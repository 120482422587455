import BaseFacilityInfoWindow from './BaseFacilityInfoWindow';
import ColombiaFacilityInfoWindow from './ColombiaFacilityInfoWindow';
import UruguayFacilityInfoWindow from './UruguayFacilityInfoWindow';
import TrinidadFacilityInfoWindow from './TrinidadFacilityInfoWindow';
import GoMFacilityInfoWindow from './GoMFacilityInfoWindow';
import GuyanaFacilityInfoWindow from './GuyanaFacilityInfoWindow';
import MexicoFacilityInfoWindow from './MexicoFacilityInfoWindow';
import ArgentinaFacilityInfoWindow from './ArgentinaFacilityInfoWindow';
import GhanaFacilityInfoWindow from './GhanaFacilityInfoWindow';
import BoliviaFacilityInfoWindow from './BoliviaFacilityInfoWindow';
import AngolaFacilityInfoWindow from './AngolaFacilityInfoWindow';
import NorwayFacilityInfoWindow from './NorwayFacilityInfoWindow';
import NigeriaFacilityInfoWindow from './NigeriaFacilityInfoWindow';
import EquatorialGuineaFacilityInfoWindow from './EquatorialGuineaFacilityInfoWindow';
import NigerFacilityInfoWindow from './NigerFacilityInfoWindow';
import CoteDIvoireFacilityInfoWindow from './CoteDIvoireFacilityInfoWindow';
import DrcFacilityInfoWindow from './DrcFacilityInfoWindow';
import ChadFacilityInfoWindow from './ChadFacilityInfoWindow';
import KurdistanFacilityInfoWindow from './KurdistanFacilityInfoWindow';
import IsraelFacilityInfoWindow from './IsraelFacilityInfoWindow';

export default {
  default: BaseFacilityInfoWindow,
  Colombia: ColombiaFacilityInfoWindow,
  Uruguay: UruguayFacilityInfoWindow,
  'Trinidad and Tobago': TrinidadFacilityInfoWindow,
  GoM: GoMFacilityInfoWindow,
  Guyana: GuyanaFacilityInfoWindow,
  Mexico: MexicoFacilityInfoWindow,
  Argentina: ArgentinaFacilityInfoWindow,
  Ghana: GhanaFacilityInfoWindow,
  Bolivia: BoliviaFacilityInfoWindow,
  Angola: AngolaFacilityInfoWindow,
  Norway: NorwayFacilityInfoWindow,
  Nigeria: NigeriaFacilityInfoWindow,
  'Equatorial Guinea': EquatorialGuineaFacilityInfoWindow,
  Niger: NigerFacilityInfoWindow,
  'Cote D Ivoire': CoteDIvoireFacilityInfoWindow,
  'Democratic Republic of Congo': DrcFacilityInfoWindow,
  Chad: ChadFacilityInfoWindow,
  Kurdistan: KurdistanFacilityInfoWindow,
  Norway: NorwayFacilityInfoWindow,
  Israel: IsraelFacilityInfoWindow,
};
