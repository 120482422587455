import defineGoogleMapMissingMethods from './defineGoogleMapMissingMethods'

const initMapWithoutMarker = (elementId, coordinate) => {
  GoogleMap.definePopupClass()
  defineGoogleMapMissingMethods()

  const customStyle = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
  ]

  return new google.maps.Map(document.getElementById('maps'), {
    zoom: JSCONSTANT.mapDefaultZoom,
    center: coordinate,
    scaleControl: true,
    backgroundColor: '#223367',
    mapTypeId: google.maps.MapTypeId.HYBRID,
    fullscreenControl: false,
    styles: customStyle,
    streetViewControl: false,
    mapTypeControl: false,
  })
}

export default initMapWithoutMarker
