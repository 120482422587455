const drawWaterChart = (
  { id, production, facility_production, water_injection_capacity },
  option,
) => {
  const waterNode = document.getElementById(`water-pie-chart-${id}`)

  const { produce_water } = facility_production
  const currentWater = parseFloat(production.water.replace(',', ''))
  const waterCapacity = parseFloat(water_injection_capacity.replace(',', ''))
  const remainWaterProduction = waterCapacity > currentWater ? waterCapacity - currentWater : 0

  if (waterCapacity > 0 && produce_water && (remainWaterProduction > 0 || currentWater > 0)) {
    option.count += 1

    const waterConfig = {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [currentWater, remainWaterProduction],
            backgroundColor: ['#51A0D5', '#d3d3d3'],
            label: 'Dataset 1',
          },
        ],
        labels: ['Water', 'Free Capacity'],
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            precision: 1,
            fontSize: 12,
            fontColor: '#fff',
          },
        },
        responsive: false,
        legend: {
          labels: {
            boxWidth: 30,
            fontSize: 10,
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let dataLabel = data.labels[tooltipItem.index]
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              value = Utils.formatProductionValue(value, 0)

              return dataLabel + ': ' + value
            },
          },
        },
      },
    }

    new Chart(waterNode.getContext('2d'), waterConfig)
  } else {
    waterNode.parentNode.remove()
  }
}

export default drawWaterChart
