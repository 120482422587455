import BaseFacilityInfoWindow from './BaseFacilityInfoWindow'

class ColombiaFacilityInfoWindow extends BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'province',
      'municipality',
      'contract',
      'water_depth',
      'hydrocarbon_type',
      'capacity',
      'productions',
    ]
  }
}

export default ColombiaFacilityInfoWindow
