import drawChart from './drawChart'
import drawChartForGom from './drawChartForGom'
import drawChartPipelineThroughput from './drawChartPipelineThroughput'
import selectObject from './selectObject'

const layerOnClick = (marker) => {
  return marker.addListener('click', function (event) {
    const content = (() => {
      switch (this.type) {
        case 'block_groups':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createBlockGroupInfoWindowContent(
            this.customInfo,
            GoogleMap.country_name,
          )
        case 'facilities':
          return GoogleMap.createFacilityInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'wells':
          return GoogleMap.createWellInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'fields':
          if (JSCONSTANT.countryZoomBlockThroughField.indexOf(GoogleMap.country_name) >= 0) {
            const assetName = this.customInfo.name
            const relationships = GoogleMap.relationship.field_blocks.filter(
              (fb) => fb.field_name === assetName,
            )
            if (relationships && relationships.length) {
              const blockIds = relationships.map((rela) => rela.block_id)
              const markerBlocks = GoogleMap.markers.blocks.filter(
                (blockMarker) => blockIds.indexOf(blockMarker.customInfo.id) >= 0,
              )
              selectObject(markerBlocks, 'fields', { event, noZoom: true })
              return GoogleMap.createBlockInfoWindowContent(
                markerBlocks[0].customInfo,
                GoogleMap.country_name,
              )
            }
          } else {
            selectObject([marker], 'fields', { event, noZoom: true })
            return GoogleMap.createFieldInfoWindowContent(this.customInfo, GoogleMap.country_name)
          }
          break
        case 'blocks':
          selectObject([marker], 'fields', { event, noZoom: true })
          if (this.customInfo.asset_model_id) {
            let field = MAP_DATA.fields.filter((f) => f.id === this.customInfo.asset_model_id)[0]
            return (
              field &&
              GoogleMap.createFieldInfoWindowContent(
                field,
                GoogleMap.country_name,
                this.customInfo.name,
              )
            )
          } else if (
            GoogleMap.country_name === 'Trinidad and Tobago' &&
            JSCONSTANT.trinidadOnshoreBlocks.includes(this.customInfo.name)
          ) {
            let block
            for (const [_type, typedBlocks] of Object.entries(MAP_DATA.blocks)) {
              if (block) break
              block = typedBlocks.find(
                (block) => block.name === JSCONSTANT.trinidadHeritagePetroleumOnshore,
              )
            }
            const blockInfo = {
              ...block,
              name: this.customInfo.name,
              participation: this.customInfo.participation,
            }
            return GoogleMap.createBlockInfoWindowContent(blockInfo, GoogleMap.country_name)
          } else {
            return GoogleMap.createBlockInfoWindowContent(this.customInfo, GoogleMap.country_name)
          }
        case 'restricted_areas':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createBlockInfoWindowContent(
            this.customInfo,
            GoogleMap.country_name,
            true,
          )
        case 'hydrocarbon_structures':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createHydrocarbonStructureInfoWindowContent(
            this.customInfo,
            GoogleMap.country_name,
          )
        case 'basins':
          return GoogleMap.createBasinInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'pipelines':
        case 'secondary_pipelines':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createPipelineInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'pipeline_throughputs':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createPipelineThroughputInfoWindowContent(
            this.customInfo,
            GoogleMap.country_name,
          )
        case 'presalts':
          return GoogleMap.createPresaltInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'refineries':
          return GoogleMap.createRefineryInfoWindowContent(this.customInfo, GoogleMap.country_name)
        case 'formation_shapefiles':
          selectObject([marker], 'fields', { event, noZoom: true })
          return GoogleMap.createFormationShapfileInfoWindowContent(
            this.customInfo,
            GoogleMap.country_name,
          )
        default:
          return null
      }
    })()

    if (!content) {
      return
    }

    GoogleMap.infoWindow.setContent(content)
    GoogleMap.infoWindow.setPosition(this.position || event.latLng)
    GoogleMap.infoWindow.setZIndex(1000)
    if (GoogleMap.infoWindow) {
      GoogleMap.infoWindow.close()
    }
    GoogleMap.infoWindow.open(GoogleMap.map)
    if (this.customInfo.production) {
      if (JSCONSTANT.countryDrawChartLikeGom.includes(GoogleMap.country_name)) {
        return drawChartForGom(this.customInfo)
      } else {
        return drawChart(this.customInfo)
      }
    }
    // for pipeline throughputs
    if (
      JSCONSTANT.countryHasPipelineThroughput.includes(GoogleMap.country_name) &&
      this.customInfo.type == 'pipeline_throughput'
    ) {
      return drawChartPipelineThroughput(this.customInfo)
    }
  })
}

export default layerOnClick
