import BaseWellInfoWindow from './BaseWellInfoWindow'

class NigeriaWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'total_depth',
      'water_blade',
      'operator',
      'line_break',
      'drilling_start',
      'drilling_end',
      'line_break',
      'well_data_available',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.removeDuplicate(this.lines).join('<br>')
  }

  pushContent = (propName) => {
    let propTitle
    let propValue
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'well_data_available':
        propTitle = I18n.t('js.customer.maps.content.well.' + propName)
        propValue = I18n.t('js.customer.maps.content.well.well_data_available_info')
        return this.lines.push(
          `<strong>${propTitle}:</strong> ${propValue} <a class="copy-click"> jp.rossi@geoexltd.com </a>`,
        )
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default NigeriaWellInfoWindow
