import BaseBlockInfoWindow from './BaseBlockInfoWindow'

class ColombiaBlockInfoWindow extends BaseBlockInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'area',
      'water_depth',
      'depth',
      'average_depth',
      'shore_status',
      'geology',
      'api',
      'line_break',
      'participation',
      'x_factor',
      'fiscal_regime',
      'round',
      'goverment_profit_share',
      'royalty_rate',
      'signature_bonus',
      'first_prod',
      'contract_signed',
      'expiration_date',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'recent_oil',
      'recent_gas',
      'total_wells_drilled',
      'well_commitment',
      'wells_drilled',
      'min_work_program',
      'transportation_method',
      'trucking_destination',
      'pipeline_network',
      'pipeline_network_segment',
      'contract_status',
      'enhanced_access_coverage',
    ]
  }
}

export default ColombiaBlockInfoWindow
