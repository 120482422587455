import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import React from 'react';
import { Fragment } from 'react';
import {
  Highlight,
  InfiniteHits,
  InstantSearch,
  SearchBox,
  RefinementList,
  RangeInput,
  Stats,
} from 'react-instantsearch';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import moment from 'moment';
import { titleize, humanize } from '../util';
import { Spinner } from '../UI/Spinner';

const RERUN_STEPS = {
  r1_running: 0,
  r2_running: 1,
  r_ghg_running: 2,
  report_running: 3,
  enhanced_report_running: 4,
};

const REPORT_TYPES = {
  report_path: 3,
  enhanced_excel_report_path: 4,
  enhanced_report_path: 4,
};

const routing = {
  router: history(),
  stateMapping: simple(),
};

const { searchClient } = instantMeiliSearch(
  `${window.location.origin}/query`,
  undefined,
  {
    requestConfig: {
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    },
  },
);

/**
 * Renders a section displaying key information about an asset, including its Welligence ID, country, operator, partners, basin, and primary hydrocarbon.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} hit.asset_id - The Welligence ID of the asset.
 * @param {string} hit.country_name - The country where the asset is located.
 * @param {string} hit.operator - The operator of the asset.
 * @param {string[]} hit.participation_data - An array of partner names for the asset.
 * @param {string} hit.basin_name - The basin where the asset is located.
 * @param {string} hit.main_hydrocacbon - The primary hydrocarbon type of the asset.
 * @returns {React.ReactElement} - A React element representing the key information section.
 */
const renderKeyInformation = (hit) => (
  <Fragment>
    <h4 className="subheading">Key Information</h4>
    <table>
      <tbody>
        <tr>
          <td>Welligence ID:</td>
          <td>{hit.asset_id}</td>
        </tr>
        <tr>
          <td>Country:</td>
          <td>{hit.country_name}</td>
        </tr>
        <tr>
          <td>Operator:</td>
          <td>{hit.operator}</td>
        </tr>
        {hit.participation_data.length > 0 &&
          hit.participation_data.map((partner, idx) => (
            <tr>
              {idx == 0 && (
                <td
                  rowSpan={hit.participation_data.length}
                  style={{ verticalAlign: 'baseline' }}
                >
                  Partner:
                </td>
              )}
              <td>{partner}</td>
            </tr>
          ))}
        <tr>
          <td>Basin:</td>
          <td>{hit.basin_name}</td>
        </tr>
        <tr>
          <td>Primary Hydrocarbon:</td>
          <td>{titleize(hit.main_hydrocacbon) ?? 'NA'}</td>
        </tr>
      </tbody>
    </table>
  </Fragment>
);

/**
 * Renders a component that displays the last updated time for an asset report.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} hit.asset_report_updated_date - The date the asset report was last updated.
 * @returns {React.ReactElement} - A React element representing the last updated time.
 */
const AssetReportUpdatedTime = ({ hit }) => {
  const reportUpdatedTime = moment(hit.asset_report_updated_date, true);
  if (!reportUpdatedTime.isValid()) return null;

  return (
    <div className="label last-updated">
      Last updated: <b>{reportUpdatedTime.format('YYYY-MM-DD')}</b>{' '}
      {reportUpdatedTime.fromNow()}
    </div>
  );
};

/**
 * Renders a timeline section for an asset, displaying information such as discovery date, first production, peak production, and license expiration.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} [hit.discovery_date] - The discovery date of the asset.
 * @param {string} [hit.first_production] - The first production date of the asset.
 * @param {string} [hit.peak_production] - The peak production date of the asset.
 * @param {string} [hit.license_date] - The license expiration date of the asset.
 * @returns {React.ReactElement} - A React element representing the timeline section.
 */
const renderTimeline = (hit) => (
  <Fragment>
    <h4 className="subheading">Timeline</h4>
    <table>
      <tbody>
        {hit.discovery_date && (
          <tr>
            <td>Discovery Date:</td>
            <td>{hit.discovery_date}</td>
          </tr>
        )}
        {hit.first_production && (
          <tr>
            <td>First Production:</td>
            <td>{hit.first_production}</td>
          </tr>
        )}
        {hit.peak_production && (
          <tr>
            <td>Peak Production:</td>
            <td>{hit.peak_production}</td>
          </tr>
        )}
        {hit.license_date && (
          <tr>
            <td>License Expiration:</td>
            <td>{hit.license_date}</td>
          </tr>
        )}
      </tbody>
    </table>
  </Fragment>
);

const renderFinConsiderations = (hit) => (
  <Fragment>
    <h4 className="subheading">Financial Considerations ({hit.base_year})</h4>
    <table>
      <tbody>
        <tr>
          <td>
            PV10 <small>(US$MM)</small>:
          </td>
          <td>
            {hit.npv.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            })}
          </td>
        </tr>
        <tr>
          <td>
            Remaining Capex + Abex <small>(US$MM)</small>:
          </td>
          <td>
            {hit.remaining_capex.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            })}
          </td>
        </tr>
        <tr>
          <td>Fiscal Regime:</td>
          <td>{hit.fiscal_regime}</td>
        </tr>
      </tbody>
    </table>
  </Fragment>
);

/**
 * Renders a table with production and reserves information for an asset.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} hit.most_current_production_date - The date of the most current production data.
 * @param {number} hit.recent_liquid_prod - The recent liquid production.
 * @param {number} hit.recent_gas_prod - The recent gas production.
 * @param {number} hit.total_reserves_liquids - The total remaining liquid reserves.
 * @param {number} hit.total_reserves_oil - The total remaining oil reserves.
 * @param {number} hit.total_reserves_gas - The total remaining gas reserves.
 * @param {number} hit.well_eur - The estimated ultimate recovery (EUR) for the well.
 * @param {number} hit.well_ip_rate - The initial production (IP) rate for the well.
 * @param {string} hit.main_hydrocacbon - The main hydrocarbon type for the well.
 * @param {string} hit.country_id - The ID of the country the asset is located in.
 * @returns {JSX.Element} - A React element representing the production and reserves table.
 */
const renderProductionAndReserves = (hit) => (
  <Fragment>
    <h4 className="subheading">Production and Reserves</h4>
    <table>
      <tbody>
        <tr>
          <td>
            Liquids Production{' '}
            <small>({hit.most_current_production_date}) (bbl/d)</small>
          </td>
          <td>{hit.recent_liquid_prod}</td>
        </tr>
        <tr>
          <td>
            Gas Production{' '}
            <small>({hit.most_current_production_date}) (MMcf/d)</small>
          </td>
          <td>{hit.recent_gas_prod}</td>
        </tr>
        <tr>
          <td>
            Remaining Liquids Reserves{' '}
            <small>({hit.most_current_production_date}) (MMcf/d)</small>
          </td>
          <td>
            {hit.country_id === window.JSCONSTANT.GOM_ID
              ? hit.total_reserves_liquids
              : hit.total_reserves_oil}
          </td>
        </tr>
        <tr>
          <td>
            Remaining Gas Reserves{' '}
            <small>({hit.most_current_production_date}) (Bcf)</small>
          </td>
          <td>{hit.total_reserves_gas}</td>
        </tr>
        {hit.well_eur && (
          <tr>
            <td>
              {titleize(hit.main_hydrocacbon)} Well EURs <small>(Bcf)</small>:
            </td>
            <td>{hit.well_eur}</td>
          </tr>
        )}
        {hit.well_ip_rate && (
          <tr>
            <td>
              {titleize(hit.main_hydrocacbon)} Well IP Rates{' '}
              <small>(MMcf/d)</small>:
            </td>
            <td>{hit.well_ip_rate}</td>
          </tr>
        )}
      </tbody>
    </table>
  </Fragment>
);

/**
 * Renders a map image based on the provided `hit` object.
 *
 * If the `hit.map_url.url` property is truthy, it renders an `<img>` element with the URL from `hit.map_url.url`.
 * Otherwise, it constructs a Google Static Maps API URL using the `hit.map_url.lat` and `hit.map_url.lng` properties,
 * and renders an `<img>` element with that URL.
 *
 * @param {Object} hit - An object containing map-related data.
 * @param {string} hit.map_url.url - The URL of the map image, if available.
 * @param {number} hit.map_url.lat - The latitude coordinate of the map center.
 * @param {number} hit.map_url.lng - The longitude coordinate of the map center.
 * @returns {React.ReactElement} - A React element representing the map image.
 */
const renderMap = (hit) => {
  if (hit.map_url.url) {
    return <img src={hit.map_url.url} className="map_picture" />;
  } else {
    const coordinate = [hit.map_url.lat, hit.map_url.lng].join(',');
    return (
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${coordinate}&zoom=5&size=585x305&maptype=roadmap&markers=color:red%7C${coordinate}&key=${window.JSCONSTANT.googleApiStaticmapKey}`}
        className="map_picture"
      />
    );
  }
};

/**
 * Renders a progress indicator for the asset report rerun process.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} hit.rerun_step - The current step of the asset report rerun process.
 * @returns {JSX.Element|null} - The rendered progress indicator, or null if the rerun step is not applicable.
 */
const RerunProgress = ({ hit }) => {
  const { rerun_step: rerunStep } = hit;
  if (!rerunStep) return null;

  return (
    <div className="col-xs-12 rerun-progress">
      <Spinner width={12} height={12} />
      Progress:
      <code>{titleize(humanize(rerunStep))}</code>
    </div>
  );
};

/**
 * Renders a single asset item in the search results.
 *
 * @param {Object} hit - The asset data object.
 * @param {string} hit.asset_name - The name of the asset.
 * @param {string} hit.country_id - The ID of the country the asset is located in.
 * @param {string} hit.asset_type - The type of the asset.
 * @param {string} hit.asset_id - The ID of the asset.
 * @param {string} hit.asset_report_updated_date - The date the asset report was last updated.
 * @param {string} hit.report_path - The URL to download the asset report.
 * @param {string} hit.enhanced_report_path - The URL to download the enhanced asset report.
 * @param {string} hit.enhanced_excel_report_path - The URL to download the enhanced asset report in Excel format.
 * @param {string} hit.rerun_step - The current step of the asset report rerun process.
 * @returns {JSX.Element} - The rendered asset item.
 */
const Hit = ({ hit }) => {
  const rerunStepValue = isNaN(RERUN_STEPS[hit.rerun_step])
    ? 10
    : RERUN_STEPS[hit.rerun_step];

  const downloadableButton = (buttonPath) => {
    if (!hit[buttonPath]) return 'disabled';

    if (REPORT_TYPES[buttonPath] >= rerunStepValue) {
      return 'disabled';
    }

    return '';
  };

  const assetModelDownloadClassName = `download-button ${downloadableButton(
    'report_path',
  )}`;
  const enhancedExcelDownloadClassName = `download-button ${downloadableButton(
    'enhanced_excel_report_path',
  )}`;
  const enhancedReportDownloadClassName = `download-button ${downloadableButton(
    'enhanced_report_path',
  )}`;

  return (
    <div key={hit.id} className="d-flex flex-sm-column w-100">
      <span className="d-inline-flex justify-content-between title">
        <h1 className="space-grotesk">
          <Highlight attribute="asset_name" hit={hit} />
        </h1>
        <a
          className="d-inline-flex edit-button"
          target="_blank"
          href={`${window.location.origin}/admin/countries/${
            hit.country_id
          }/assets?${hit.asset_type.toLowerCase()}_id=${hit.asset_id}`}
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
          <span className="ml-2 align-self-center">EDIT ASSET</span>
        </a>
      </span>
      <AssetReportUpdatedTime hit={hit} />
      <div className="row">
        <div className="col-sm-8 col-xs-12">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              {renderKeyInformation(hit)}
            </div>
            <div className="col-sm-6 col-xs-12">
              {renderFinConsiderations(hit)}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">{renderTimeline(hit)}</div>
            <div className="col-sm-6 col-xs-12">
              {renderProductionAndReserves(hit)}
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-xs-12">
          <div className="row">
            <div className="col-xs-12">{renderMap(hit)}</div>
            <RerunProgress hit={hit} />
            <div className="col-xs-12">
              <div className="d-flex flex-column">
                <button className={assetModelDownloadClassName}>
                  {rerunStepValue === RERUN_STEPS.report_running ? (
                    <Spinner />
                  ) : null}
                  {downloadableButton('report_path') === '' ? (
                    <a
                      href={hit.report_path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Report
                    </a>
                  ) : (
                    <span className="disabled">Download Report</span>
                  )}
                </button>
                <button className={enhancedReportDownloadClassName}>
                  {rerunStepValue === RERUN_STEPS.enhanced_report_running ? (
                    <Spinner />
                  ) : null}
                  {downloadableButton('enhanced_report_path') === '' ? (
                    <a
                      href={hit.enhanced_report_path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Enhanced PDF
                    </a>
                  ) : (
                    <span className="disabled">Download Enhanced PDF</span>
                  )}
                </button>
                <button className={enhancedExcelDownloadClassName}>
                  {rerunStepValue === RERUN_STEPS.enhanced_report_running ? (
                    <Spinner />
                  ) : null}
                  {downloadableButton('enhanced_excel_report_path') === '' ? (
                    <a
                      href={hit.enhanced_excel_report_path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download Enhanced Excel
                    </a>
                  ) : (
                    <span className="disabled">Download Enhanced Excel</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => (
  <Fragment>
    <InstantSearch
      className="filters row"
      indexName="assets"
      routing={routing}
      searchClient={searchClient}
    >
      <aside className="col-sm-1f2 col-lg-2 col-md-4 col-xs-12 refinement-bar">
        <h4 className="filter-label">
          <span className="ic ic-country"></span>Country
        </h4>
        <RefinementList
          className="select"
          attribute="country_name"
          searchable={true}
          searchablePlaceholder="Country"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-working"></span>Fiscal Regime
        </h4>
        <RefinementList
          className="asset-select"
          attribute="fiscal_regime"
          searchable={true}
          searchablePlaceholder="Fiscal Regime"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-working"></span>Asset Name
        </h4>
        <RefinementList
          className="asset-select"
          attribute="asset_name"
          searchable={true}
          searchablePlaceholder="Asset"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-company"></span>Company
        </h4>
        <RefinementList
          className="select"
          attribute="participation"
          searchable={true}
          searchablePlaceholder="Company"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-basin"></span>Basin
        </h4>
        <RefinementList
          className="select"
          attribute="basin"
          searchable={true}
          searchablePlaceholder="Company"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-status"></span>Shore Status
        </h4>
        <RefinementList
          className="select"
          attribute="shore_status"
          searchable={true}
          searchablePlaceholder="Shore Status"
        />
        <h4 className="filter-label">
          <span className="ic ic-npv"></span>
          <span>
            NPV <small>(US$MM)</small>
          </span>
        </h4>
        <RangeInput attribute="npv" precision={1} />
        <h4 className="filter-label">
          <span className="ic ic-reserves"></span>
          <span>
            Remaining Reserves <small>(MMboe)</small>
          </span>
        </h4>
        <RangeInput attribute="reserves" precision={1} />
        <h4 className="filter-label">
          <span className="ic ic-capex"></span>
          <span>
            Remaining Capex + Abex <small>(US$MM)</small>
          </span>
        </h4>
        <RangeInput attribute="remaining_capex" precision={1} />
        <h4 className="filter-label">
          <span className="ic ic-capex"></span>
          <span>
            Current Production <small>(boe/d)</small>
          </span>
        </h4>
        <RangeInput attribute="current_prod" precision={1} />
        <h4 className="filter-label">
          <span className="ic ic-epoch"></span>Epoch
        </h4>
        <RefinementList
          className="select"
          attribute="epoch"
          searchable={true}
          searchablePlaceholder="Epoch"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-formation"></span>Formation
        </h4>
        <RefinementList
          className="select"
          attribute="formation"
          searchable={true}
          searchablePlaceholder="Formation"
          showMore={true}
          limit={4}
        />
        <h4 className="filter-label">
          <span className="ic ic-lithology"></span>Lithology
        </h4>
        <RefinementList
          className="select"
          attribute="lithology"
          searchable={true}
          searchablePlaceholder="Lithology"
          showMore={true}
          limit={4}
        />
      </aside>
      <main className="col-sm-12 col-lg-10 col-md-8 col-xs-12 result">
        <SearchBox />
        <Stats />
        <InfiniteHits key="id" hitComponent={Hit} showPrevious={false} />
      </main>
    </InstantSearch>
  </Fragment>
);

export default App;
