import BaseFieldInfoWindow from './BaseFieldInfoWindow'
import GomFieldInfoWindow from './GomFieldInfoWindow'
import BoliviaFieldInfoWindow from './BoliviaFieldInfoWindow'
import GuyanaFieldInfoWindow from './GuyanaFieldInfoWindow'

export default {
  default: BaseFieldInfoWindow,
  GoM: GomFieldInfoWindow,
  Bolivia: BoliviaFieldInfoWindow,
  Guyana: GuyanaFieldInfoWindow,
}
