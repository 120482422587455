import TrinidadPipelineInfoWindow from './TrinidadPipelineInfoWindow'

class GuyanaPipelineInfoWindow extends TrinidadPipelineInfoWindow {
  static get props() {
    return [
      'name',
      'pipeline_type',
      'operator',
      'diameter_inches',
      'capacity',
      'length',
      'status',
      'line_break',
      'shore_status',
      'origin',
      'destination',
    ]
  }
}

export default GuyanaPipelineInfoWindow
