var peruRefineries = [
  'Lubricant Plant',
  'Refinery',
  'LPG Bottling Plant',
  'LPG Bottling Company',
  'Airport Supply Plant',
  'Supply Plant',
  'LPG Supply Plant',
  'Refinery',
  'Pumping Station',
  'Peru Facility',
  'Separation',
  'Fractionation',
  'Criogenic',
  'Gas Processing',
  'Compression',
  'Collection Station',
];

var guatemalaRefineries = [
  'Production Plant',
  'Pumping Station',
  'Charging Point',
  'Delivery Point',
  'Refinery',
  'Processing Plant',
];

var boliviaRefineries = [
  'Production Plant',
  'Pumping Station',
  'Charging Point',
  'Delivery Point',
  'Refinery',
  'Processing Plant',
  'Dew Point',
  'Absorption',
  'Cryogenic',
  'Primary Separation',
  'Modified Dew Point',
  'Liquids Separation',
  'Compression Station',
];

var boliviaMissingTypeFacilities = [
  'Planta Monteagudo',
  'Planta Vibora',
  'Planta Caranda',
];

var mexicoOilRefinery = ['Separation (onshore)'];

var mexicoOilRefinery2 = [
  'Dehydration (onshore)',
  'Heating (onshore)',
  'Water Injection (onshore)',
  'Other',
];

var mexicoPipelineValve = [
  'Gathering (onshore)',
  'Distribution (onshore)',
  'Pumping (onshore)',
];

var mexicoFixedPlatform = [
  'Compression (offshore)',
  'Offshore hub (Enlace)',
  'Drilling (offshore)',
  'Measuring (offshore)',
  'Production (offshore)',
  'Accomodation (offshore)',
  'Pumping (offshore)',
  'Telecom (offshore)',
  'Measurement point (Cayo Arcas)',
];

var gomFixedPlatform = [
  'FIXED',
  'Fixed Platform',
  'CAIS',
  'CT',
  'MOPU',
  'MTLP',
  'SEMI',
  'SPAR',
  'TLP',
];

var mexicoOilAndGasPipe = ['Compression (onshore)', 'Measuring (onshore)'];

var colombiaOffshore = ['Gas processing', 'Water injection'];

var colombiaOilAndGasPipe = ['Compression'];

var colombiaOilRefinery2 = ['Decommissioned', 'Dehydration', 'Injection'];

var colombiaOilRefinery = ['Production'];

var colombiaPipelineValue = ['Pumping'];

var ArgentinaRefineries = ['Refinery'];

const setMarkersIcon = () => {
  return (() => {
    const result = [];
    for (let layer of Array.from(JSCONSTANT.layersUsingMarker)) {
      let markers = GoogleMap.markers[layer];

      if (Array.isArray(markers)) {
        let groupedMarkers = Utils.splitLargeArrayIntoSmallerArrays(
          markers,
          2000
        );
        result.push(
          (() => {
            const result1 = [];
            for (markers of Array.from(groupedMarkers)) {
              result1.push(
                (() => {
                  const result2 = [];
                  for (let marker of Array.from(markers)) {
                    if (marker.customInfo.object_type === 'Facility') {
                      let typeName, typeNameByCountry;
                      switch (marker.customInfo.type_name) {
                        case 'Refinery':
                          typeName = 'refinery';
                          break;
                        default:
                          typeName = 'other';
                          break;
                      }

                      if (
                        !marker.customInfo.water_depth &&
                        typeName == 'other'
                      ) {
                        if (marker.customInfo.country !== 'GoM') {
                          typeName = 'offshore';
                        }
                      }

                      switch (marker.customInfo.country) {
                        case 'GoM':
                          typeNameByCountry = gomTypeName(marker);
                          break;
                        case 'Brazil':
                          typeNameByCountry = brazilTypeName(marker);
                          break;
                        case 'Cameroon':
                          typeNameByCountry = cameroonTypeName(marker);
                          break;
                        case 'Equatorial Guinea':
                          typeNameByCountry = equatorialGuineaTypeName(marker);
                          break;
                        case 'Congo Brazzaville':
                          typeNameByCountry = congoBrazzavilleTypeName(marker);
                          break;
                        default:
                          typeNameByCountry = countryTypeName(marker);
                      }

                      if (
                        ['Angola', 'Nigeria'].includes(
                          marker.customInfo.country
                        )
                      ) {
                        typeName = typeName || typeNameByCountry;
                      } else {
                        typeName =
                          typeNameByCountry == null
                            ? typeName
                            : typeNameByCountry;
                      }

                      if (!marker.icon) {
                        result2.push(
                          marker.setIcon(
                            JSCONSTANT.markerIcons[layer][typeName]
                          )
                        );
                      } else {
                        result2.push(undefined);
                      }
                    } else {
                      if (!marker.icon) {
                        result2.push(
                          marker.setIcon(JSCONSTANT.markerIcons[layer])
                        );
                      } else {
                        result2.push(undefined);
                      }
                    }
                  }
                  return result2;
                })()
              );
            }
            return result1;
          })()
        );
      } else {
        result.push(
          (() => {
            const result3 = [];
            for (let key in markers) {
              let subMarkers = markers[key];
              result3.push(
                (() => {
                  const result4 = [];
                  for (let marker of Array.from(subMarkers)) {
                    // facilities in mexico
                    if (marker.customInfo.object_type === 'Facility') {
                      let typeName, typeNameByCountry;
                      switch (marker.customInfo.type_name) {
                        case 'Refinery':
                          typeName = 'refinery';
                          break;
                        default:
                          typeName = 'other';
                          break;
                      }

                      if (
                        !marker.customInfo.water_depth &&
                        typeName == 'other'
                      ) {
                        if (marker.customInfo.country !== 'GoM') {
                          typeName = 'offshore';
                        }
                      }

                      switch (marker.customInfo.country) {
                        case 'GoM':
                          typeNameByCountry = gomTypeName(marker);
                          break;
                        case 'Mexico':
                          typeNameByCountry = mexicoTypeName(marker);
                          break;
                        case 'Brazil':
                          typeNameByCountry = brazilTypeName(marker);
                          break;
                        default:
                          typeNameByCountry = countryTypeName(marker);
                      }

                      typeName =
                        typeNameByCountry == null
                          ? typeName
                          : typeNameByCountry;

                      if (!marker.icon) {
                        result4.push(
                          marker.setIcon(
                            JSCONSTANT.markerIcons[layer][typeName]
                          )
                        );
                      } else {
                        result4.push(undefined);
                      }
                    } else if (marker.type === 'wells') {
                      if (!marker.icon) {
                        result4.push(
                          marker.setIcon(JSCONSTANT.markerIcons.wells[key])
                        );
                      } else {
                        result4.push(undefined);
                      }
                    } else {
                      if (!marker.icon) {
                        result4.push(
                          marker.setIcon(JSCONSTANT.markerIcons[layer])
                        );
                      } else {
                        result4.push(undefined);
                      }
                    }
                  }
                  return result4;
                })()
              );
            }
            return result3;
          })()
        );
      }
    }
    return result;
  })();
};

const gomTypeName = (marker) => {
  let typeName;

  if (gomFixedPlatform.includes(marker.customInfo.type_name)) {
    typeName = 'fixa';
  }

  if (
    !gomFixedPlatform.concat(['FPSO']).includes(marker.customInfo.type_name)
  ) {
    typeName = 'square';
  }

  if (['Petroleum Refinery'].includes(marker.customInfo.type_name)) {
    typeName = 'refinery';
  }

  return typeName;
};

const brazilTypeName = (marker) => {
  let typeName;

  switch (marker.customInfo.type_name) {
    case 'TLWP':
    case 'Semi-submersible':
    case 'Fixed Platform':
      typeName = 'fixa';
      break;
    case 'FPSO':
      typeName = 'other';
      break;
  }

  return typeName;
};

const cameroonTypeName = (marker) => {
  let typeName;

  switch (marker.customInfo.type_name) {
    case 'Fixed Platform':
      typeName = 'fixa';
      break;
    case 'FLNG':
    case 'FSO':
      typeName = 'other';
      break;
  }

  return typeName;
};

const equatorialGuineaTypeName = (marker) => {
  let typeName;

  switch (marker.customInfo.type_name) {
    case 'FPSO':
      typeName = 'other';
      break;
    case 'Fixed Platform':
      typeName = 'fixa';
      break;
  }

  return typeName;
};

const congoBrazzavilleTypeName = (marker) => {
  let typeName;

  switch (marker.customInfo.type_name) {
    case 'FPSO':
    case 'FPU':
      typeName = 'other';
      break;
  }

  return typeName;
};

const countryTypeName = (marker) => {
  let countryName = marker.customInfo.country;
  let typeName;

  switch (countryName) {
    case 'Peru':
      if (Array.from(peruRefineries).includes(marker.customInfo.type_name)) {
        typeName = 'refinery';
      }
      break;
    case 'Guatemala':
      if (
        Array.from(guatemalaRefineries).includes(marker.customInfo.type_name)
      ) {
        typeName = 'refinery';
      }
      break;
    case 'Bolivia':
      let markerType = marker.customInfo.type_name;
      let markerName = marker.customInfo.name;

      if (
        Array.from(boliviaRefineries).includes(markerType) ||
        Array.from(boliviaMissingTypeFacilities).includes(markerName)
      ) {
        typeName = 'refinery';
      }
      break;
    case 'Uruguay':
      typeName = 'refinery';
      break;
    case 'Argentina':
      if (ArgentinaRefineries.includes(marker.customInfo.type_name)) {
        typeName = 'refinery';
      }
      break;
    case 'Ghana':
      typeName = 'other';
      break;
    case 'Trinidad and Tobago':
    case 'Norway':
      typeName = 'fixa';
      break;
    case 'Cote D Ivoire':
      if (marker.customInfo.type_name == 'FPSO') {
        typeName = 'other';
      }
    case 'Democratic Republic of Congo':
      if (marker.customInfo.type_name == 'FSO') {
        typeName = 'other';
      }
    case 'Angola':
      if (marker.customInfo.type_name == 'FPSO') {
        typeName = 'other';
      }
    case 'Nigeria':
      typeName = 'other';
    case 'Chad':
      if (marker.customInfo.type_name == 'Refinery') {
        typeName = 'refinery';
      }
      break;
    case 'Niger':
      if (marker.customInfo.type_name == 'Refinery') {
        typeName = 'refinery';
      }
      break;
    case 'Senegal':
      typeName = 'other';
      break;
  }

  return typeName;
};

const mexicoTypeName = (marker) => {
  let typeName;

  if (Array.from(mexicoOilRefinery).includes(marker.customInfo.type_name)) {
    typeName = 'oil_refinery';
  } else if (
    Array.from(mexicoOilRefinery2).includes(marker.customInfo.type_name)
  ) {
    typeName = 'oil_refinery_2';
  } else if (
    Array.from(mexicoPipelineValve).includes(marker.customInfo.type_name)
  ) {
    typeName = 'pipeline_valve';
  } else if (
    Array.from(mexicoFixedPlatform).includes(marker.customInfo.type_name)
  ) {
    typeName = 'fixa';
  } else if (
    Array.from(mexicoOilAndGasPipe).includes(marker.customInfo.type_name)
  ) {
    typeName = 'oil_and_gas_pipe';
  }

  return typeName;
};

const colombiaTypeName = (marker) => {
  let typeName;

  if (Array.from(colombiaOffshore).includes(marker.customInfo.type_name)) {
    typeName = 'offshore';
  } else if (
    Array.from(colombiaOilAndGasPipe).includes(marker.customInfo.type_name)
  ) {
    typeName = 'oil_and_gas_pipe';
  } else if (
    Array.from(colombiaOilRefinery2).includes(marker.customInfo.type_name)
  ) {
    typeName = 'oil_refinery_2';
  } else if (
    Array.from(colombiaOilRefinery).includes(marker.customInfo.type_name)
  ) {
    typeName = 'oil_refinery';
  } else if (
    Array.from(colombiaPipelineValue).includes(marker.customInfo.type_name)
  ) {
    typeName = 'pipeline_valve';
  }

  return typeName;
};

export default setMarkersIcon;
