import BaseWellInfoWindow from './BaseWellInfoWindow'

class GuyanaWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'result',
      'prospect',
      'operator',
      'measured_depth',
      'vertical_depth',
      'water_blade',
      'total_depth',
      'line_break',
      'drilling_start',
      'drilling_end',
      'drill_days',
      'rig_name',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.removeDuplicate(this.lines).join('<br>')
  }

  pushContent = (propName) => {
    let propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'prospect':
        let value = this.element.prospect

        if (value && value !== 'null' && this.element.well_type !== 'Appraisal') {
          let propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${value}`)
        }
        if (this.element.name === 'Yellowtail-2') {
          return this.lines.push(`<strong>Field:</strong> ${value}`)
        }
        break

      default:
        let propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          let propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default GuyanaWellInfoWindow
