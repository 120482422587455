const resetLayerText = () => {
  let except_label =
    "label[for!='wells'][for!='pipelines'][for!='blocks'][for!='secondary_pipelines'][for!='midstream'][for!='upstream'][for!='infield']";
  if (GoogleMap.country_name === 'Mexico') {
    except_label = `${except_label}[for!='facilities']`;
  }

  return $(`${except_label}.layer-label`).each(function (index, label) {
    const layerName = $(label).attr('for');
    const translatedText = I18n.t(`js.customer.maps.index.${layerName}`);
    return $(label).text(`${translatedText} (0)`);
  });
};

export default resetLayerText;
