import convertStringToFloat from './convertStringToFloat'
import moment from 'moment'

const drawGasPipelineThroughputLineChart = ({ id, pipeline_segment_throughput }) => {
  const ctx = document.getElementById(`gas-pipeline-throughput-line-chart-${id}`).getContext('2d')

  let gasThroughput = []
  let gas = pipeline_segment_throughput.gas

  gas.forEach((t) => {
    gasThroughput.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1], 0) })
  })

  new Chart(ctx, {
    type: 'line',

    data: {
      datasets: [
        {
          label: 'Gas (MMcf/d)',
          fill: false,
          borderColor: '#FF3333',
          backgroundColor: '#FF3333',
          borderWidth: 1,
          data: gasThroughput,
        },
      ],
    },

    options: {
      title: {
        display: false,
        text: '',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 20,
          fontSize: 10,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'YYYY',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'MMcf/d',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  })
}

export default drawGasPipelineThroughputLineChart
