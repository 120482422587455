import MapEvents from './MapEvents';
import initCircleMap from './mapHelpers/initMap';
import selectObject from './mapHelpers/selectObject';
import getFilteredMapData from './filterMaps';
import focusMap from './mapHelpers/focusMap';
import loadCountryRelativeData from './mapHelpers/loadCountryRelativeData';
import { getInfoWindowHtml } from './infoWindows';

const setMap = function () {
  window.Utils = {
    dmsToDecimal(value) {
      if (!value) {
        return value;
      }
      if (!value.toString().includes(':')) {
        return value;
      }
      const splited_value = value.split(':');
      const hour = splited_value[0];
      const min = splited_value[1];
      const sec = splited_value[2];
      const newValue =
        Math.abs(parseInt(hour)) +
        (parseInt(min) * 60 + parseFloat(sec.replace(/\,/g, '.'))) / 3600;
      return (parseInt(hour) >= 0 && newValue) || -newValue;
    },

    getCoordinateByCountryName(country, callback) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: country, componentRestrictions: { locality: 'country' } },
        (results, status) => callback(results[0].geometry.location)
      );
    },

    abortUnfinishedAjaxRequests(requests) {
      return (() => {
        const result = [];
        for (let xhr of Array.from(requests)) {
          if (xhr.readyState !== XMLHttpRequest.DONE) {
            result.push(xhr.abort());
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    },

    formatProductionValue(value, numberFractionDigits) {
      if (numberFractionDigits == null) {
        numberFractionDigits = 2;
      }
      value = value.toString();

      return parseFloat(value.replace(',', '')).toLocaleString(undefined, {
        maximumFractionDigits: numberFractionDigits,
        minimumFractionDigits: numberFractionDigits,
      });
    },

    splitLargeArrayIntoSmallerArrays(originalArray, chunkSize) {
      const groups = [];
      let counter = 0;
      while (counter < originalArray.length) {
        groups.push(originalArray.slice(counter, counter + chunkSize));
        counter += chunkSize;
      }
      return groups;
    },
  };

  window.GoogleMap = {
    map: null,
    Popup: null,
    selectObject: selectObject,
    focusMap: focusMap,
    loadCountryRelativeData: loadCountryRelativeData,

    definePopupClass() {
      GoogleMap.Popup = function (position, contentText, area, type) {
        this.position = position;
        this.area = area;
        this.type = type;
        const content = document.createElement('div');
        content.textContent = contentText;
        content.classList.add('popup-bubble-content');
        content.classList.add('popup-bubble-content-' + type);

        const pixelOffset = document.createElement('div');
        pixelOffset.classList.add('popup-bubble-anchor');
        pixelOffset.appendChild(content);

        this.anchor = document.createElement('div');
        this.anchor.classList.add('popup-tip-anchor');
        this.anchor.appendChild(pixelOffset);

        return this.stopEventPropagation();
      };

      GoogleMap.Popup.prototype = Object.create(
        google.maps.OverlayView.prototype
      );

      // Called when the popup is added to the map.
      GoogleMap.Popup.prototype.onAdd = function () {
        return this.getPanes().floatPane.appendChild(this.anchor);
      };

      // Called when the popup is removed from the map.
      GoogleMap.Popup.prototype.onRemove = function () {
        if (this.anchor.parentElement) {
          return this.anchor.parentElement.removeChild(this.anchor);
        }
      };

      GoogleMap.Popup.prototype.setVisible = function (visible) {
        let display;
        if (visible && this.display === 'block') {
          display = 'block';
        } else {
          display = 'none';
        }
        return (this.anchor.style.display = display);
      };

      // Called when the popup needs to draw itself.
      GoogleMap.Popup.prototype.draw = function () {
        let area_enought_to_display;
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        );

        const zoom = GoogleMap.map.getZoom();

        if (this.type === 'fields') {
          area_enought_to_display =
            (zoom >= 9 && this.area > 500000000) ||
            (zoom >= 10 && this.area > 200000000) ||
            (zoom >= 11 && this.area > 100000000) ||
            (zoom >= 12 && this.area > 11000000) ||
            zoom >= 13;
        } else if (this.type === 'pipelines') {
          area_enought_to_display = zoom >= 10;
        } else if (this.type === 'blocks' && GoogleMap.country_name === 'GoM') {
          area_enought_to_display = zoom >= 9;
        } else {
          area_enought_to_display =
            (zoom >= 8 && this.area > 2000000000) ||
            (zoom >= 9 && this.area > 500000000) ||
            (zoom >= 10 && this.area > 100000000) ||
            zoom >= 11;
        }

        const display =
          Math.abs(divPosition.x) < 4000 &&
          Math.abs(divPosition.y) < 4000 &&
          area_enought_to_display
            ? 'block'
            : 'none';
        this.display = display;
        if (display === 'block') {
          this.anchor.style.left = divPosition.x + 'px';
          this.anchor.style.top =
            divPosition.y +
            GoogleMap.map.getZoom() +
            (15 - GoogleMap.map.getZoom()) +
            'px';
        }
        if (this.anchor.style.display !== display) {
          return (this.anchor.style.display = display);
        }
      };
      // Stops clicks/drags from bubbling up to the map.
      return (GoogleMap.Popup.prototype.stopEventPropagation = function () {
        const { anchor } = this;
        anchor.style.cursor = 'auto';
        return [
          'click',
          'dblclick',
          'contextmenu',
          'wheel',
          'mousedown',
          'touchstart',
          'pointerdown',
        ].forEach((event) =>
          anchor.addEventListener(event, (e) => e.stopPropagation())
        );
      });
    },

    makeMapView(country) {
      if (!GoogleMap.map) {
        return;
      }

      Utils.getCoordinateByCountryName(country, function (coordinate) {
        if (country == JSCONSTANT.GOM_DISPLAY_NAME) {
          GoogleMap.map.setZoom(JSCONSTANT.mapGomZoom);
          return GoogleMap.map.setCenter(
            new google.maps.LatLng(...JSCONSTANT.gomLatLng)
          );
        } else if (country == JSCONSTANT.TOGO_DISPLAY_NAME) {
          GoogleMap.map.setZoom(JSCONSTANT.mapTogoZoom);
          return GoogleMap.map.setCenter(
            new google.maps.LatLng(...JSCONSTANT.togoLatLng)
          );
        } else if (country == JSCONSTANT.jordanName) {
          GoogleMap.map.setZoom(JSCONSTANT.mapDefaultZoom + 2);
          return GoogleMap.map.setCenter(
            new google.maps.LatLng(...JSCONSTANT.jordanLatLng)
          );
        } else if (country == JSCONSTANT.georgiaName) {
          GoogleMap.map.setZoom(JSCONSTANT.mapDefaultZoom + 4);
          return GoogleMap.map.setCenter(
            new google.maps.LatLng(...JSCONSTANT.georgiaLatLng)
          );
        } else {
          GoogleMap.map.setZoom(JSCONSTANT.mapDefaultZoom + 2);
          return GoogleMap.map.setCenter(coordinate);
        }
      });
    },

    resetMapView() {
      if (!GoogleMap.map) {
        return;
      }
      Utils.getCoordinateByCountryName(JSCONSTANT.mapDefaultCountry, function (
        coordinate
      ) {
        GoogleMap.map.setZoom(JSCONSTANT.mapDefaultZoom);
        return GoogleMap.map.setCenter(coordinate);
      });
    },

    initCircleMap(data, clearMarkers) {
      return initCircleMap(data, clearMarkers);
    },

    setDefaultHighlightFieldBlock() {
      if (!GoogleMap.markers) {
        return;
      }
      for (let field of Array.from(GoogleMap.markers.fields)) {
        field.setOptions({
          strokeColor: JSCONSTANT.markerOptions.strokeColor['fields'],
          strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
        });
      }

      for (let block of Array.from(GoogleMap.markers.blocks)) {
        block.setOptions({
          strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
          strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
        });
      }

      return Array.from(GoogleMap.markers.restricted_areas).map(
        (restricted_area) =>
          restricted_area.setOptions({
            strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
            strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
          })
      );
    },

    createWellInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('well', element, country_name);
    },

    createFacilityInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('facility', element, country_name);
    },

    createFieldInfoWindowContent(element, country_name, block_name) {
      return getInfoWindowHtml('field', element, country_name, {
        block_name: block_name,
      });
    },

    createBlockGroupInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('blockGroup', element, country_name);
    },

    createBlockInfoWindowContent(element, country_name, is_restricted_area) {
      return getInfoWindowHtml('block', element, country_name, {
        is_restricted_area: is_restricted_area,
      });
    },

    createBasinInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('basin', element, country_name);
    },

    createHydrocarbonStructureInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('hydrocarbonStructure', element, country_name);
    },

    createPipelineInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('pipeline', element, country_name);
    },

    createPipelineThroughputInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('pipelineThroughput', element, country_name);
    },

    createPresaltInfoWindowContent(element, country_name) {
      return getInfoWindowHtml('presalt', element, country_name);
    },

    createRefineryInfoWindowContent(element) {
      return getInfoWindowHtml('refinery', element, country_name);
    },

    createFormationShapfileInfoWindowContent(element) {
      const content = [];
      content.push(`<strong>Window:</strong> ${element.name}`);
      return content.join('<br>');
    },
  };
  const mapEvents = new MapEvents();
  mapEvents.init();
  window.getFilteredMapData = getFilteredMapData;
};

export default setMap;
