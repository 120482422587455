export default function focusMap() {
  if (!GoogleMap.markers) return

  let $form = $('form.filters_form')
  let objectSearchType, nameAsset, idAsset
  let countryName = $('#country').text()
  var values = $form.serializeArray()
  var filteredValues = values.filter((element) => {
    return element.value !== ''
  })
  let selectedObject
  let relatedFields =
    MAP_DATA.related_assets &&
    MAP_DATA.related_assets.find((rfs) => rfs.some((rf) => rf.name === nameAsset))

  filteredValues = filteredValues.map((e) => {
    return e.name
  })

  // search field or block by name
  const searchElements = [
    { name: 'fields', type: 'asset' },
    { name: 'blocks', type: 'block' },
    { name: 'hydrocarbon_structures', type: 'hydrocarbon_structure' },
    { name: 'wells', type: 'well' },
    { name: 'facilities', type: 'facility' },
  ]

  searchElements.forEach((item) => {
    let element = $(`#search_${item.type}_name`)

    if (element.text()) {
      objectSearchType = item.name
      nameAsset = element.text().replace(' (' + countryName + ')', '')
      idAsset = parseInt(element[0].value)

      return
    }
  })

  if (objectSearchType === 'hydrocarbon_structures') {
    selectedObject = filterNestedCollections(GoogleMap.markers.hydrocarbon_structures, (object) => {
      return object.customInfo && object.customInfo.name === nameAsset
    })
  } else if (objectSearchType === 'fields') {
    let relationships = filterNestedCollections(GoogleMap.relationship.field_blocks, (fb) => {
      return fb.block_id && fb.field_name === nameAsset
    })

    if (relationships && relationships.length) {
      objectSearchType = 'blocks'

      let blockIds = mapNestedCollections(relationships, (rela) => {
        return rela.block_id
      })

      selectedObject = filterNestedCollections(GoogleMap.markers.blocks, (marker) => {
        return marker.customInfo && blockIds.includes(marker.customInfo.id)
      })
    } else if (relatedFields && relatedFields.length) {
      let relatedFieldsNames = relatedFields.map((f) => f.name)

      selectedObject = filterNestedCollections(GoogleMap.markers.fields, (object) => {
        return object.customInfo && relatedFieldsNames.includes(object.customInfo.name)
      })
    } else {
      selectedObject = filterNestedCollections(GoogleMap.markers.fields, (object) => {
        return object.customInfo && object.customInfo.name === nameAsset
      })
    }
  } else if (objectSearchType === 'wells') {
    let wells = GoogleMap.markers.wells

    for (let wellType in wells) {
      let wellArray = wells[wellType]

      selectedObject = wellArray.filter((e) => {
        return e.customInfo.id === idAsset
      })

      if (selectedObject.length != 0) break
    }
  } else if (objectSearchType === 'facilities') {
    selectedObject = filterFacilities(GoogleMap.markers.facilities, nameAsset)
  } else {
    let selectedObjects

    selectedObjects =
      objectSearchType == 'fields' ? GoogleMap.markers.fields : GoogleMap.markers.blocks

    selectedObject = filterNestedCollections(selectedObjects, (object) => {
      return object.customInfo && object.customInfo.name === nameAsset
    })
  }

  selectedObject = flattenNestedCollections(selectedObject)

  if (selectedObject && selectedObject.length) {
    var coordinate, latLng

    if (['wells', 'facilities'].includes(objectSearchType)) {
      latLng = selectedObject[0].getPosition()
    } else {
      if (Array.isArray(selectedObject[0].customInfo.coordinates[0])) {
        coordinate = selectedObject[0].customInfo.coordinates[0][0]
      } else {
        const firstCoordinate = selectedObject[0].customInfo.coordinates[0].value[0]
        if (Array.isArray(firstCoordinate) && Array.isArray(firstCoordinate[0])) {
          coordinate = firstCoordinate[0]
        } else {
          coordinate = firstCoordinate
        }
      }

      latLng = new google.maps.LatLng(coordinate[0].lat, coordinate[0].lng)

      let displayOnlyFragment = selectedObject.filter((item) => item.isFragment)
      if (displayOnlyFragment.length != 0) {
        selectedObject = displayOnlyFragment
      }
    }

    GoogleMap.selectObject(selectedObject, objectSearchType, { latLng: latLng })
  }
}

const filterFacilities = (facilities, nameAsset) => {
  let selectedObject

  if (Array.isArray(facilities)) {
    selectedObject = facilities.filter((e) => {
      return e.customInfo.name === nameAsset
    })
  } else {
    for (let facilityType in facilities) {
      selectedObject = facilities[facilityType].filter((e) => e.customInfo.name === nameAsset)

      if (selectedObject.length != 0) break
    }
  }

  return selectedObject
}
