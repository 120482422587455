class HydrocarbonTypeFilter {
  constructor() {
    this.value = $('#search_hydrocarbon').val()
    this.isSelected = !!this.value
  }
  processData(filteredData) {
    if (this.isSelected) {
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filter.bind(this))
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, this.map.bind(this))
      filteredData.secondaryAssets = []
    }

    return filteredData
  }
  filter(item) {
    return item.hydrocarbon_type === this.value && parseFloat(item.api) !== 0
  }
  map(item) {
    if (item.selected) {
      item.selected = this.filter(item)
    }
    return item
  }
}

export default HydrocarbonTypeFilter
