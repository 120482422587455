import convertStringToFloat from './convertStringToFloat'
import moment from 'moment'

const drawLiquidLineChart = (facility, { displayTitle = true } = {}) => {
  const {
    id,
    facility_production,
    oil_capacity,
    water_injection_capacity: water_capacity,
  } = facility

  const ctx = document.getElementById(`liquid-line-chart-${id}`).getContext('2d')

  let oilProduction = []
  let oilCapacity = []
  let waterProduction = []
  let waterCapacity = []

  let { oil, water } = facility_production

  oil.forEach((t) => {
    oilProduction.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1], 0) })
    oilCapacity.push({
      x: moment(t[0]),
      y: Utils.formatProductionValue(convertStringToFloat(oil_capacity) / 1000, 0),
    })
  })

  water.forEach((t) => {
    waterProduction.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1], 0) })
    waterCapacity.push({
      x: moment(t[0]),
      y: Utils.formatProductionValue(convertStringToFloat(water_capacity) / 1000, 0),
    })
  })

  new Chart(ctx, {
    type: 'line',

    data: {
      datasets: [
        {
          label: 'Oil (bbl/d)',
          fill: false,
          borderColor: '#00B359',
          backgroundColor: '#00B359',
          borderWidth: 1,
          data: oilProduction,
        },
        {
          label: 'Oil Capacity (bbl/d)',
          fill: false,
          borderColor: '#82B200',
          backgroundColor: '#82B200',
          borderWidth: 1,
          data: oilCapacity,
        },
        {
          label: 'Water (bbl/d)',
          fill: false,
          borderColor: '#09ADF0',
          backgroundColor: '#09ADF0',
          borderWidth: 1,
          data: waterProduction,
        },
        {
          label: 'Water Capacity (bbl/d)',
          fill: false,
          borderColor: '#006CBF',
          backgroundColor: '#006CBF',
          borderWidth: 1,
          data: waterCapacity,
        },
      ],
    },

    options: {
      animation: {
        duration: 0, // general animation time
      },
      hover: {
        animationDuration: 0, // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0, // animation duration after a resize
      title: {
        display: displayTitle,
        text: '           Liquids Production',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 20,
          fontSize: 10,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'YYYY',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Thousand bbl/d',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  })
}

export default drawLiquidLineChart
