const CANVAS_LINE_WIDTH = 280
const CANVAS_LINE_HEIGHT = 300

class DrcFacilityInfoWindow {
  static get props() {
    return ['name', 'type_name', 'operator', 'contract', 'water_depth', 'line_break']
  }

  lines = []
  html = ''
  titlePieChartHTML = ''
  liquidLineChartHTML = ''
  gasLineChartHTML = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`
  }

  pushContent = (propName) => {
    let propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
      case 'name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }
        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default DrcFacilityInfoWindow
