import MinMaxFilter from './MinMaxFilter'

class CapexFilter extends MinMaxFilter {
  constructor() {
    super()
    this.min = $('#search_remaining_capex_min').val()
    this.max = $('#search_remaining_capex_max').val()
    this.value = [this.min, this.max]
    this.isSelected = !!(this.min || this.max)
  }
  filter(item) {
    let capex =
      typeof item.capex == 'string' ? parseFloat(item.capex.replace(/,/g, '')) : item.capex

    return this.min <= capex && capex <= this.max
  }
}

export default CapexFilter
