import React, { Component } from 'react';
import _ from 'lodash';
import { GeologyData, isPresent } from './util.js';
import { EraSelection } from './Components/EraSelection';
import { PeriodSelection } from './Components/PeriodSelection';
import { EpochSelection } from './Components/EpochSelection';
import { AgeSelection } from './Components/AgeSelection';
import { SimpleInput } from './Components/SimpleInput';
import { SimpleDropdown } from './Components/SimpleDropdown.js';

class GeologyForm extends Component {
  constructor(props) {
    super(props);

    let { geology, init, basins } = props;

    if (init) {
      this.state = this.initNewData(geology, basins);
    } else {
      this.state = this.initDefaultData(geology, basins);
    }
  }

  // new geology
  initNewData = (geology, basins) => {
    return {
      era: geology.era || null,
      period: geology.period || null,
      epoch: geology.epoch || null,
      age: geology.age || null,
      formation: '',
      play: '',
      primary_formation: geology.primary_formation,
      basin_id: null,
      basin: null,
      basins,
    };
  };

  // edit geology
  initDefaultData = (geology, basins) => {
    let {
      era,
      period,
      epoch,
      age,
      formation,
      play,
      primary_formation,
      basin_id,
    } = geology;
    let basinName;
    if (basin_id) {
      basinName = basins.find((b) => b.value === basin_id).label;
    }
    return {
      era: { value: era, label: era, name: 'era' },
      period: { value: period, label: period, name: 'period' },
      epoch: { value: epoch, label: epoch, name: 'epoch' },
      age: { value: age, label: age, name: 'age' },
      formation: formation,
      play: play,
      primary_formation: {
        value: primary_formation ? 1 : 0,
        label: primary_formation ? 'YES' : 'NO',
      },
      basin_id: basin_id,
      basin: basin_id ? { value: basin_id, label: basinName } : null,
      basins,
    };
  };

  simpleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name.match(/\[(.*?)\]/)[1];

    this.setState({
      [name]: value,
    });
  };

  simpleDropdownChange = (selectedOption) => {
    this.setState({
      primary_formation: selectedOption,
    });
  };

  basinChange = (selectedOption) => {
    this.setState({
      basin_id: selectedOption.value,
      basin: selectedOption,
    });
  };

  onChange = (nameSelect) => (selectedOption) => {
    let { era, period, epoch } = this.state;

    if (selectedOption === null) {
      let name = nameSelect;

      let objectChange = {
        [name]: null,
      };

      if (name === 'era') {
        objectChange = {
          ...objectChange,
          period: null,
          epoch: null,
          age: null,
        };
      }

      if (name == 'period') {
        objectChange = {
          ...objectChange,
          epoch: null,
          age: null,
        };
      }

      if (name == 'epoch') {
        objectChange = {
          ...objectChange,
          age: null,
        };
      }

      if (name === 'age') {
        objectChange = {
          ...objectChange,
        };
      }

      this.setState({ ...objectChange });
    } else {
      let { name } = selectedOption;

      let prevSelectedOption = _.get(this.state, name);

      if (_.isEqual(prevSelectedOption, selectedOption)) {
        return;
      }

      let objectChange = {
        [name]: selectedOption,
      };

      if (name === 'era') {
        objectChange = {
          ...objectChange,
          period: null,
          epoch: null,
          age: null,
        };
      }

      if (name == 'period') {
        if (_.isEmpty(era)) {
          let era_value = GeologyData.filter(
            (t) => t['Period'] === selectedOption.value
          )[0]['Era'];

          objectChange = {
            ...objectChange,
            era: {
              value: era_value,
              label: era_value,
              name: 'era',
            },
          };
        }
        objectChange = {
          ...objectChange,
          epoch: null,
          age: null,
        };
      }

      if (name == 'epoch') {
        if (_.isEmpty(period)) {
          let period_value = GeologyData.filter(
            (t) => t['Epoch'] === selectedOption.value
          );

          if (_.isEmpty(era)) {
            period_value = period_value[0]['Period'];
          } else {
            period_value = period_value.filter(
              (t) => t['Era'] === era.value
            )[0]['Period'];
          }

          objectChange = {
            ...objectChange,
            period: {
              value: period_value,
              label: period_value,
              name: 'period',
            },
          };
        }

        if (_.isEmpty(era)) {
          let era_value = GeologyData.filter(
            (t) => t['Epoch'] === selectedOption.value
          )[0]['Era'];

          objectChange = {
            ...objectChange,
            era: {
              value: era_value,
              label: era_value,
              name: 'era',
            },
          };
        }

        objectChange = {
          ...objectChange,
          age: null,
        };
      }

      if (name === 'age') {
        if (_.isEmpty(era)) {
          let era_value = GeologyData.filter(
            (t) => t['Age'] === selectedOption.value
          )[0]['Era'];

          objectChange = {
            ...objectChange,
            era: {
              value: era_value,
              label: era_value,
              name: 'era',
            },
          };
        }

        if (_.isEmpty(period)) {
          let period_value = GeologyData.filter(
            (t) => t['Age'] === selectedOption.value
          );

          if (_.isEmpty(era)) {
            period_value = period_value[0]['Period'];
          } else {
            period_value = period_value.filter(
              (t) => t['Era'] === era.value
            )[0]['Period'];
          }

          objectChange = {
            ...objectChange,
            period: {
              value: period_value,
              label: period_value,
              name: 'period',
            },
          };
        }

        if (_.isEmpty(epoch)) {
          let epoch_value = GeologyData.filter(
            (t) => t['Age'] === selectedOption.value
          );

          if (isPresent(era)) {
            epoch_value = epoch_value.filter((t) => t['Era'] === era.value);
          }

          if (_.isEmpty(period)) {
            epoch_value = epoch_value[0]['Epoch'];
          } else {
            epoch_value = epoch_value.filter(
              (t) => t['Period'] === period.value
            )[0]['Epoch'];
          }

          objectChange = {
            ...objectChange,
            epoch: {
              value: epoch_value,
              label: epoch_value,
              name: 'epoch',
            },
          };
        }
      }

      this.setState({ ...objectChange });
    }
  };

  render() {
    let state = _.pick(this.state, ['era', 'period', 'epoch', 'age', 'basin']);
    console.log(state.basin);
    let { formation, play, primary_formation, basins, basin } = this.state;
    let options = [
      { value: 1, label: 'YES' },
      { value: 0, label: 'NO' },
    ];
    return (
      <React.Fragment>
        <EraSelection onChange={this.onChange} state={state} />
        <PeriodSelection onChange={this.onChange} state={state} />
        <EpochSelection onChange={this.onChange} state={state} />
        <AgeSelection onChange={this.onChange} state={state} />
        <SimpleInput
          title={'Formation'}
          name={'formation'}
          value={formation}
          onChange={this.simpleChange}
        />
        <SimpleInput
          title={'Play'}
          name={'play'}
          value={play}
          onChange={this.simpleChange}
        />
        <SimpleDropdown
          title={'Primary Formation'}
          name={'geology[primary_formation]'}
          onChange={this.simpleDropdownChange}
          options={options}
          value={primary_formation}
        />
        <SimpleDropdown
          title={'Basin'}
          name={'geology[basin_id]'}
          onChange={this.basinChange}
          options={basins}
          value={basin}
        />
      </React.Fragment>
    );
  }
}

export default GeologyForm;
