class BaseBlockGroupInfoWindow {
  static get props() {
    return ['name', 'participation', 'water_depth', 'area']
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  pushContent = (propName) => {
    let propValue, propTitle

    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.block_group.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BaseBlockGroupInfoWindow
