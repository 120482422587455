class UgandaPipelineInfoWindow {
  static get props() {
    return [
      'name',
      'pipeline_type',
      'operator',
      'length',
      'diameter_inches',
      'capacity',
      'origin',
      'destination',
      'stream',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  pushContent = (propName) => {
    let propValue, propTitle
    switch (propName) {
      case 'capacity':
        if (this.element.capacity) {
          if (this.element.pipeline_type === 'Oil' && parseFloat(this.element.capacity) !== 0) {
            return this.lines.push(
              `<strong>${I18n.t('js.customer.maps.content.pipeline.capacity')} (bbl/d):</strong> ${
                this.element.capacity
              }`,
            )
          } else if (
            this.element.pipeline_type === 'Gas' &&
            parseFloat(this.element.capacity) !== 0
          ) {
            return this.lines.push(
              `<strong>${I18n.t('js.customer.maps.content.pipeline.capacity')} (MMcf/d):</strong> ${
                this.element.capacity
              }`,
            )
          }
        } else {
          return
        }
        break
      case 'diameter_inches':
        if (this.element.diameter && parseFloat(this.element.diameter)) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.pipeline.diameter_inches')}:</strong> ${
              this.element.diameter
            }`,
          )
        }
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.pipeline.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default UgandaPipelineInfoWindow
