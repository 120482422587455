const addPopup = (centerLocation, name, area, key, subkey, marker) => {
  const popup = new GoogleMap.Popup(centerLocation, name, area, key)
  popup.setMap(GoogleMap.map)
  if (marker) {
    marker.text = popup
  } else if (!subkey) {
    if (GoogleMap.markers[key]['Popup'] === undefined) {
      GoogleMap.markers[key]['Popup'] = []
    }
    GoogleMap.markers[key]['Popup'].push(popup)
  }

  return popup
}

export default addPopup
