import linkSubcriberOverview from '../common'

class BaseBlockInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'area',
      'water_depth',
      'depth',
      'shore_status',
      'geology',
      'api',
      'line_break',
      'status',
      'participation',
      'fiscal_regime',
      'round',
      'goverment_profit_share',
      'royalty_rate',
      'signature_bonus',
      'first_prod',
      'contract_signed',
      'contract_start',
      'expiration_date',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'recent_oil',
      'recent_gas',
      'active_wells',
      'total_wells_drilled',
      'well_commitment',
      'wells_drilled',
      'min_work_program',
      'transportation_method',
      'trucking_destination',
      'pipeline_network',
      'pipeline_network_segment',
      'access_coverage',
      'enhanced_access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { is_restricted_area }) {
    this.element = element
    this.country_name = country_name
    this.is_restricted_area = is_restricted_area || false

    let relatedBlock =
      MAP_DATA.related_assets &&
      MAP_DATA.related_assets.find((rfs) => rfs.some((rf) => rf.name === element.name))

    if (relatedBlock) {
      this.html = `
        <div class="tabs-wrapper">
          <div class="tabs-head">
            ${this.getRelatedFieldsTabsHeader(relatedBlock, props)}
          </div>
          <div class="tabs-body">
            ${this.getRelatedFieldsHtml(relatedBlock, props)}
          </div>
        </div>
      `
    } else {
      this.html = this.getElementHtml(element, props)
    }
  }

  removeDuplicate = (lines) => {
    let prevLine

    return lines.filter((line, index) => {
      let compare = true

      if (index !== 0) {
        compare = line !== prevLine
      }

      prevLine = line

      return compare
    })
  }

  getRelatedFieldsHtml = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<div id="${f.name.parameterize()}" class="tab${i === 0 ? ' active' : ''}">
          ${this.getElementHtml(f, props)}
        </div>`
      })
      .join('')
  }

  getRelatedFieldsTabsHeader = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<a href="#${f.name.parameterize()}" class="tab-head-item${
          i === 0 ? ' active' : ''
        }">
          ${f.name}
        </a>`
      })
      .join('')
  }

  getElementHtml = (el, props) => {
    let lines = []
    let well_productions, propValue, propTitle, asset_id, asset_type
    props.forEach((propName) => {
      switch (propName) {
        case 'line_break':
          lines.push('')
          break
        case 'recent_gas':
          if (this.country_name != 'GoM' && el.block_type !== 'blocks_production') return
          let gasPeriod =
            el.most_recent_period ||
            (el.well_productions && el.well_productions.gas && el.well_productions.gas.period)
          let gasValue =
            el.recent_gas_prod ||
            (el.well_productions && el.well_productions.gas && el.well_productions.gas.value) ||
            0
          if (gasValue > 0 && gasValue < 0.1) {
            gasValue = 0.1
          }
          if (gasPeriod && parseFloat(gasValue) != 0) {
            lines.push(
              `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
                period: gasPeriod,
              })}:</strong> ${Utils.formatProductionValue(gasValue, 1)}`,
            )
          }
          break
        case 'recent_oil':
          if (this.country_name != 'GoM' && el.block_type !== 'blocks_production') return
          let oilPeriod =
            el.most_recent_period ||
            (el.well_productions && el.well_productions.oil && el.well_productions.oil.period)
          let oilValue =
            el.recent_oil_prod ||
            (el.well_productions && el.well_productions.oil && el.well_productions.oil.value) ||
            0
          if (oilValue > 0 && oilValue < 0.1) {
            oilValue = 0.1
          }
          if (oilPeriod && oilValue != 0) {
            lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_liquid_production_period',
                { period: oilPeriod },
              )}:</strong> ${Utils.formatProductionValue(oilValue, 1)}`,
            )
          }
          break
        case 'current_prod':
          if (el.most_recent_period) {
            let current_prod = parseFloat(el.current_prod) || 0
            if (current_prod > 0 && current_prod < 0.1) {
              current_prod = 0.1
            }
            lines.push(
              `<strong>${I18n.t('js.customer.maps.content.block.most_recent_production_period', {
                period: this.element.most_recent_period,
              })}:</strong> ${Utils.formatProductionValue(current_prod, 1)}`,
            )
          }
          break
        case 'access_coverage':
          if (el.access_coverage) {
            asset_type = 'Field'
            asset_id = this.element.id
          } else if (el.asset_model_id) {
            asset_type = 'Field'
            asset_id = this.element.asset_model_id
          }
          if (!asset_type || !asset_id) break

          lines.push(
            `<a href='${Routes.customer_download_path({
              country: this.country_name,
              asset_id,
              asset_type,
              type: 'report',
              timestamp: Date.now(),
            })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
          )
          lines.push(
            `<a target='_blank' href='${Routes.customer_download_path({
              country: this.country_name,
              asset_id,
              asset_type,
              type: 'pdf_report',
              timestamp: Date.now(),
            })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
          )
          break
        case 'enhanced_access_coverage':
          if (el.enhanced_access_coverage) {
            asset_type = 'Field'
            asset_id = el.id
          } else if (el.asset_model_id) {
            asset_type = 'Field'
            asset_id = el.asset_model_id
          } else if (el.asset_model) {
            asset_type = el.asset_model[0]
            asset_id = el.asset_model[1]
          }

          if (!asset_type || !asset_id) break

          lines.push(
            `<a href='${Routes.customer_download_path({
              country: this.country_name,
              asset_type,
              asset_id,
              type: 'report',
              timestamp: Date.now(),
            })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
          )
          lines.push(
            `<a target='_blank' href='${Routes.customer_download_path({
              country: this.country_name,
              asset_type,
              asset_id,
              type: 'enhanced_pdf_report',
              timestamp: Date.now(),
            })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
          )
          lines.push(linkSubcriberOverview(this.country_name))

          break
        case 'oil_reserves':
          propValue = el.oil_reserves
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            if (parseFloat(propValue) && propName !== 'license_expiration') {
              propValue = Utils.formatProductionValue(propValue, 1)
            }
            propTitle = I18n.t('js.customer.maps.content.block.' + propName)
            propTitle =
              this.country_name === 'GoM'
                ? I18n.t('js.customer.maps.content.block.liquids_reserves')
                : propTitle
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
          break
        case 'first_prod':
          if (el.first_prod_date) {
            lines.push(
              `<strong>${I18n.t('js.customer.maps.content.block.first_prod')}:</strong> ${
                el.first_prod_date
              }`,
            )
          }
          break
        case 'basin':
          propValue = el.basin
          if (propValue) {
            propName = this.country_name === 'GoM' ? 'protraction_area' : propName
            lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.block.' + propName,
              )}:</strong> ${propValue}`,
            )
          }
          break
        default:
          propValue = el[propName]
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            propTitle = I18n.t('js.customer.maps.content.block.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
      }
    })
    return this.removeDuplicate(lines).join('<br>')
  }
}

export default BaseBlockInfoWindow
