import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { titleize, humanize } from '../util';
import { Spinner } from '../UI/Spinner';

/**
 * Renders a component that displays the time a Jenkins build item was queued.
 *
 * @param {Object} props - The component props.
 * @param {string} props.timestamp - The timestamp of when the build item was queued.
 * @returns {JSX.Element} - A React component that displays the queued time.
 */
const QueuedTime = ({ timestamp }) => {
  const queuedTime = moment(timestamp, true);
  if (!queuedTime.isValid()) return null;

  return (
    <span className="timestamp">
      {queuedTime.fromNow()}
      <span className="button_tooltip">
        {queuedTime.format('YYYY-MM-DD HH:mm:ss')} UTC
      </span>
    </span>
  );
};

/**
 * Renders a section that displays a list of Jenkins build items that are currently queued.
 *
 * The component fetches the queued items from the server and displays them in a list. Each queued item is displayed with its parameters and the time it was queued.
 *
 * @returns {JSX.Element} - A React component that displays the list of queued Jenkins build items.
 */
const QueuedItems = () => {
  const [items, setItems] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    fetch('/admin/report_builds/queued_items', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setItems(data.items);
        setLoaded(true);
      })
      .catch((error) => {
        setLoaded(true);
        setErrorMessage(error.message);
      });
  }, []);

  if (!loaded)
    return (
      <div className="text-center">
        <Spinner width={14} height={14} />
      </div>
    );

  if (loaded && errorMessage)
    return <div className="text-center">{errorMessage}</div>;

  const handleCancelItem = (itemId) => {
    return (event) => {
      event.preventDefault();
      fetch(`/admin/report_builds/cancel_build?item_id=${itemId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        },
      }).then((_res) => {
        setItems(items.filter((item) => item.id !== itemId));
      });
    };
  };

  return (
    <div className="box-workers jenkins-build-status report-generation-builds">
      <div className="workers-content">
        <div className="workers-table">
          <div className="wt-head">Build Number</div>
          <div className="wt-head">Status</div>
          <div className="wt-head">Timestamp</div>
          <div className="wt-head">Parameters</div>
          <div className="wt-head">Actions</div>
          {items.length > 0 ? (
            items.map((item) => (
              <>
                <div className="wt-item">-</div>
                <div className="wt-item">
                  <div className="badge queued">QUEUED</div>
                </div>
                <div className="wt-item">
                  <QueuedTime timestamp={item.timestamp} />
                </div>
                <div className="wt-item">
                  <table className="jenkins-build-parameters">
                    <tbody>
                      {Object.entries(item.params).map((entry) => {
                        const [param_key, param_value] = entry;
                        if (['RUBY_BRANCH'].includes(param_key)) return;

                        let value = param_value;
                        if (param_value.includes(',')) {
                          value = param_value
                            .split(',')
                            .map((value) => titleize(humanize(value)))
                            .join(', ');
                        } else {
                          value = titleize(humanize(param_value));
                        }
                        return (
                          <tr key={param_key}>
                            <th>{titleize(humanize(param_key))}</th>
                            <td className="pl-2">{value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="wt-item">
                  <button
                    className="mx-auto btn btn-danger destroy-button"
                    onClick={handleCancelItem(item.id)}
                  >
                    Cancel
                  </button>
                </div>
              </>
            ))
          ) : (
            <div className="empty-queue wt-item">
              <p>All done! ✅</p>
              <p>No more reports in the queue.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QueuedItems;
