const CANVAS_LINE_WIDTH = 280;
const CANVAS_LINE_HEIGHT = 300;

class CoteDIvoireFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'operator',
      'contract',
      'water_depth',
      'line_break',
      'oil_capacity',
      'gas_capacity',
      'gas_injection',
      'water_injection',
      'line_break',
      'construction',
      'award_date',
      'storage',
      'productions',
    ];
  }

  lines = [];
  html = '';
  titlePieChartHTML = '';
  liquidLineChartHTML = '';
  gasLineChartHTML = '';

  constructor(element, country_name, props) {
    this.element = element;
    this.country_name = country_name;

    props.forEach((prop) => {
      this.pushContent(prop);
    });

    this.html = `<div style="position: relative">${this.lines.join(
      '<br>'
    )}</div>`;

    this.bodyInformation = `<div style="position: relative">${this.lines.join(
      '<br>'
    )}</div>`;

    if (this.liquidLineChartHTML || this.gasLineChartHTML) {
      this.html = `
        ${this.bodyInformation}<br>
        <div class='row'>
          ${this.titlePieChartHTML}
        </div>\
        <div id="chart" class='row chart-row'>
        ${this.liquidLineChartHTML}
        ${this.gasLineChartHTML}
        </div>\
      `;
    }
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle;
    switch (propName) {
      case 'line_break':
        return this.lines.push('');
      case 'oil_capacity':
        this.processOilCapacity();
        break;
      case 'productions':
        let production;
        if ((production = this.element.production)) {
          const { period } = production;
          this.lines.push(' ');
          this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.facility.current_liquids_throughput',
              {
                period,
              }
            )}</strong> ${production.liquids || '0.00'}`
          );
          this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.facility.current_gas_throghput',
              {
                period,
              }
            )}</strong> ${production.gas || '0.00'}`
          );

          this.titlePieChartHTML = `\
            <div class='col-md-12 text-center'>
              <h4 id='title-pie-chart-${this.element.id}'>Historical Production</h4>
            </div>\
          `;
          this.liquidLineChartHTML = `\
            <div class='col-md-6'>
              <canvas id='liquid-line-chart-${this.element.id}' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT}'>
            </div>\
          `;
          this.gasLineChartHTML = `\
            <div class='col-md-6'>
              <canvas id='gas-line-chart-${this.element.id}' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT}'>
            </div>\
          `;
        }
        break;
      case 'name':
        propValue = this.element[propName];
        if (!(propValue && propValue !== 'N/A')) {
          break;
        }
        propTitle = I18n.t('js.customer.maps.content.facility.' + propName);
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);

      case 'associated_blocks':
        this.processAssociatedBlock();
        break;
      case 'utilization':
        this.processUtilization();
        break;
      default:
        propValue = this.element[propName];
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName);
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
        }
    }
  };

  processUtilization = () => {
    let propValue = this.element.utilization;

    if (propValue) {
      let propTitle = I18n.t('js.customer.maps.content.facility.utilization');
      return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}%`);
    }
  };

  processUtilization = () => {
    let propValue = this.element.utilization;

    if (propValue) {
      let propTitle = I18n.t('js.customer.maps.content.facility.utilization');
      return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}%`);
    }
  };

  processOilCapacity = () => {
    let propValue = this.element.oil_capacity;
    let propTitle;

    if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
      propTitle = I18n.t(
        'js.customer.maps.content.facility.oil_processing_capacity'
      );
      return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
    }
  };

  processAssociatedBlock = () => {
    let propValue = this.element.associated_blocks;

    if (propValue) {
      let propTitle = I18n.t(
        'js.customer.maps.content.facility.associated_blocks'
      );
      return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
    }
  };
}

export default CoteDIvoireFacilityInfoWindow;
