const buildSubCheckboxPipeline = () => {
  if (GoogleMap.country_name === 'GoM') {
    $('#upstream, #midstream, #infield')
      .parent()
      .removeClass('hidden')
  } else {
    $('#upstream, #midstream, #infield')
      .parent()
      .addClass('hidden')
  }
}

export default buildSubCheckboxPipeline
