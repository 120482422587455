const buildCheckboxChildForPipeline = (subheader, parent, type) => {
  return `<li> \
<div class='checkbox checkbox-${type || 'default'}'> \
<input type='checkbox' name='${subheader}' id='${subheader}' value='true' class='sub-option ' data-parent='${parent}' checked='checked'> \
<label class='layer-label' for='${subheader}'>${I18n.t(
    `js.customer.maps.index.${subheader}`,
  )}</label> \
</div> \
</li>`
}

export default buildCheckboxChildForPipeline
