const drawOilChart = ({ id, production, facility_production, oil_capacity }, option) => {
  const oilNode = document.getElementById(`oil-pie-chart-${id}`)

  const { produce_oil } = facility_production
  const currentOil = parseFloat(production.oil.replace(',', ''))
  const oilCapacity = parseFloat(oil_capacity.replace(',', ''))
  const remainOilProduction = oilCapacity > currentOil ? oilCapacity - currentOil : 0

  if (oilCapacity > 0 && produce_oil && (remainOilProduction > 0 || currentOil > 0)) {
    option.count += 1

    const oilConfig = {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [currentOil.toFixed(0), remainOilProduction.toFixed(0)],
            backgroundColor: ['#00b359', '#d3d3d3'],
            label: 'Dataset 1',
          },
        ],
        labels: ['Oil', 'Free Capacity'],
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            precision: 1,
            fontSize: 12,
            fontColor: '#fff',
          },
        },
        responsive: false,
        legend: {
          labels: {
            boxWidth: 30,
            fontSize: 10,
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let dataLabel = data.labels[tooltipItem.index]
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              value = Utils.formatProductionValue(value, 0)

              return dataLabel + ': ' + value
            },
          },
        },
      },
    }

    new Chart(oilNode.getContext('2d'), oilConfig)
  } else {
    oilNode.parentNode.remove()
  }
}

export default drawOilChart
