const CANVAS_LINE_WIDTH = 280
const CANVAS_LINE_HEIGHT = 300

class NigerFacilityInfoWindow {
  static get props() {
    return ['name', 'type_name', 'operator', 'contract', 'water_depth', 'line_break', 'productions']
  }

  lines = []
  html = ''
  titlePieChartHTML = ''
  liquidLineChartHTML = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    if (this.liquidLineChartHTML) {
      this.html = `
        ${this.bodyInformation}<br>
        <div class='row'>
          ${this.titlePieChartHTML}
        </div>\
        <div id="chart" class='row chart-row'>
        ${this.liquidLineChartHTML}
        </div>\
      `
    }
  }

  pushContent = (propName) => {
    let propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
      case 'productions':
        let production
        if ((production = this.element.production)) {
          const { period } = production
          this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.facility.oil_processing_capacity',
            )}</strong> ${this.element.oil_capacity || '0.00'}`,
          )
          this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.facility.current_oil_throghput', {
              period,
            })}</strong> ${production.oil || '0.00'}`,
          )
          this.lines.push(' ')
          this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.facility.current_liquids_throughput', {
              period,
            })}</strong> ${production.liquids || '0.00'}`,
          )

          this.titlePieChartHTML = `\
            <div class='col-md-12 text-center'>
              <h4 id='title-pie-chart-${this.element.id}'>Historical Production</h4>
            </div>\
          `
          this.liquidLineChartHTML = `\
            <canvas id='liquid-line-chart-${
              this.element.id
            }' width='${CANVAS_LINE_WIDTH}' height='${(CANVAS_LINE_HEIGHT * 2) / 3}'>
            \
          `
        }
        break
      case 'name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }
        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default NigerFacilityInfoWindow
