import BaseWellInfoWindow from './BaseWellInfoWindow'

class SenegalWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'well_design',
      'total_depth',
      'water_blade',
      'result',
      'operator',
      'line_break',
      'drilling_start',
      'drilling_end',
      'drill_days',
    ]
  }
}

export default SenegalWellInfoWindow
