const drawGasChart = ({ id, production, facility_production, gas_capacity }, option) => {
  const gasNode = document.getElementById(`gas-pie-chart-${id}`)

  const { produce_gas } = facility_production
  const currentGas = parseFloat(production.gas.replace(',', ''))
  const gasCapacity = parseFloat(gas_capacity.replace(',', ''))
  const remainGasProduction = gasCapacity > currentGas ? gasCapacity - currentGas : 0

  if (gasCapacity > 0 && produce_gas && (remainGasProduction > 0 || currentGas > 0)) {
    option.count += 1

    const gasConfig = {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [currentGas, remainGasProduction],
            backgroundColor: ['#ff3333', '#d3d3d3'],
            label: 'Dataset 1',
          },
        ],
        labels: ['Gas', 'Free Capacity'],
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            precision: 1,
            fontSize: 12,
            fontColor: '#fff',
          },
        },
        responsive: false,
        legend: {
          labels: {
            boxWidth: 30,
            fontSize: 10,
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let dataLabel = data.labels[tooltipItem.index]
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              value = Utils.formatProductionValue(value, 0)

              return dataLabel + ': ' + value
            },
          },
        },
      },
    }

    new Chart(gasNode.getContext('2d'), gasConfig)
  } else {
    gasNode.parentNode.remove()
  }
}

export default drawGasChart
