import BaseBlockInfoWindow from './BaseBlockInfoWindow'
import ColombiaBlockInfoWindow from './ColombiaBlockInfoWindow'
import BahamasBlockInfoWindow from './BahamasBlockInfoWindow'
import DominicanRepublicBlockInfoWindow from './DominicanRepublicBlockInfoWindow'
import GuyanaBlockInfoWindow from './GuyanaBlockInfoWindow'
import GhanaBlockInfoWindow from './GhanaBlockInfoWindow'
import NamibiaBlockInfoWindow from './NamibiaBlockInfoWindow'
import TrinidadBlockInfoWindow from './TrinidadBlockInfoWindow'

export default {
  default: BaseBlockInfoWindow,
  Colombia: ColombiaBlockInfoWindow,
  Bahamas: BahamasBlockInfoWindow,
  'Dominican Republic': DominicanRepublicBlockInfoWindow,
  Guyana: GuyanaBlockInfoWindow,
  Ghana: GhanaBlockInfoWindow,
  Namibia: NamibiaBlockInfoWindow,
  'Trinidad and Tobago': TrinidadBlockInfoWindow,
}
