import BasePipelineInfoWindow from './BasePipelineInfoWindow'
import ChilePipelineInfoWindow from './ChilePipelineInfoWindow'
import TrinidadPipelineInfoWindow from './TrinidadPipelineInfoWindow'
import GuyanaPipelineInfoWindow from './GuyanaPipelineInfoWindow'
import UgandaPipelineInfoWindow from './UgandaPipelineInfoWindow'

export default {
  default: BasePipelineInfoWindow,
  Chile: ChilePipelineInfoWindow,
  'Trinidad and Tobago': TrinidadPipelineInfoWindow,
  Guyana: GuyanaPipelineInfoWindow,
  Uganda: UgandaPipelineInfoWindow,
}
