const CANVAS_LINE_WIDTH = 280
const CANVAS_LINE_HEIGHT = 280

class BasePipelineThroughputInfoWindow {
  static get props() {
    return ['name', 'pipeline_type', 'pipeline_segment_throughput', 'associated_assets']
  }

  lines = []
  html = ''
  titlePieChartHTML = ''
  liquidLineChartHTML = ''
  gasLineChartHTML = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    if (this.liquidLineChartHTML || this.gasLineChartHTML) {
      this.html = `
        ${this.bodyInformation}<br>
        <div class='row'>
          ${this.titlePieChartHTML}
        </div>\
        <div id="chart" class='row chart-row'>
        ${this.liquidLineChartHTML}
        ${this.gasLineChartHTML}
        </div>\
      `
    }
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      case 'name':
      case 'pipeline_type':
        if (this.element[propName]) {
          propValue = this.element[propName]
          propTitle = I18n.t('js.customer.maps.content.pipeline_segment.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'pipeline_segment_throughput':
        let production = this.element.pipeline_segment_throughput
        this.lines.push(' ')

        if (this.element.pipeline_type == 'Oil') {
          this.titlePieChartHTML = `\
            <div class='col-md-12 text-center'>
              <h4 id='title-pie-chart-${this.element.id}'>Liquids Throughput</h4>
            </div>\
          `
          this.liquidLineChartHTML = `\
            <div class='col-md-12'>
              <canvas id='liquid-pipeline-throughput-line-chart-${this.element.id}' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT}'>
            </div>\
          `
        } else {
          this.titlePieChartHTML = `\
            <div class='col-md-12 text-center'>
              <h4 id='title-pie-chart-${this.element.id}'>Gas Throughput</h4>
            </div>\
          `
          this.gasLineChartHTML = `\
            <div class='col-md-12'>
              <canvas id='gas-pipeline-throughput-line-chart-${this.element.id}' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT}'>
            </div>\
          `
        }
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.pipeline_segment.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BasePipelineThroughputInfoWindow
