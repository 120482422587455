import BaseFacilityInfoWindow from './BaseFacilityInfoWindow'

class IsraelFacilityInfoWindow extends BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'country',
      'type_name',
      'contract_type',
      'water_depth',
      'field_name',
      'associated_blocks',
      'install_date',
      'capacity',
      'gas_capacity',
    ]
  }
}

export default IsraelFacilityInfoWindow
