const drawPipeline = (coordinate, visible, key, type, pipeline) => {
  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4,
  }

  const dataPipeline = {
    path: coordinate,
    visible,
    strokeColor: JSCONSTANT.mapColors[key][type],
    strokeOpacity: JSCONSTANT.markerOptions.strokeOpacity,
    strokeWeight: JSCONSTANT.markerOptions.pipelineStrokeWeight,
    customInfo: pipeline,
    map: GoogleMap.map,
    type: key,
    zIndex: JSCONSTANT.zIndex[key],
  }
  if (pipeline.planned) {
    dataPipeline.icons = [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '20px',
      },
    ]
    dataPipeline.strokeOpacity = 0
  }

  return new google.maps.Polyline(dataPipeline)
}

export default drawPipeline
