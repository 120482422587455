class BaseHydrocarbonStructureInfoWindow {
  static get props() {
    return ['name', 'hydrocarbon_type', 'water_depth']
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  pushContent = (propName) => {
    let propValue, propTitle

    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      case 'hydrocarbon_type':
        this.lines.push(
          `<strong>${I18n.t(
            'js.customer.maps.content.hydrocarbon_structure.hydrocarbon_type',
          )}:</strong> ${this.element.hydrocarbon_type === 'oil' ? 'Oil' : 'Gas'}`,
        )
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            propValue = Utils.formatProductionValue(propValue, 1)
          }
          propTitle = I18n.t('js.customer.maps.content.hydrocarbon_structure.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BaseHydrocarbonStructureInfoWindow
