import MeasureTool from 'measure-tools';
import setMapOnAll from './mapHelpers/setMapOnAll';
import updateMarkersOptions from './mapHelpers/updateMarkersOptions';
import initMapWithoutMarker from './mapHelpers/initMapWithoutMarker';

class MapEvents {
  init() {
    this.defaultCountry = 'Brazil';
    this.initDefaultMap();
    this.turnLayersOnOff();
    this.toggleIndeterminateCheckbox();
    this.changeMapAccordionIcon();
  }

  toggleMenu() {
    $(function () {
      $('button.menu-toggle').click(function () {
        const _this = $(this);
        const icon = _this.find('i');
        icon.text(function (i, text) {
          if (text === 'settings') {
            return 'close';
          } else {
            return 'settings';
          }
        });
        return _this.parents('.show-maps').toggleClass('options-active');
      });
    });
  }

  initDefaultMap() {
    const _this = this;
    Utils.getCoordinateByCountryName(
      this.defaultCountry,
      function (coordinate) {
        GoogleMap.roundUpValue = 0;
        GoogleMap.map = initMapWithoutMarker('maps', coordinate);
        GoogleMap.measureTool = new MeasureTool(GoogleMap.map);

        _this.toggleMenu();

        $('button.ruler').on('click', function (e) {
          const $this = $(this);
          e.preventDefault();
          if ($this.hasClass('active')) {
            GoogleMap.measureTool.end();
            updateMarkersOptions({ clickable: true });
          } else {
            GoogleMap.measureTool.start([]);
            updateMarkersOptions({ clickable: false });
          }
          return $this.toggleClass('active');
        });
        return $(document).trigger('mapLoaded');
      },
    );
  }

  turnLayersOnOff() {
    const elementsWillBeRemoved = [];
    $('.maps-filter-control').on(
      'change',
      "input[type='checkbox']",
      function () {
        const _this = $(this);
        const options = {
          checkbox: _this.prop('name'),
          visible: _this.prop('checked'),
          hasChild:
            _this
              .parent()
              .siblings('ul:not(.hidden):not(.filter-configuration)').length >
            0,
          parent: _this.data('parent'),
        };
        return setMapOnAll(options);
      },
    );
  }

  toggleIndeterminateCheckbox() {
    $('.maps-filter-control').on('click', 'input.sub-option', function () {
      const parent = $(`#${$(this).data('parent')}`);
      if (
        $(this).closest('ul').find('input:checked').length ===
        $(this).closest('ul').find('input').length
      ) {
        parent.prop('indeterminate', false);
        return parent.prop('checked', true);
      } else {
        parent.prop('checked', false);
        return parent.prop(
          'indeterminate',
          !!$(this).closest('ul').find('input:checked').length,
        );
      }
    });

    return $('.maps-filter-control').on(
      'click',
      '#pipelines, #secondary_pipelines, #wells, #blocks, #facilities, #roadways',
      function () {
        if ($(this).is(':checked')) {
          return $(this)
            .parent()
            .siblings('ul')
            .find('input:not(.checkbox-config)')
            .each(function () {
              return $(this).prop('checked', true);
            });
        } else {
          return $(this)
            .parent()
            .siblings('ul')
            .find('input:not(.checkbox-config)')
            .each(function () {
              return $(this).prop('checked', false);
            });
        }
      },
    );
  }

  changeMapAccordionIcon() {
    $('#map_accordion.panel-group').on('hidden.bs.collapse', this.toggleIcon);
    $('#map_accordion.panel-group').on('shown.bs.collapse', this.toggleIcon);
  }

  toggleIcon(e) {
    const ele = $(e.target).prev('.panel-heading').find('.more-less');

    if (ele.text() === 'keyboard_arrow_down') {
      ele.text('keyboard_arrow_right');
    } else {
      ele.text('keyboard_arrow_down');
    }
  }
}

export default MapEvents;
