import BasinFilter from './BasinFilter'
import HydrocarbonTypeFilter from './HydrocarbonTypeFilter'
import NpvFilter from './NpvFilter'
import ActiveProductionWellsFilter from './ActiveProductionWellsFilter'
import CapexFilter from './CapexFilter'
import FieldProdFilter from './FieldProdFilter'
import ShoreStatusFilter from './ShoreStatusFilter'
import CompanyFilter from './CompanyFilter'
import CompanyOperatorFilter from './CompanyOperatorFilter'
import ProductionWellsProcessor from './ProductionWellsProcessor'
import MidstreamPipelinesProcessor from './MidstreamPipelinesProcessor'
import UpstreamPipelinesProcessor from './UpstreamPipelinesProcessor'
import InfieldPipelinesProcessor from './InfieldPipelinesProcessor'

export default [
  BasinFilter,
  HydrocarbonTypeFilter,
  NpvFilter,
  ActiveProductionWellsFilter,
  CapexFilter,
  FieldProdFilter,
  ShoreStatusFilter,
  CompanyFilter,
  CompanyOperatorFilter,
]

export const processorConstructors = [
  ProductionWellsProcessor,
  MidstreamPipelinesProcessor,
  UpstreamPipelinesProcessor,
  InfieldPipelinesProcessor,
]
