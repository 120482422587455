import React, { Component } from 'react';
import GeoCat from './GeoCat';

let OPTION_NAME = [
  { value: 'Trap Type', label: 'Trap Type' },
  { value: 'Reservoir Drive Mechanism', label: 'Reservoir Drive Mechanism' },
  { value: 'Recovery Phase', label: 'Recovery Phase' },
  { value: 'Artificial Lift', label: 'Artificial Lift' },
  { value: 'Completion Type', label: 'Completion Type' },
  { value: 'Lithology', label: 'Lithology' },
  { value: 'IOR Technique', label: 'IOR Technique' },
  { value: 'HPHT', label: 'HPHT' },
];

class GeoCats extends Component {
  constructor(props) {
    super(props);

    let { geo_cats } = props;

    this.state = {
      geo_cats: geo_cats,
    };
  }

  onChangeName = (index) => (event) => {
    let { geo_cats } = this.state;

    let geo_cat = geo_cats[index];

    if (geo_cat.name === event.value) {
      return;
    }

    if (event.value === 'Recovery Phase') {
      geo_cats.splice(index + 1, 0, { name: 'IOR Technique' });
    }

    if (geo_cat.name === 'Recovery Phase') {
      let technique = geo_cats[index + 1];

      if (technique.name === 'IOR Technique') {
        geo_cats.splice(index + 1, 1);
      }
    }

    geo_cat.name = event.value;
    geo_cat.value = null;

    this.setState({ geo_cats: geo_cats });
  };

  onChangeValue = (index) => (event) => {
    let { geo_cats } = this.state;
    let geo_cat = geo_cats[index];

    if (event === null) {
      geo_cat.value = null;
    } else {
      if (geo_cat.value === event.value) {
        return;
      }

      geo_cat.value = event.value;
    }

    if (geo_cat.name === 'Recovery Phase') {
      geo_cats
        .filter((t) => t.name === 'IOR Technique')
        .forEach((t) => (t.value = null));
    }

    this.setState({ geo_cats: geo_cats });
  };

  addRow = (event) => {
    event.preventDefault();

    let { geo_cats } = this.state;

    geo_cats.push({});

    this.setState({ geo_cats: geo_cats });
  };

  removeRow = (index) => (event) => {
    event.preventDefault();

    let { geo_cats } = this.state;

    let geo_cat = geo_cats[index + 1];

    geo_cats.splice(index, 1);

    if (geo_cat && geo_cat.name === 'IOR Technique') {
      geo_cats.splice(index, 1);
    }

    this.setState({ geo_cats: geo_cats });
  };

  optionValue = (name) => {
    let options = [];
    switch (name) {
      case 'Lithology':
        options = ['Sandstone', 'Carbonate', 'Coal', 'Shale', 'Granite'];
        break;
      case 'Trap Type':
        options = ['Structural trap', 'Stratigraphic trap', 'Combination trap'];
        break;
      case 'Reservoir Drive Mechanism':
        options = [
          'Gas cap expansion',
          'Solution gas drive',
          'Water drive',
          'Gravity drainage',
          'Compaction drive',
          'Combination',
        ];
        break;
      case 'Artificial Lift':
        options = [
          'Sucker-rod pump',
          'ESP',
          'Gas lift',
          'PCP',
          'Hydraulic pump',
          'Plunger lift',
        ];
        break;
      case 'Completion Type':
        options = ['Open hole', 'Cased hole'];
        break;
      case 'Recovery Phase':
        options = ['Primary', 'Secondary', 'Tertiary'];
        break;
      case 'HPHT':
        options = ['Yes', 'No'];
        break;
      case 'IOR Technique':
        let { geo_cats } = this.state;

        let recovery_phase = geo_cats.find((t) => t.name === 'Recovery Phase');

        if (recovery_phase) {
          switch (recovery_phase.value) {
            case 'Primary':
              break;
            case 'Secondary':
              options = ['Waterflood', 'Gas injection'];
              break;
            case 'Tertiary':
              options = [
                'CO2 injection',
                'Nitrogen injection',
                'Polymer flood',
                'Surfactant injection',
                'In-situ combustion',
                'Steam flood',
                'Cyclic steam stimulation',
                'SAGD',
                'Water-Alternating-Gas',
              ];
              break;
            default:
              options = [
                'Waterflood',
                'Gas injection',
                'CO2 injection',
                'Nitrogen injection',
                'Polymer flood',
                'Surfactant injection',
                'In-situ combustion',
                'Steam flood',
                'Cyclic steam stimulation',
                'SAGD',
                'Water-Alternating-Gas',
              ];
              break;
          }
        } else {
          options = [
            'Waterflood',
            'Gas injection',
            'CO2 injection',
            'Nitrogen injection',
            'Polymer flood',
            'Surfactant injection',
            'In-situ combustion',
            'Steam flood',
            'Cyclic steam stimulation',
            'SAGD',
            'Water-Alternating-Gas',
          ];
        }
        break;
      default:
        break;
    }
    return options.map((t) => ({ value: t, label: t }));
  };

  render() {
    let { geo_cats } = this.state;

    return (
      <React.Fragment>
        <button className="save-button save button-geo" onClick={this.addRow}>
          {'Add Category'}
        </button>
        {geo_cats.map((geo_cat, index) => {
          let { value } = geo_cat;
          return (
            <GeoCat
              key={index}
              index={index}
              name={`geology[geology_categorical_properties_attributes][${index}]`}
              destroy={geo_cat.destroy}
              onChangeName={this.onChangeName}
              optionName={OPTION_NAME}
              valName={{ value: geo_cat.name, label: geo_cat.name }}
              onChangeValue={this.onChangeValue}
              optionValue={this.optionValue(geo_cat.name)}
              valValue={{ value: value, label: value }}
              addRow={this.addRow}
              removeRow={this.removeRow}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default GeoCats;
