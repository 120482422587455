import BaseWellInfoWindow from './BaseWellInfoWindow';

class BoliviaWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'basin',
      'total_depth',
      'measured_depth',
      'water_blade',
      'result',
      'operator',
      'line_break',
      'drilling_start',
      'drilling_end',
      'drill_days',
      'shore_status',
    ];
  }
}

export default BoliviaWellInfoWindow;
