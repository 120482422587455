const drawFieldorBlock = (
  coordinate,
  visible,
  element,
  fillColor,
  isHighlighted,
  key,
  map = null,
) => {
  let highlight
  let zIndex = 0
  if (
    GoogleMap.country_name !== 'Guyana' &&
    Array.from(JSCONSTANT.country_display_block_over_field).includes(GoogleMap.country_name)
  ) {
    switch (key) {
      case 'fields':
        zIndex = JSCONSTANT.zIndex.blocks
        break
      case 'blocks':
      case 'restricted_areas':
        zIndex = JSCONSTANT.zIndex.fields
        break
      case 'formation_shapefiles':
        zIndex = JSCONSTANT.zIndex.formation_shapefiles
        break
      case 'hydrocarbon_structures':
        zIndex = JSCONSTANT.zIndex.hydrocarbon_structures
        break
    }
  } else {
    zIndex = JSCONSTANT.zIndex[key]
  }
  if (key === 'blocks') {
    if (Array.from(JSCONSTANT.blockNamesHaveBottomLayer).includes(element.name)) {
      zIndex = 1
    }
  }
  if (isHighlighted) {
    zIndex = JSCONSTANT.zIndex.highlighted
  }

  let isDisplayFragment

  if (element) {
    let displayFragment = JSCONSTANT.mapOnlyDisplayFragments[element.name]
    let fragmentCoordinate = element.coordinates.filter((item) => item.value == coordinate)
    if (fragmentCoordinate.length != 0) {
      let fragmentName = fragmentCoordinate[0].name
      isDisplayFragment = fragmentName == displayFragment
    }
  }

  const polygon = new google.maps.Polygon({
    paths: coordinate,
    visible,
    customInfo: element,
    isFragment: isDisplayFragment ? true : false,
    strokeColor: isHighlighted
      ? JSCONSTANT.markerOptions.strokeColor.highlighted
      : JSCONSTANT.markerOptions.strokeColor[key],
    strokeOpacity: JSCONSTANT.markerOptions.strokeOpacity,
    strokeWeight: isHighlighted
      ? JSCONSTANT.markerOptions.strokeWeightHighlighted
      : JSCONSTANT.markerOptions.strokeWeight,
    fillColor,
    fillOpacity: JSCONSTANT.markerOptions.fillOpacity[key],
    map,
    type: key,
    zIndex,
  })

  if (isHighlighted) {
    highlight = new google.maps.Marker({
      position: polygon.getApproximateCenter(),
      map: GoogleMap.map,
      icon: {
        url: JSCONSTANT.pinIconPath,
        scaledSize: new google.maps.Size(40, 40),
      },
    })
  } else {
    highlight = new google.maps.Marker({
      position: null,
      map: null,
      icon: null,
    })
  }
  GoogleMap.markers.highlights.push(highlight)

  return polygon
}

export default drawFieldorBlock
