const buildFormationShapefileCheckbox = () => {
  if (JSCONSTANT.countryHasFormationShapefile.indexOf(GoogleMap.country_name) > -1) {
    if ($('#formation-shapefiles-layer-checkbox').hasClass('hidden')) {
      return $('#formation-shapefiles-layer-checkbox').removeClass('hidden')
    }
  } else {
    if (!$('#formation-shapefiles-layer-checkbox').hasClass('hidden')) {
      return $('#formation-shapefiles-layer-checkbox').addClass('hidden')
    }
  }
}

export default buildFormationShapefileCheckbox
