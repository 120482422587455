class ProductionWellsProcessor {
  constructor() {
    this.value = $('#show_only_active_production_well').is(':checked')
    this.isSelected = !!this.value
  }
  processData(filteredData, country) {
    if (this.isSelected && ['Brazil', 'Mexico'].includes(country)) {
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filter.bind(this))
    }
    return filteredData
  }
  filter(item) {
    return item.last_month_available_production_data
  }
}

export default ProductionWellsProcessor
