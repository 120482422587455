import MinMaxFilter from './MinMaxFilter'

class NpvFilter extends MinMaxFilter {
  constructor() {
    super()
    this.min = $('#search_npv_min').val()
    this.max = $('#search_npv_max').val()
    this.value = [this.min, this.max]
    this.isSelected = !!(this.min || this.max)
  }
  filter(item) {
    let npv = typeof item.npv == 'string' ? parseFloat(item.npv.replace(/,/g, '')) : item.npv

    return this.min <= npv && npv <= this.max
  }
}

export default NpvFilter
