const changeLayerText = (layer, records, options = {}) => {
  let newText
  let translatedText

  if (options.startWith) {
    translatedText = I18n.t(`js.customer.maps.index.${options.startWith}_${layer}`)
  } else {
    translatedText = I18n.t(`js.customer.maps.index.${layer}`)
  }

  const label = $(`label[for='${layer}']`)

  if (GoogleMap.country_name === 'GoM') {
    let gomLabel = label.attr('for')

    switch (gomLabel) {
      case 'fields':
        translatedText = I18n.t(`js.customer.maps.index.lease`)
        break;
      case 'blocks':
        translatedText = I18n.t(`js.customer.maps.index.blocks_assets`)
        break;
      default:
        translatedText = I18n.t(`js.customer.maps.index.${layer}`)
    }
  }

  if (options.notShow || !records) {
    newText = `${translatedText}`
  } else {
    newText = `${translatedText} (${records.toLocaleString()})`
  }

  return label.text(newText)
}

export default changeLayerText
