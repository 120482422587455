const defineGoogleMapMissingMethods = () => {
  google.maps.Polygon.prototype.getBoundingBox = google.maps.Polyline.prototype.getBoundingBox = function() {
    const bounds = new google.maps.LatLngBounds()
    this.getPath().forEach(function(element, index) {
      bounds.extend(element)
    })
    return bounds
  }

  return (google.maps.Polygon.prototype.getApproximateCenter = google.maps.Polyline.prototype.getApproximateCenter = function() {
    let boundsHeight = 0
    let boundsWidth = 0
    let centerPoint = undefined
    let heightIncr = 0
    let maxSearchLoops = undefined
    const maxSearchSteps = 10
    let n = 1
    let northWest = undefined
    const polygonBounds = this.getBoundingBox()
    let testPos = undefined
    let widthIncr = 0
    // Get polygon Centroid
    centerPoint = polygonBounds.getCenter()
    if (google.maps.geometry.poly.containsLocation(centerPoint, this)) {
      // Nothing to do Centroid is in polygon use it as is
      return centerPoint
    } else {
      maxSearchLoops = maxSearchSteps / 2
      // Calculate NorthWest point so we can work out height of polygon NW->SE
      northWest = new google.maps.LatLng(
        polygonBounds.getNorthEast().lat(),
        polygonBounds.getSouthWest().lng(),
      )
      // Work out how tall and wide the bounds are and what our search increment will be
      boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(
        northWest,
        polygonBounds.getSouthWest(),
      )
      heightIncr = boundsHeight / maxSearchSteps
      boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(
        northWest,
        polygonBounds.getNorthEast(),
      )
      widthIncr = boundsWidth / maxSearchSteps
      // Expand out from Centroid and find a point within polygon at 0, 90, 180, 270 degrees
      while (n <= maxSearchLoops) {
        // Test point North of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 0)
        if (google.maps.geometry.poly.containsLocation(testPos, this)) {
          break
        }
        // Test point East of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 90)
        if (google.maps.geometry.poly.containsLocation(testPos, this)) {
          break
        }
        // Test point South of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, heightIncr * n, 180)
        if (google.maps.geometry.poly.containsLocation(testPos, this)) {
          break
        }
        // Test point West of Centroid
        testPos = google.maps.geometry.spherical.computeOffset(centerPoint, widthIncr * n, 270)
        if (google.maps.geometry.poly.containsLocation(testPos, this)) {
          break
        }
        n++
      }
      return testPos
    }
  })
}

export default defineGoogleMapMissingMethods
