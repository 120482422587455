import linkSubcriberOverview from '../common'
import BaseBlockInfoWindow from './BaseBlockInfoWindow'

class GhanaBlockInfoWindow extends BaseBlockInfoWindow {
  static get props() {
    return [
      'name',
      'field',
      'basin',
      'area',
      'water_depth',
      'depth',
      'shore_status',
      'geology',
      'api',
      'line_break',
      'participation',
      'fiscal_regime',
      'round',
      'contract_signed',
      'expiration_date',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'recent_oil_prod',
      'recent_gas_prod',
      'active_wells',
      'total_wells_drilled',
      'line_break',
      'enhanced_access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { is_restricted_area }) {
    super(element, country_name, props, { is_restricted_area })

    this.element = element
    this.country_name = country_name
    this.is_restricted_area = is_restricted_area || false

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.removeDuplicate(this.lines).join('<br>')
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle

    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      case 'recent_gas_prod':
        let gasPeriod = this.element.most_recent_period
        let gasValue = this.element.recent_gas_prod

        if (gasPeriod && gasValue) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
              period: gasPeriod,
            })}:</strong> ${gasValue}`,
          )
        }
        break
      case 'recent_oil_prod':
        let oilPeriod = this.element.most_recent_period
        let oilValue = this.element.recent_oil_prod

        if (oilPeriod && oilValue) {
          return this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.well.most_recent_liquid_production_period',
              { period: oilPeriod },
            )}:</strong> ${oilValue}`,
          )
        }
        break
      case 'enhanced_access_coverage':
        if (this.element.enhanced_access_coverage) {
          this.lines.push(
            `<a href='${Routes.customer_download_path({
              country: this.country_name,
              asset_type: 'Field',
              asset_id: this.element.id,
              type: 'report',
              timestamp: Date.now(),
            })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
          )
          this.lines.push(
            `<a target='_blank' href='${Routes.customer_download_path({
              country: this.country_name,
              asset_type: 'Field',
              asset_id: this.element.id,
              type: 'enhanced_pdf_report',
              timestamp: Date.now(),
            })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
          )
          this.lines.push(linkSubcriberOverview(this.country_name))
        } else if (this.element.asset_model_id) {
          this.lines.push(
            `<a href='${Routes.customer_download_path({
              country: this.country_name,
              asset_id: this.element.asset_model_id,
              asset_type: 'Field',
              type: 'report',
            })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
          )
          this.lines.push(
            `<a target='_blank' href='${Routes.customer_download_path({
              country: this.country_name,
              asset_id: this.element.asset_model_id,
              asset_type: 'Field',
              type: 'enhanced_pdf_report',
            })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
          )
        }

        break
      case 'oil_reserves':
        propValue = this.element.oil_reserves
        if (propValue) {
          propTitle = I18n.t('js.customer.maps.content.block.' + propName)
          propTitle = I18n.t('js.customer.maps.content.block.liquids_reserves')

          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.block.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default GhanaBlockInfoWindow
