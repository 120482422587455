import convertStringToFloat from './convertStringToFloat'
import moment from 'moment'

const drawGasLineChart = (facility, { displayTitle = true } = {}) => {
  const { id, facility_production, gas_capacity } = facility

  const ctx = document.getElementById(`gas-line-chart-${id}`).getContext('2d')

  let gasProduction = []
  let gasCapacity = []

  let gas = facility_production.gas

  gas.forEach((t) => {
    gasProduction.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1], 0) })
    gasCapacity.push({ x: moment(t[0]), y: Utils.formatProductionValue(gas_capacity, 0) })
  })

  new Chart(ctx, {
    type: 'line',

    data: {
      datasets: [
        {
          label: 'Gas (MMcf/d)',
          fill: false,
          borderColor: '#FF3333',
          backgroundColor: '#FF3333',
          borderWidth: 1,
          data: gasProduction,
        },
        {
          label: 'Gas Capacity (MMcf/d)',
          fill: false,
          borderColor: '#D10808',
          backgroundColor: '#D10808',
          borderWidth: 1,
          data: gasCapacity,
        },
      ],
    },

    options: {
      animation: {
        duration: 0, // general animation time
      },
      hover: {
        animationDuration: 0, // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0, // animation duration after a resize
      title: {
        display: displayTitle,
        text: '           Gas Production',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 20,
          fontSize: 10,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'YYYY',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'MMcf/d',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  })
}

export default drawGasLineChart
