import React from 'react';
import SelectionInput from '../SelectionInput';
import { GeologyData } from '../util';

export const EraSelection = ({ onChange, state }) => {
  let { era } = state;

  const getOption = () => {
    return _.uniq(GeologyData.map((t) => t['Era'])).map((t) => {
      return { value: t, label: t, name: 'era' };
    });
  };

  return (
    <SelectionInput
      title={'Era'}
      name={'geology[era]'}
      onChange={onChange}
      options={getOption()}
      value={era}
      index={'era'}
      className={'col-sm-3 col-xs-12 label'}
    />
  );
};
