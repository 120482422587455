const buildShoreStatusSelect = () => {
  let shoreStatusOptions = [
    { name: 'Shallow-water', value: 'Shallow-water' },
    { name: 'Onshore', value: 'Onshore' },
    { name: 'Pre-salt', value: 'Pre-salt' },
    { name: 'Unconventional', value: 'Unconventional' },
    { name: 'Deepwater', value: 'Deepwater' },
  ]
  if (GoogleMap.country_name === 'Peru') {
    shoreStatusOptions = [
      { name: 'Shallow-water', value: 'Shallow-water' },
      { name: 'Onshore', value: 'Onshore' },
      { name: 'Deepwater', value: 'Deepwater' },
    ]
  }
  const shoreStatusSelectize = $('#search_shore_status').data('selectize')
  shoreStatusSelectize.clearOptions()
  shoreStatusSelectize.load(callback => callback(shoreStatusOptions))
}

export default buildShoreStatusSelect
