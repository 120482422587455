import React from 'react';
import SelectionInput from '../SelectionInput';
import { GeologyData, isPresent } from '../util';

export const AgeSelection = ({ onChange, state }) => {
  let { era, period, epoch, age } = state;

  const getOption = () => {
    let data = GeologyData;

    if (isPresent(epoch)) {
      data = data.filter((t) => t['Epoch'] === epoch.value);
    }

    if (isPresent(period)) {
      data = data.filter((t) => t['Period'] === period.value);
    }

    if (isPresent(era)) {
      data = data.filter((t) => t['Era'] === era.value);
    }

    let options = _.uniq(data.map((t) => t['Age']));

    return options.map((t) => {
      return { value: t, label: t, name: 'age' };
    });
  };

  return (
    <SelectionInput
      title={'Age'}
      name={'geology[age]'}
      onChange={onChange}
      options={getOption()}
      value={age}
      index={'age'}
      className={'col-sm-3 col-xs-12 label'}
    />
  );
};
