import drawGasLineChart from './drawChart/drawGasLineChart'
import drawLiquidLineChartForGom from './drawChart/drawLiquidLineChartForGom'

const drawChartForGom = (facility) => {
  return setTimeout(function () {
    drawLiquidLineChartForGom(facility)
    drawGasLineChart(facility)
  }, 100)
}

export default drawChartForGom
