export const GeologyData = [
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Holocene',
    Age: 'Meghalayan',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Holocene',
    Age: 'Northgrippian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Holocene',
    Age: 'Greenlandian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Pleistocene',
    Age: 'Upper Pleistocene',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Pleistocene',
    Age: 'Middle Pleistocene',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Pleistocene',
    Age: 'Calabrian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Quaternary',
    Epoch: 'Pleistocene',
    Age: 'Gelasian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Pliocene',
    Age: 'Piacenzian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Pliocene',
    Age: 'Zanclean',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Messinian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Tortonian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Serravallian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Langhian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Burdigalian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Neogene',
    Epoch: 'Miocene',
    Age: 'Aquitanian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Oligocene',
    Age: 'Chattian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Oligocene',
    Age: 'Rupelian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Eocene',
    Age: 'Priabonian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Eocene',
    Age: 'Bartonian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Eocene',
    Age: 'Lutetian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Eocene',
    Age: 'Ypresian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Paleocene',
    Age: 'Thanetian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Paleocene',
    Age: 'Selandian',
  },
  {
    Era: 'Cenozoic',
    Period: 'Paleogene',
    Epoch: 'Paleocene',
    Age: 'Danian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Senonian',
    Age: 'Maastrichtian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Senonian',
    Age: 'Campanian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Senonian',
    Age: 'Santonian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Senonian',
    Age: 'Coniacian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Maastrichtian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Campanian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Santonian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Coniacian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Turonian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Upper Cretaceous',
    Age: 'Cenomanian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Albian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Aptian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Barremian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Hauterivian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Valanginian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Cretaceous',
    Epoch: 'Lower Cretaceous',
    Age: 'Berriasian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Upper Jurassic',
    Age: 'Tithonian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Upper Jurassic',
    Age: 'Kimmeridgian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Upper Jurassic',
    Age: 'Oxfordian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Middle Jurassic',
    Age: 'Callovian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Middle Jurassic',
    Age: 'Bathonian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Middle Jurassic',
    Age: 'Bajocian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Middle Jurassic',
    Age: 'Aalenian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Lower Jurassic',
    Age: 'Toarcian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Lower Jurassic',
    Age: 'Pliensbachian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Lower Jurassic',
    Age: 'Sinemurian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Jurassic',
    Epoch: 'Lower Jurassic',
    Age: 'Hettangian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Upper Triassic',
    Age: 'Rhaetian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Upper Triassic',
    Age: 'Norian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Upper Triassic',
    Age: 'Carnian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Middle Triassic',
    Age: 'Ladinian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Middle Triassic',
    Age: 'Anisian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Lower Triassic',
    Age: 'Olenekian',
  },
  {
    Era: 'Mesozoic',
    Period: 'Triassic',
    Epoch: 'Lower Triassic',
    Age: 'Induan',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Lopingian',
    Age: 'Changhsingian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Lopingian',
    Age: 'Wuchiapingian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Guadalupian',
    Age: 'Capitanian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Guadalupian',
    Age: 'Wordian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Guadalupian',
    Age: 'Roadian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Cisuralian',
    Age: 'Kungurian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Cisuralian',
    Age: 'Artinskian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Cisuralian',
    Age: 'Sakmarian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Permian',
    Epoch: 'Cisuralian',
    Age: 'Asselian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Upper Pennsylvanian',
    Age: 'Gzhelian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Upper Pennsylvanian',
    Age: 'Kasimovian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Middle Pennsylvanian',
    Age: 'Moscovian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Lower Pennsylvanian',
    Age: 'Bashkirian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Upper Mississippian',
    Age: 'Serpukhovian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Middle Mississippian',
    Age: 'Visean',
  },
  {
    Era: 'Paleozoic',
    Period: 'Carboniferous',
    Epoch: 'Lower Mississippian',
    Age: 'Tournaisian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Upper Devonian',
    Age: 'Famennian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Upper Devonian',
    Age: 'Frasnian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Middle Devonian',
    Age: 'Givetian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Middle Devonian',
    Age: 'Eifelian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Lower Devonian',
    Age: 'Emsian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Lower Devonian',
    Age: 'Pragian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Devonian',
    Epoch: 'Lower Devonian',
    Age: 'Lochkovian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Pridoli',
    Age: '',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Ludlow',
    Age: 'Ludfordian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Ludlow',
    Age: 'Gorstian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Wenlock',
    Age: 'Homerian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Wenlock',
    Age: 'Sheinwoodian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Llandovery',
    Age: 'Telychian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Llandovery',
    Age: 'Aeronian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Silurian',
    Epoch: 'Llandovery',
    Age: 'Rhuddanian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Upper Ordovician',
    Age: 'Hirnantian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Upper Ordovician',
    Age: 'Katian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Upper Ordovician',
    Age: 'Sandbian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Middle Ordovician',
    Age: 'Darriwilian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Middle Ordovician',
    Age: 'Dapingian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Lower Ordovician',
    Age: 'Floian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Ordovician',
    Epoch: 'Lower Ordovician',
    Age: 'Tremadocian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Furongian',
    Age: 'Stage 10',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Furongian',
    Age: 'Jiangshanian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Furongian',
    Age: 'Paibian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Miaolingian',
    Age: 'Guzhangian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Miaolingian',
    Age: 'Drumian',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Miaolingian',
    Age: 'Wuliuan',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Series 2',
    Age: 'Stage 4',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Series 2',
    Age: 'Stage 3',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Terreneuvian',
    Age: 'Stage 2',
  },
  {
    Era: 'Paleozoic',
    Period: 'Cambrian',
    Epoch: 'Terreneuvian',
    Age: 'Fortunian',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Ediacaran',
    Epoch: 'Upper Ediacaran',
    Age: '',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Ediacaran',
    Epoch: 'Lower Ediacaran',
    Age: '',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Cyrogenian',
    Epoch: 'Marinoan',
    Age: '',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Cyrogenian',
    Epoch: 'Sturtian',
    Age: '',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Tonian',
    Epoch: 'Upper Tonian',
    Age: '',
  },
  {
    Era: 'Neoproterozoic',
    Period: 'Tonian',
    Epoch: 'Lower Tonian',
    Age: '',
  },
]

export const isPresent = (object) => {
  return !_.isEmpty(object)
}
