const getInvisibleLayers = () => {
  const invisibleLayers = []
  $(".maps-filter-control input[type='checkbox']").each(function() {
    const _this = $(this)
    if (!_this.prop('checked')) {
      return invisibleLayers.push(_this.attr('name'))
    }
  })
  return invisibleLayers
}

export default getInvisibleLayers
