class GomFieldInfoWindow {
  static get props() {
    return [
      'name',
      'water_depth',
      'line_break',
      'participation',
      'abandonment_liability',
      'bid_amount',
      'lease_effective_date',
      'primary_term',
      'lease_expiration_date',
      'lease_status_code',
      'line_break',
      'recent_oil',
      'recent_gas',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { block_name }) {
    this.element = element
    this.country_name = country_name
    this.block_name = block_name || false

    let relatedFields =
      MAP_DATA.related_assets &&
      MAP_DATA.related_assets.find((rfs) => rfs.some((rf) => rf.name === element.name))

    if (relatedFields) {
      this.html = `
        <div class="tabs-wrapper">
          <div class="tabs-head">
            ${this.getRelatedFieldsTabsHeader(relatedFields, props)}
          </div>
          <div class="tabs-body">
            ${this.getRelatedFieldsHtml(relatedFields, props)}
          </div>
        </div>
      `
    } else {
      this.html = this.getElementHtml(element, props)
    }
  }

  getRelatedFieldsHtml = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<div id="${f.name.replace(/\s/g, '-')}" class="tab${i === 0 ? ' active' : ''}">
          ${this.getElementHtml(f, props)}
        </div>`
      })
      .join('')
  }

  getRelatedFieldsTabsHeader = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<a href="#${f.name.replace(/\s/g, '-')}" class="tab-head-item${
          i === 0 ? ' active' : ''
        }">
          ${f.name}
        </a>`
      })
      .join('')
  }

  getElementHtml = (el, props) => {
    let lines = []
    let propValue, propTitle
    props.forEach((propName) => {
      switch (propName) {
        case 'line_break':
          lines.push('')
          break
        case 'recent_gas':
          let gasPeriod = el.current_production_date
          let gasValue = el.current_prod_gas || 0
          if (gasValue > 0 && gasValue < 0.1) {
            gasValue = 0.1
          }
          if (gasPeriod) {
            lines.push(
              `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
                period: gasPeriod,
              })}:</strong> ${Utils.formatProductionValue(gasValue, 1)}`,
            )
          }
          break

        case 'recent_oil':
          let oilPeriod = el.current_production_date
          let oilValue = el.current_prod_liquid || 0
          if (oilValue > 0 && oilValue < 0.1) {
            oilValue = 0.1
          }
          if (oilPeriod) {
            lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_liquid_production_period',
                { period: oilPeriod },
              )}:</strong> ${Utils.formatProductionValue(oilValue, 1)}`,
            )
          }
          break
        case 'name':
          propTitle = I18n.t('js.customer.maps.content.field.lease')
          lines.push(`<strong>${propTitle}:</strong> ${el.name}`)
          break
        case 'participation':
          propTitle = I18n.t('js.customer.maps.content.field.' + propName)
          let participations = el[propName]
          lines.push(`<strong>${propTitle}:</strong>`)
          participations.forEach((value) => {
            lines.push(value)
          })
          break
        default:
          propValue = el[propName]
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            propTitle = I18n.t('js.customer.maps.content.field.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
      }
    })
    return lines.join('<br>')
  }
}
export default GomFieldInfoWindow
