const updateMarkersOptions = options => {
  const markersList = Object.keys(GoogleMap.markers)

  if (!GoogleMap.markers) {
    return
  }
  return markersList.forEach(function(marker) {
    if (Array.isArray(GoogleMap.markers[marker])) {
      if (GoogleMap.markers[marker]) {
        return GoogleMap.markers[marker].forEach(m => m.setOptions(options))
      }
    } else {
      return (() => {
        const result = []
        for (let key in GoogleMap.markers[marker]) {
          result.push(GoogleMap.markers[marker][key].forEach(m => m.setOptions(options)))
        }
        return result
      })()
    }
  })
}

export default updateMarkersOptions
