import React from 'react'
import Select from 'react-select'

const SelectionInput = ({ title, name, value, onChange, index, options, className }) => {
  let classNameSet = className || 'col-sm-4 col-xs-12 label'
  return (
    <label className={classNameSet}>
      <span className="item-text">{title}</span>
      <Select
        name={name}
        value={value}
        onChange={onChange(index)}
        isClearable={true}
        isSearchable={true}
        options={options}
      />
    </label>
  )
}

export default SelectionInput
