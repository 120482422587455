import BaseWellInfoWindow from './BaseWellInfoWindow'

class KenyaWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'well_type',
      'measured_depth',
      'vertical_depth',
      'water_blade',
      'result',
      'operator',
      'rig_name',
      'well_status',
      'line_break',
      'drilling_start',
      'drilling_end',
      'completion',
      'drill_days',
    ]
  }
}

export default KenyaWellInfoWindow
