import BaseBlockInfoWindow from './BaseBlockInfoWindow'

class GuyanaBlockInfoWindow extends BaseBlockInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'area',
      'water_depth',
      'shore_status',
      'geology',
      'api',
      'line_break',
      'participation',
      'fiscal_regime',
      'contract_signed',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'access_coverage',
      'enhanced_access_coverage',
    ]
  }
}

export default GuyanaBlockInfoWindow
