import buildSubCheckboxes from './buildSubCheckboxes'

const buildFacilitySubCheckboxes = (subheaders, object) => {
  return buildSubCheckboxes(
    subheaders,
    object,
    ['Mexico', 'Colombia', 'GoM'].includes(GoogleMap.country_name),
  )
}

export default buildFacilitySubCheckboxes
