import blockGroupInfoWindows from './blockGroupInfoWindows'
import blockInfoWindows from './blockInfoWindows'
import fieldInfoWindows from './fieldInfoWindows'
import basinInfoWindows from './basinInfoWindows'
import wellInfoWindows from './wellInfoWindows'
import facilityInfoWindows from './facilityInfoWindows'
import pipelineInfoWindows from './pipelineInfoWindows'
import presaltInfoWindows from './presaltInfoWindows'
import hydrocarbonStructureInfoWindows from './hydrocarbonStructureInfoWindows'
import refineryInfoWindows from './refineryInfoWindows'
import pipelineThroughputInfoWindows from './pipelineThroughputInfoWindows'

const infoWindows = {
  blockGroupInfoWindows,
  blockInfoWindows,
  fieldInfoWindows,
  basinInfoWindows,
  wellInfoWindows,
  facilityInfoWindows,
  pipelineInfoWindows,
  presaltInfoWindows,
  hydrocarbonStructureInfoWindows,
  refineryInfoWindows,
  pipelineThroughputInfoWindows,
}

export const getInfoWindowHtml = (type, element, country_name, params) => {
  const typeInfoWindows = infoWindows[type + 'InfoWindows']

  if (!typeInfoWindows) {
    console.warn(`Info Windows for ${type} are not defined!`)
    return ''
  }
  const infoWindowConstructor = typeInfoWindows[country_name] || typeInfoWindows.default
  const infoWindow = new infoWindowConstructor(
    element,
    country_name,
    infoWindowConstructor.props,
    params,
  )
  return infoWindow.html
}

export default infoWindows
