const setCheckboxesForEcu = () => {
  const hiddenEcuControls = [$('#basins').parents('li'), $('#search_basin').parents('.filter')]
  if (GoogleMap.country_name === 'Ecuador') {
    return hiddenEcuControls.forEach(el => el.addClass('hidden'))
  } else {
    return hiddenEcuControls.forEach(el => el.removeClass('hidden'))
  }
}

export default setCheckboxesForEcu
