import buildSubCheckboxes from './buildSubCheckboxes'

const buildBlockSubCheckboxes = (subheaders, object) => {
  return buildSubCheckboxes(
    subheaders,
    object,
    Array.from(JSCONSTANT.country_display_block_over_field).includes(GoogleMap.country_name) || GoogleMap.country_name === 'Mexico',
  )
}

export default buildBlockSubCheckboxes
