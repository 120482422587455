import linkSubcriberOverview from '../common'

class BoliviaFieldInfoWindow {
  static get props() {
    return [
      'name',
      'block_name',
      'basin',
      'area',
      'water_depth',
      'average_depth',
      'shore_status',
      'period',
      'geology',
      'formation',
      'api',
      'line_break',
      'participation',
      'fiscal_regime',
      'round',
      'royalty_rate',
      'first_prod',
      'contract_signed',
      'license_expiration',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'most_recent_liquids',
      'most_recent_gas',
      'active_wells_per_field',
      'total_wells_drilled',
      'access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { block_name }) {
    this.element = element
    this.country_name = country_name
    this.block_name = block_name || false

    this.html = this.getElementHtml(element, props)
  }

  getElementHtml = (el, props) => {
    let lines = []
    let propValue, propTitle
    props.forEach((propName) => {
      switch (propName) {
        case 'line_break':
          lines.push('')
          break
        case 'block_name':
          this.block_name && lines.push(`<strong>Block:</strong> ${this.block_name}`)
          break
        case 'name':
          propTitle = I18n.t('js.customer.maps.content.field.name')
          lines.push(`<strong>${propTitle}:</strong> ${el.name}`)
          break
        case 'participation':
          propTitle = I18n.t('js.customer.maps.content.field.' + propName)
          let participations = el[propName]

          if (participations) {
            lines.push(`<strong>${propTitle}:</strong> ${participations}`)
          }
          break
        case 'most_recent_liquids':
        case 'most_recent_gas':
          let production = el[propName]
          if (propValue && propValue !== 'null' && parseFloat(propValue) != 0) {
            let propTitle = I18n.t(`js.customer.maps.content.field.${propName}`, {
              period: el['prod_current_date'],
            })
            lines.push(`<strong>${propTitle}:</strong> ${production}`)
          }

          break
        case 'access_coverage':
          if (el.access_coverage) {
            lines.push(
              `<a href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Block',
                asset_id: el.block_id,
                type: 'report',
                timestamp: Date.now(),
              })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
            )

            let type = JSCONSTANT.countryHaveEnhancedReport.includes(this.country_name)
              ? 'enhanced_pdf_report'
              : 'pdf_report'

            lines.push(
              `<a target='_blank' href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Block',
                asset_id: el.block_id,
                type: type,
                timestamp: Date.now(),
              })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
            )
            lines.push(linkSubcriberOverview(this.country_name))
          }
          break
        case 'oil_reserves':
          let liquids_production = el[propName]

          if (propName && parseFloat(liquids_production) !== 0 && liquids_production !== 'N/A') {
            let propTitle = I18n.t(`js.customer.maps.content.field.liquids_reserves`, {
              period: el['prod_current_date'],
            })
            lines.push(`<strong>${propTitle}:</strong> ${liquids_production}`)
          }
          break
        case 'npv':
          let npv = el[propName]

          if (npv) {
            propTitle = I18n.t('js.customer.maps.content.field.block_npv', {
              block: el['block_name'],
            })
            lines.push(`<strong>${propTitle}:</strong> ${npv}`)
          }
          break
        default:
          propValue = el[propName]
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            propTitle = I18n.t('js.customer.maps.content.field.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
      }
    })
    return lines.join('<br>')
  }
}

export default BoliviaFieldInfoWindow
