const CANVAS_PIE_WIDTH = 190
const CANVAS_PIE_HEIGHT = 150
const CANVAS_LINE_WIDTH = 280
const CANVAS_LINE_HEIGHT = 300

// TODO: split country logic
class BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'contract_type',
      'field_name',
      'province',
      'municipality',
      'operator',
      'contract',
      'water_depth',
      'hydrocarbon_type',
      'capacity',
      'oil_capacity',
      'productions',
    ]
  }

  lines = []
  html = ''
  titlePieChartHTML = ''
  gasChartHTML = ''
  oilChartHTML = ''
  waterChartHTML = ''
  liquidLineChartHTML = ''
  gasLineChartHTML = ''
  chartHTML = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    if (
      this.gasChartHTML ||
      this.oilChartHTML ||
      this.waterChartHTML ||
      this.liquidLineChartHTML ||
      this.gasLineChartHTML
    ) {
      this.chartHTML = `\
        <div class='row'>
          ${this.titlePieChartHTML}
        </div>\
        <div class='row infowindow'>
          ${this.oilChartHTML}
          ${this.waterChartHTML}
          ${this.gasChartHTML}
        </div>\
        <div class='row infowindow'>
          ${this.liquidLineChartHTML}
          ${this.gasLineChartHTML}
        </div>\
      `
      this.html = `
        <div class="tabs-wrapper facility-tab">
          <div class="tabs-head">
            ${this.getTabsHeader()}
          </div>
          <div class="tabs-body">
            ${this.getTabsBody()}
          </div>
        </div>
      `
    }
  }

  getTabsHeader = () => {
    return `
      <a href="#information" class="tab-head-item active">
       Recent Production
      </a>
      <a href="#chart" class="tab-head-item">
        Historical Production
      </a>
    `
  }

  getTabsBody = () => {
    return `
      <div id="information" class="tab active">
        ${this.bodyInformation}

        <div class='row'>
          ${this.titlePieChartHTML}
        </div>\
        <div class='row infowindow'>
          ${this.oilChartHTML}
          ${this.waterChartHTML}
          ${this.gasChartHTML}
        </div>
      </div>
      <div id="chart" class="tab">
        <div class='row infowindow chart-row'>
          ${this.liquidLineChartHTML}
          ${this.gasLineChartHTML}
        </div>\
      </div>
    `
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'capacity':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            let fractionDigits = JSCONSTANT.CountryHasRoundedOilCapacity.includes(this.country_name)
              ? 0
              : 1
            propValue = Utils.formatProductionValue(propValue, fractionDigits)
          }
          propTitle = `Processing Capacity ${this.element.unity}`
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'field_name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
      case 'oil_capacity':
        this.processOilCapacity()
        break
      case 'productions':
        if (
          (this.country_name === 'Peru' || this.country_name === 'Bolivia') &&
          parseFloat(this.element.gas_capacity) !== 0
        ) {
          this.lines.push(
            `<strong>Processing Capacity ${this.element.unity}:</strong> ${this.element.gas_capacity}`,
          )
        }

        if (JSCONSTANT.countryHasFacilityProduction.indexOf(this.country_name) < 0) {
          let production
          if ((production = this.element.production)) {
            const { period } = production

            if (this.isPresent(this.element.oil_capacity) || this.isPresent(production.oil)) {
              this.lines.push(' ')
              if (this.isPresent(this.element.oil_capacity)) {
                this.lines.push(
                  `<strong>${I18n.t(
                    'js.customer.maps.content.facility.oil_processing_capacity',
                  )}</strong> ${this.element.oil_capacity || '0.00'}`,
                )
              }
              if (this.isPresent(production.oil)) {
                this.lines.push(
                  `<strong>${I18n.t('js.customer.maps.content.facility.current_oil_throghput', {
                    period,
                  })}</strong> ${production.oil || '0.00'}`,
                )
              }
            }

            if (
              this.isPresent(this.element.water_injection_capacity) ||
              this.isPresent(this.element.water_treating_processing_capacity) ||
              this.isPresent(production.water)
            ) {
              this.lines.push(' ')
              this.lines.push(
                `<strong>${I18n.t(
                  'js.customer.maps.content.facility.water_injection_capacity',
                )}</strong> ${this.element.water_injection_capacity || '0.00'}`,
              )
              this.lines.push(
                `<strong>${I18n.t(
                  'js.customer.maps.content.facility.water_treating_processing_capacity',
                )}</strong> ${this.element.water_treating_processing_capacity || '0.00'}`,
              )
              this.lines.push(
                `<strong>${I18n.t('js.customer.maps.content.facility.current_water_throghput', {
                  period,
                })}</strong> ${production.water || '0.00'}`,
              )
            }

            if (this.isPresent(this.element.gas_capacity) || this.isPresent(production.gas)) {
              this.lines.push(' ')
              if (this.isPresent(this.element.gas_capacity)) {
                this.lines.push(
                  `<strong>${I18n.t(
                    'js.customer.maps.content.facility.gas_processing_capacity',
                  )}</strong> ${this.element.gas_capacity || '0.00'}`,
                )
              }
              if (this.isPresent(production.gas)) {
                this.lines.push(
                  `<strong>${I18n.t('js.customer.maps.content.facility.current_gas_throghput', {
                    period,
                  })}</strong> ${production.gas || '0.00'}`,
                )
              }
            }

            this.titlePieChartHTML = `\
              <div class='col-md-12 text-center'>
                <h4 id='title-pie-chart-${this.element.id}'>Most Recent Production</h4>
              </div>\
            `

            this.gasChartHTML = `\
              <div class='col-md-4'>
                <canvas id='gas-pie-chart-${this.element.id}' width='${CANVAS_PIE_WIDTH}' height='${CANVAS_PIE_HEIGHT}'>
              </div>\
            `

            this.oilChartHTML = `\
              <div class='col-md-4'>
                <canvas id='oil-pie-chart-${this.element.id}' width='${CANVAS_PIE_WIDTH}' height='${CANVAS_PIE_HEIGHT}'>
              </div>\
            `

            this.waterChartHTML = `\
              <div class='col-md-4'>
                <canvas id='water-pie-chart-${this.element.id}' width='${CANVAS_PIE_WIDTH}' height='${CANVAS_PIE_HEIGHT}'>
              </div>\
            `

            this.liquidLineChartHTML = `\
              <div class='col-md-6'>
                <canvas id='liquid-line-chart-${
                  this.element.id
                }' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT + 30}'>
              </div>\
            `

            this.gasLineChartHTML = `\
              <div class='col-md-6'>
                <canvas id='gas-line-chart-${this.element.id}' width='${CANVAS_LINE_WIDTH}' height='${CANVAS_LINE_HEIGHT}'>
              </div>\
            `
          } else if (this.country_name !== 'Colombia' && this.country_name !== 'Argentina') {
            this.lines.push(' ')
            this.lines.push(
              `<strong>Oil Processing Capacity (bbl/d):</strong> ${
                this.element.oil_capacity || '0.00'
              }`,
            )
            this.lines.push('<strong>Most Recent Oil Throughput (bbl/d):</strong> 0.00')
            this.lines.push(' ')
            this.lines.push(
              `<strong>Gas Processing Capacity (MMcfd):</strong> ${
                this.element.gas_capacity || '0.00'
              }`,
            )
            this.lines.push('<strong>Most Recent Gas Throughput (MMcfd):</strong> 0.00')
            this.lines.push(' ')
            this.lines.push(
              `<strong>Water Processing Capacity (MMcfd):</strong> ${
                this.element.water_capacity || this.element.water_injection_capacity || '0.00'
              }`,
            )
            this.lines.push('<strong>Most Recent Water Throughput (MMcfd):</strong> 0.00')
          }
        } else if (this.country_name === 'Mexico') {
          parseFloat(this.element.oil_capacity) &&
            this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.facility.oil_processing_capacity',
              )}</strong> ${this.element.oil_capacity}`,
            )
          parseFloat(this.element.gas_capacity) &&
            this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.facility.gas_processing_capacity',
              )}</strong> ${this.element.gas_capacity}`,
            )
        }
        break
      case 'name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }

  isPresent = (value) => {
    return value && value != 'NA'
  }

  processOilCapacity = () => {
    let propValue = this.element.oil_capacity
    let propTitle

    if (
      ['Angola', 'Peru', 'Kurdistan'].includes(this.country_name) &&
      propValue &&
      parseFloat(propValue) !== 0 &&
      propValue !== 'N/A'
    ) {
      let fractionDigits = JSCONSTANT.CountryHasRoundedOilCapacity.includes(this.country_name)
        ? 0
        : 1
      propValue = Utils.formatProductionValue(propValue, fractionDigits)
      propTitle = I18n.t('js.customer.maps.content.facility.oil_processing_capacity')
      return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
    }
  }
}

export default BaseFacilityInfoWindow
