class BoliviaFieldInfoWindow {
  static get props() {
    return [
      'name',
      'hydrocarbon_type',
      'status',
      'water_depth',
      'discovery_date',
      'production_start_date',
      'line_break',
      'access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { block_name }) {
    this.element = element
    this.country_name = country_name
    this.block_name = block_name || false

    this.html = this.getElementHtml(element, props)
  }

  getElementHtml = (el, props) => {
    let lines = []
    let propValue, propTitle
    props.forEach((propName) => {
      switch (propName) {
        case 'line_break':
          lines.push('')
          break
        case 'name':
          propTitle = I18n.t('js.customer.maps.content.field.name')
          lines.push(`<strong>${propTitle}:</strong> ${el.name}`)
          break
        case 'access_coverage':
          if (el.access_coverage) {
            lines.push(
              `<a href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Block',
                asset_id: el.block_id,
                type: 'report',
                timestamp: Date.now(),
              })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
            )

            let type = JSCONSTANT.countryHaveEnhancedReport.includes(this.country_name)
              ? 'enhanced_pdf_report'
              : 'pdf_report'

            lines.push(
              `<a target='_blank' href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Block',
                asset_id: el.block_id,
                type: type,
                timestamp: Date.now(),
              })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
            )
          }
          break
        default:
          propValue = el[propName]
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            propTitle = I18n.t('js.customer.maps.content.field.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
      }
    })
    return lines.join('<br>')
  }
}

export default BoliviaFieldInfoWindow
