import BaseWellInfoWindow from './BaseWellInfoWindow'

class BrazilWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'result',
      'facility_name',
      'well_design',
      'measured_depth',
      'vertical_depth',
      'water_blade',
      'line_break',
      'ip_petroleum_bbld',
      'ip_gas_mmcfd',
      'eur_liquids_mmbbl',
      'eur_gas_bcf',
      'water_cut',
      'gor',
      'recent_liquids',
      'recent_gas',
      'line_break',
      'drilling_start',
      'drilling_end',
      'drill_days',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.removeDuplicate(this.lines).join('<br>')
  }

  pushContent = (propName) => {
    let propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'recent_liquids':
        if (this.element.recent_liquids != undefined && this.element.recent_liquids !== 0.0) {
          return this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.well.most_recent_liquid_production_period',
              {
                period: this.element.period,
              },
            )}:</strong> ${Utils.formatProductionValue(this.element.recent_liquids, 1)}`,
          )
        }
        break
      case 'recent_gas':
        if (this.element.recent_gas != undefined && this.element.recent_gas !== 0.0) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
              period: this.element.period,
            })}:</strong> ${Utils.formatProductionValue(this.element.recent_gas, 1)}`,
          )
        }
        break
      case 'drill_days':
        var propValue = this.element['drill_days']
        if (propValue && parseInt(propValue) > 0) {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'total_depth':
        propValue = this.element[propName] || this.element['depth_vertical']
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (Array.from(JSCONSTANT.countryHasTotalVerticalDepth).includes(this.country_name)) {
            propName = 'total_vertical_depth'
          }
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'measured_depth':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'water_blade':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'vertical_depth':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'depth_vertical':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'ip_petroleum_bbld':
      case 'ip_gas_mmcfd':
      case 'eur_liquids_mmbbl':
      case 'eur_gas_bcf':
      case 'gor':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(
            `<strong>${propTitle}:</strong> ${Utils.formatProductionValue(propValue, 1)}`,
          )
        }
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BrazilWellInfoWindow
