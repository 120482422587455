import drawLiquidPipelineThroughputLineChart from './drawChart/drawLiquidPipelineThroughputLineChart'
import drawGasPipelineThroughputLineChart from './drawChart/drawGasPipelineThroughputLineChart'

const drawChartPipelineThroughput = (pipeline_throughput) => {
  return setTimeout(function () {
    if (pipeline_throughput.pipeline_type == 'Oil') {
      drawLiquidPipelineThroughputLineChart(pipeline_throughput)
    } else {
      drawGasPipelineThroughputLineChart(pipeline_throughput)
    }
  }, 100)
}

export default drawChartPipelineThroughput
