const buildMarkerClusterer = invisibleLayers => {
  return (() => {
    const result = []
    for (var layer of Array.from(JSCONSTANT.markerClusterer)) {
      var markerClusterer, markersWillBePushed
      var markers = GoogleMap.markers[layer]
      if (Array.isArray(markers)) {
        markersWillBePushed = invisibleLayers.indexOf(layer) === -1
        markers = (markersWillBePushed && markers) || []
        markerClusterer = new MarkerClusterer(
          GoogleMap.map,
          markers,
          JSCONSTANT.markerClustererOptions[layer],
        )
        result.push(GoogleMap.clusterers[layer].push(markerClusterer))
      } else {
        result.push(
          (() => {
            const result1 = []
            for (let key in markers) {
              let subMarkers = markers[key]
              GoogleMap.clusterers[layer][key] = []
              markersWillBePushed = invisibleLayers.indexOf(key) === -1
              subMarkers = (markersWillBePushed && subMarkers) || []
              markerClusterer = new MarkerClusterer(
                GoogleMap.map,
                subMarkers,
                JSCONSTANT.markerClustererOptions[layer],
              )
              result1.push(GoogleMap.clusterers[layer][key].push(markerClusterer))
            }
            return result1
          })(),
        )
      }
    }
    return result
  })()
}

export default buildMarkerClusterer
