import linkSubcriberOverview from '../common'

class BaseFieldInfoWindow {
  static get props() {
    return [
      'name',
      'block_name',
      'basin',
      'area',
      'water_depth',
      'average_depth',
      'shore_status',
      'period',
      'geology',
      'era',
      'api',
      'line_break',
      'participation',
      'field_status',
      'fiscal_regime',
      'round',
      'goverment_profit_share',
      'royalty_rate',
      'first_prod',
      'contract_signed',
      'expiration_date',
      'license_expiration',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'recent_oil',
      'recent_gas',
      'active_wells_per_field',
      'total_wells_drilled',
      'full_valuation',
      'exec_summ',
      'access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { block_name }) {
    this.element = element
    this.country_name = country_name
    this.block_name = block_name || false

    let relatedFields =
      MAP_DATA.related_assets &&
      MAP_DATA.related_assets.find((rfs) => rfs.some((rf) => rf.name === element.name))

    if (relatedFields) {
      this.html = `
        <div class="tabs-wrapper">
          <div class="tabs-head">
            ${this.getRelatedFieldsTabsHeader(relatedFields, props)}
          </div>
          <div class="tabs-body">
            ${this.getRelatedFieldsHtml(relatedFields, props)}
          </div>
        </div>
      `
    } else {
      this.html = this.getElementHtml(element, props)
    }
  }

  removeDuplicate = (lines) => {
    let prevLine

    return lines.filter((line, index) => {
      let compare = true

      if (index !== 0) {
        compare = line !== prevLine
      }

      prevLine = line

      return compare
    })
  }

  getRelatedFieldsHtml = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<div id="${f.name.parameterize()}" class="tab${i === 0 ? ' active' : ''}">
          ${this.getElementHtml(f, props)}
        </div>`
      })
      .join('')
  }

  getRelatedFieldsTabsHeader = (rfs, props) => {
    return rfs
      .map((f, i) => {
        return `<a href="#${f.name.parameterize()}" class="tab-head-item${
          i === 0 ? ' active' : ''
        }">
          ${f.name}
        </a>`
      })
      .join('')
  }

  getElementHtml = (el, props) => {
    let lines = []
    let propValue, propTitle
    props.forEach((propName) => {
      switch (propName) {
        case 'line_break':
          lines.push('')
          break
        case 'recent_gas':
          let gasPeriod =
            el.current_production_date ||
            (el.well_productions && el.well_productions.gas && el.well_productions.gas.period)
          let gasValue =
            el.current_prod_gas ||
            (el.well_productions && el.well_productions.gas && el.well_productions.gas.value) ||
            0
          if (gasValue > 0 && gasValue < 0.1) {
            gasValue = 0.1
          }
          if (gasPeriod) {
            lines.push(
              `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
                period: gasPeriod,
              })}:</strong> ${Utils.formatProductionValue(gasValue, 1)}`,
            )
          }
          break

        case 'recent_oil':
          let oilPeriod =
            el.current_production_date ||
            (el.well_productions && el.well_productions.oil && el.well_productions.oil.period)
          let oilValue =
            el.current_prod_liquid ||
            (el.well_productions && el.well_productions.oil && el.well_productions.oil.value) ||
            0
          if (oilValue > 0 && oilValue < 0.1) {
            oilValue = 0.1
          }
          if (oilPeriod) {
            lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_liquid_production_period',
                { period: oilPeriod },
              )}:</strong> ${Utils.formatProductionValue(oilValue, 1)}`,
            )
          }
          break
        case 'access_coverage':
          if (el.access_coverage) {
            lines.push(
              `<a href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Field',
                asset_id: el.id,
                type: 'report',
                timestamp: Date.now(),
              })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
            )

            let type = JSCONSTANT.countryHaveEnhancedReport.includes(this.country_name)
              ? 'enhanced_pdf_report'
              : 'pdf_report'

            lines.push(
              `<a target='_blank' href='${Routes.customer_download_path({
                country: this.country_name,
                asset_type: 'Field',
                asset_id: el.id,
                type: type,
                timestamp: Date.now(),
              })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
            )
            lines.push(linkSubcriberOverview(this.country_name))
          }
          break
        case 'block_name':
          this.block_name && lines.push(`<strong>Block:</strong> ${this.block_name}`)
          break
        case 'name':
          propTitle = I18n.t('js.customer.maps.content.field.name')
          lines.push(`<strong>${propTitle}:</strong> ${el.name}`)
          break
        case 'participation':
          let participations = el[propName]

          if (participations) {
            propTitle = I18n.t('js.customer.maps.content.field.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${participations}`)
          }
          break
        default:
          propValue = el[propName]
          if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
            propTitle = I18n.t('js.customer.maps.content.field.' + propName)
            lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
          }
      }
    })

    return this.removeDuplicate(lines).join('<br>')
  }
}

export default BaseFieldInfoWindow
