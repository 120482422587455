import React from 'react';
import SelectionInput from '../SelectionInput';
import { GeologyData, isPresent } from '../util';

export const EpochSelection = ({ onChange, state }) => {
  let { era, period, epoch } = state;

  const getOption = () => {
    let data = GeologyData;

    if (isPresent(period)) {
      data = data.filter((t) => t['Period'] === period.value);
    }

    if (isPresent(era)) {
      data = data.filter((t) => t['Era'] === era.value);
    }

    let options = _.uniq(data.map((t) => t['Epoch']));

    return options.map((t) => {
      return {
        value: t,
        label: t,
        name: 'epoch',
      };
    });
  };

  return (
    <SelectionInput
      title={'Epoch'}
      name={'geology[epoch]'}
      onChange={onChange}
      options={getOption()}
      value={epoch}
      index={'epoch'}
      className={'col-sm-3 col-xs-12 label'}
    />
  );
};
