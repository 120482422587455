import BasePipelineInfoWindow from './BasePipelineInfoWindow'

class ChilePipelineInfoWindow extends BasePipelineInfoWindow {
  static get props() {
    return [
      'name',
      'pipeline_type',
      'operator',
      'participation',
      'contract',
      'pipeline_network',
      'pipeline_network_segment',
      'length',
      'diameter_mm',
      'construction',
      'origin',
      'destination',
      'status',
      'material_type',
      'project',
      'stream',
      'capacity',
      'cost',
      'developer',
      'sponsor',
      'section',
      'start_date',
    ]
  }
}

export default ChilePipelineInfoWindow
