import React from 'react'
import Select from 'react-select'

export const SimpleDropdown = ({ title, name, value, onChange, options }) => {
  return (
    <label className="col-sm-3 col-xs-12 label">
      <span className="item-text">{title}</span>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        isClearable={true}
        isSearchable={true}
        options={options}
      />
    </label>
  )
}
