import BaseFacilityInfoWindow from './BaseFacilityInfoWindow'

class GuyanaFacilityInfoWindow extends BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'field_name',
      'operator',
      'water_depth',
      'line_break',
      'oil_capacity',
      'gas_capacity',
      'water_injection_capacity',
      'line_break',
      'storage',
      'start_up',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'field_name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)

      case 'oil_capacity':
        return this.lines.push(
          `<strong>${I18n.t(
            'js.customer.maps.content.facility.oil_processing_capacity',
          )}</strong> ${this.element.oil_capacity || '0.00'}`,
        )
        break

      case 'gas_capacity':
        return this.lines.push(
          `<strong>${I18n.t(
            'js.customer.maps.content.facility.gas_processing_capacity',
          )}</strong> ${this.element.gas_capacity || '0.00'}`,
        )
        break

      case 'water_injection_capacity':
        return this.lines.push(
          `<strong>${I18n.t(
            'js.customer.maps.content.facility.water_injection_capacity',
          )}</strong> ${this.element.water_injection_capacity || '0.00'}`,
        )
        break

      case 'name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)

      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            propValue = Utils.formatProductionValue(propValue, 0)
          }
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default GuyanaFacilityInfoWindow
