import React, { Component } from 'react'
import setMap from './MapScripts'

class Maps extends Component {
  constructor(props) {
    super(props)
    setMap()
  }
  render(props) {
    return (
      <div className="map-container">
        <button className="menu-toggle">
          <i className="material-icons">close</i>
        </button>
        <div id="maps"></div>
      </div>
    )
  }
}

export default Maps
