import BaseWellInfoWindow from './BaseWellInfoWindow'

class EcuadorWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'result',
      'well_design',
      'measured_depth',
      'vertical_depth',
      'water_blade',
      'line_break',
      'drilling_start',
      'drilling_end',
      'drill_days',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.removeDuplicate(this.lines).join('<br>')
  }

  pushContent = (propName) => {
    let propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break

      default:
        let propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          let propTitle = I18n.t('js.customer.maps.content.well.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default EcuadorWellInfoWindow
