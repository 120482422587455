class InfieldPipelinesProcessor {
  constructor() {
    this.value = $('#infield').is(':checked')
    this.isSelected = !this.value
  }
  processData(filteredData, country) {
    if (this.isSelected && ['GoM'].includes(country)) {
      filteredData.pipelines = filterNestedCollections(
        filteredData.pipelines,
        this.filter.bind(this),
      )
    }
    return filteredData
  }
  filter(item) {
    return item.stream !== 'INFIELD'
  }
}

export default InfieldPipelinesProcessor
