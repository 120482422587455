import filterConstructors, { processorConstructors } from './filters'

const getFilteredMapData = function (mapData) {
  try {
    const filters = filterConstructors.concat(processorConstructors).map((F) => new F())
    let filteredData = {}
    var country = mapData.country
    var countryHasBlockOverWellBlock =
      JSCONSTANT.country_has_block_over_well_block.indexOf(GoogleMap.country_name) !== -1
    if (countryHasBlockOverWellBlock) {
      filteredData.mainAssets = mapData.blocks
      filteredData.secondaryAssets = mapData.fields
    } else {
      filteredData.mainAssets = mapData.fields
      filteredData.secondaryAssets = mapData.blocks
    }
    filteredData.block_groups = mapData.block_groups
    filteredData.wells = mapData.wells
    filteredData.basins = mapData.basins
    filteredData.pipelines = mapData.pipelines
    filteredData.facilities = mapData.facilities
    filteredData.pipeline_throughputs = mapData.pipeline_throughputs
    var hydrocarbon_structures = mapData.hydrocarbon_structures
    var formation_shapefiles = mapData.formation_shapefiles
    var useHighlights = $('#use_highlights').is(':checked')
    var filterSelected = filters.some((f) => f.isSelected)
    var filterBySelected = function (item) {
      item.selected = true
      return item
    }
    if (filterSelected) {
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, filterBySelected)
      filteredData.secondaryAssets = mapNestedCollections(
        filteredData.secondaryAssets,
        filterBySelected,
      )
    }
    var highlightItem = function (item) {
      item.highlighted = item.selected
      return item
    }
    var unhighlightItem = function (item) {
      item.highlighted = false
      return item
    }
    var removeItem = function (item) {
      return item.selected
    }

    filters.forEach((f) => {
      filteredData = f.processData(filteredData, country)
    })

    if (filterSelected) {
      if (useHighlights) {
        filteredData.secondaryAssets = mapNestedCollections(
          filteredData.secondaryAssets,
          highlightItem,
        )
        filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, highlightItem)
      } else {
        filteredData.secondaryAssets = filterNestedCollections(
          filteredData.secondaryAssets,
          removeItem,
        )
        filteredData.mainAssets = filterNestedCollections(filteredData.mainAssets, removeItem)
        filteredData.secondaryAssets = mapNestedCollections(
          filteredData.secondaryAssets,
          unhighlightItem,
        )
        filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, unhighlightItem)
      }

      if (!useHighlights) {
        var selectedAssetNames = _.map(
          filterNestedCollections(filteredData.mainAssets, removeItem),
          'name',
        )
        hydrocarbon_structures = hydrocarbon_structures.filter((item) =>
          selectedAssetNames.includes(item.name.toUpperCase()),
        )
      }
    } else {
      filteredData.secondaryAssets = mapNestedCollections(
        filteredData.secondaryAssets,
        unhighlightItem,
      )
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, unhighlightItem)
    }

    return {
      country: country,
      block_groups: filteredData.block_groups,
      blocks: countryHasBlockOverWellBlock ? filteredData.mainAssets : filteredData.secondaryAssets,
      fields: countryHasBlockOverWellBlock ? filteredData.secondaryAssets : filteredData.mainAssets,
      facilities: filteredData.facilities,
      basins: filteredData.basins,
      wells: filteredData.wells,
      pipelines: filteredData.pipelines,
      secondary_pipelines: mapData.secondary_pipelines,
      pipeline_throughputs: mapData.pipeline_throughputs,
      restricted_areas: mapData.restricted_areas || [],
      presalts: mapData.presalts,
      refineries: mapData.refineries,
      field_blocks: mapData.field_blocks,
      hydrocarbon_structures: hydrocarbon_structures,
      formation_shapefiles: formation_shapefiles,
    }
  } catch (e) {
    console.error(e)
  }
}

export default getFilteredMapData
