const configureDefaultHiddenLayers = () => {
  if (GoogleMap.country_name === 'GoM') {
    $('#wells')
      .parents('li')
      .find('input[type="checkbox"]')
      .each(function () {
        $(this).prop('checked', false)
      })
  }
}

export default configureDefaultHiddenLayers
