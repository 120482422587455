class BaseRefineryInfoWindow {
  static get props() {
    return ['name', 'state', 'year', 'capacity']
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach(prop => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  pushContent = propName => {
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            propValue = Utils.formatProductionValue(propValue, 1)
          }
          propTitle = I18n.t('js.customer.maps.content.refinery.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BaseRefineryInfoWindow
