class BasinFilter {
  constructor() {
    this.value = $('#search_basin').text()
    this.isSelected = !!this.value
  }
  processData(filteredData) {
    if (this.isSelected) {
      filteredData.basins = filteredData.basins.filter((basin) => basin.name === this.value)
      let basin_id = filteredData.basins[0].id
      filteredData.facilities = _.filter(filteredData.facilities, { basin_id: basin_id })
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filter.bind(this))
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, this.map.bind(this))
      filteredData.secondaryAssets = mapNestedCollections(
        filteredData.secondaryAssets,
        this.map.bind(this),
      )
    }
    return filteredData
  }
  filter(item) {
    return item.basin === this.value
  }
  map(item) {
    if (item.selected) {
      item.selected = this.filter(item)
    }
    return item
  }
}

export default BasinFilter
