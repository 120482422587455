import React from 'react';
import SelectionInput from './SelectionInput';
import classNames from 'classnames';

const GeoCat = (props) => {
  let rowClass = classNames({ row: true, hidden: props.destroy });

  return (
    <div className={rowClass}>
      <SelectionInput
        title={'geo_cat_name'}
        name={`${props.name}[name]`}
        onChange={props.onChangeName}
        options={props.optionName}
        value={props.valName}
        index={props.index}
      />
      <SelectionInput
        title={'geo_cat_value'}
        name={`${props.name}[value]`}
        onChange={props.onChangeValue}
        options={props.optionValue}
        value={props.valValue}
        index={props.index}
      />
      <button className="save-button save button-geo" onClick={props.addRow}>
        {'add_category'}
      </button>

      <button
        className="save-button save button-geo"
        onClick={props.removeRow(props.index)}
      >
        {'remove_category'}
      </button>
    </div>
  );
};

export default GeoCat;
