const setMapOnAll = (options) => {
  try {
    let marker, markers, newMarkers, popup
    if (options.parent) {
      markers = GoogleMap.markers[options.parent][options.checkbox]
    } else {
      markers = GoogleMap.markers[options.checkbox]
    }

    if (
      [
        'block_groups',
        'fields',
        'blocks',
        'hydrocarbon_structures',
        'restricted_areas',
        'formation_shapefiles',
      ].indexOf(options.checkbox) > -1
    ) {
      const popups = markers['Popup']
      if (popups) {
        for (popup of Array.from(popups)) {
          popup.anchor.style.visibility = options.visible ? 'visible' : 'hidden'
        }
      }
    }

    if (markers && Array.isArray(markers)) {
      if (options.hasChild || GoogleMap.markers[options.checkbox]) {
        for (marker of Array.from(markers)) {
          marker.setVisible(options.visible)
          if (marker.popup) {
            marker.popup.setVisible(options.visible)
          }
        }
        if (GoogleMap.clusterers[options.checkbox]) {
          if (options.visible) {
            GoogleMap.clusterers[options.checkbox][0].addMarkers(markers)
          } else {
            GoogleMap.clusterers[options.checkbox][0].clearMarkers()
          }
        }
      } else if (options.parent) {
        newMarkers = GoogleMap.markers[options.parent][options.checkbox]
        for (marker of Array.from(newMarkers)) {
          marker.setVisible(options.visible)
          if (marker.text) {
            marker.text.anchor.style.visibility = options.visible ? 'visible' : 'hidden'
          }
          if (marker.popup) {
            marker.popup.setVisible(options.visible)
          }
        }
        if (
          GoogleMap.clusterers[options.parent] &&
          GoogleMap.clusterers[options.parent][options.checkbox]
        ) {
          if (options.visible) {
            GoogleMap.clusterers[options.parent][options.checkbox][0].addMarkers(newMarkers)
          } else {
            GoogleMap.clusterers[options.parent][options.checkbox][0].clearMarkers()
          }
        }
      }
    } else {
      if (options.hasChild) {
        for (let key in markers) {
          const subMarkers = markers[key]
          for (marker of Array.from(subMarkers)) {
            marker.setVisible(options.visible)
            if (marker.text) {
              marker.text.anchor.style.visibility = options.visible ? 'visible' : 'hidden'
            }
            if (marker.popup) {
              marker.popup.setVisible(options.visible)
            }
          }

          if (GoogleMap.clusterers[options.checkbox]) {
            if (options.visible) {
              GoogleMap.clusterers[options.checkbox][key][0].addMarkers(subMarkers)
            } else {
              GoogleMap.clusterers[options.checkbox][key][0].clearMarkers()
            }
          }
        }
      } else if (options.parent) {
        newMarkers = GoogleMap.markers[options.parent][options.checkbox]
        for (marker of Array.from(newMarkers)) {
          marker.setVisible(options.visible)
          if (marker.text) {
            marker.text.anchor.style.visibility = options.visible ? 'visible' : 'hidden'
          }
          if (marker.popup) {
            marker.popup.setVisible(options.visible)
          }
        }
        if (
          GoogleMap.clusterers[options.parent] &&
          GoogleMap.clusterers[options.parent][options.checkbox]
        ) {
          if (options.visible) {
            GoogleMap.clusterers[options.parent][options.checkbox][0].addMarkers(newMarkers)
          } else {
            GoogleMap.clusterers[options.parent][options.checkbox][0].clearMarkers()
          }
        }
      }
    }
    return
  } catch (e) {
    return console.error(e)
  }
}
export default setMapOnAll
