class MinMaxFilter {
  constructor() {
    // Implement me
    this.min = undefined
    this.max = undefined
    this.value = [this.min, this.max]
    this.isSelected = !!(this.min || this.max)
  }
  processData(filteredData) {
    if (this.isSelected) {
      this.min = this.min ? parseInt(this.min.replace(/,/g, ''), 10) : 0
      this.max = this.max ? parseInt(this.max.replace(/,/g, ''), 10) : Number.POSITIVE_INFINITY
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filter.bind(this))
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, this.map.bind(this))
      filteredData.secondaryAssets = []
    }

    return filteredData
  }
  filter(item) {
    // implement me
  }
  map(item) {
    if (item.selected) {
      item.selected = this.filter(item)
    }
    return item
  }
}

export default MinMaxFilter
