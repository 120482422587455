import convertStringToFloat from './convertStringToFloat'

const drawLiquidPipelineThroughputLineChart = ({ id, pipeline_segment_throughput }) => {
  const ctx = document
    .getElementById(`liquid-pipeline-throughput-line-chart-${id}`)
    .getContext('2d')

  let oilThroughput = []
  let oil = pipeline_segment_throughput.oil

  oil.forEach((t) => {
    oilThroughput.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1] / 1000, 0) })
  })

  new Chart(ctx, {
    type: 'line',

    data: {
      datasets: [
        {
          label: 'Oil (bbl/d)',
          fill: false,
          borderColor: '#00B359',
          backgroundColor: '#00B359',
          borderWidth: 1,
          data: oilThroughput,
        },
      ],
    },

    options: {
      title: {
        display: false,
        text: '',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 20,
          fontSize: 10,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'YYYY',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Thousand bbl/d',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  })
}

export default drawLiquidPipelineThroughputLineChart
