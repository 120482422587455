const buildCheckbox = (object, type, checked) => {
  if (checked == null) {
    checked = true
  }
  return `<div class='checkbox checkbox-${type || 'primary'}'> \
  <input type='checkbox' name='${object}' id='${object}' value='true' ${
    checked != null ? checked : { "checked='checked'": '' }
  }> \
  <label class='layer-label' for='${object}'>${I18n.t(`js.customer.maps.index.${object}`)}</label> \
  </div>`
}

export default buildCheckbox
