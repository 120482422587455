import BaseFacilityInfoWindow from './BaseFacilityInfoWindow'

class TrinidadFacilityInfoWindow extends BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'field_name',
      'operator',
      'water_depth',
      'line_break',
      'oil_capacity',
      'gas_capacity',
      'line_break',
      'start_up',
    ]
  }
}

export default TrinidadFacilityInfoWindow
