import convertStringToFloat from './convertStringToFloat'

const drawLiquidLineChartForGom = ({ id, facility_production, oil_capacity }) => {
  const ctx = document.getElementById(`liquid-line-chart-${id}`).getContext('2d')

  let oilProduction = []
  let oilCapacity = []

  let oil = facility_production.oil

  oil.forEach((t) => {
    oilProduction.push({ x: moment(t[0]), y: Utils.formatProductionValue(t[1], 0) })
    oilCapacity.push({
      x: moment(t[0]),
      y: Utils.formatProductionValue(convertStringToFloat(oil_capacity) / 1000, 0),
    })
  })

  new Chart(ctx, {
    type: 'line',

    data: {
      datasets: [
        {
          label: 'Oil (bbl/d)',
          fill: false,
          borderColor: '#00B359',
          backgroundColor: '#00B359',
          borderWidth: 1,
          data: oilProduction,
        },
        {
          label: 'Oil Capacity (bbl/d)',
          fill: false,
          borderColor: '#82B200',
          backgroundColor: '#82B200',
          borderWidth: 1,
          data: oilCapacity,
        },
      ],
    },

    options: {
      title: {
        display: true,
        text: '           Liquids Production',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 20,
          fontSize: 10,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'YYYY',
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Thousand bbl/d',
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  })
}

export default drawLiquidLineChartForGom
