const toggleHydrocarbonLayerCheckbox = () => {
  return $('#hydrocarbon-structures-layer-checkbox').toggle(
    [
      'Angola',
      'Algeria',
      'Argentina',
      'Bolivia',
      'Brazil',
      'Cameroon',
      'Colombia',
      'Cyprus',
      'Israel',
      'Falkland Islands/Islas Malvinas',
      'French Guiana',
      'Ghana',
      'GoM',
      'Guyana',
      'Honduras',
      'Mexico',
      'Mozambique',
      'Nigeria',
      'Jordan',
      'Peru',
      'SouthAfrica',
      'Suriname',
      'Thailand',
      'Trinidad and Tobago',
      'Uganda',
      'Cuba',
      'Congo Brazzaville',
      'Democratic Republic of Congo',
      'Niger',
      'Kenya',
      'Congo Brazzaville',
      'Chad',
      'Kurdistan',
    ].includes(GoogleMap.country_name)
  );
};
export default toggleHydrocarbonLayerCheckbox;
