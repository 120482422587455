class BasePipelineInfoWindow {
  static get props() {
    return [
      'name',
      'pipeline_type',
      'operator',
      'participation',
      'contract',
      'pipeline_network',
      'pipeline_network_segment',
      'length',
      'diameter_inches',
      'construction',
      'origin',
      'destination',
      'status',
      'shore_status',
      'material_type',
      'project',
      'stream',
      'capacity',
      'cost',
      'developer',
      'sponsor',
      'section',
      'start_date',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')
        break
      case 'name':
      case 'pipeline_type':
        if (this.element[propName]) {
          propValue = this.element[propName]
          propTitle = I18n.t('js.customer.maps.content.pipeline.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'diameter_mm':
        return this.lines.push(
          `<strong>${I18n.t('js.customer.maps.content.pipeline.diameter_mm')}:</strong> ${
            this.element.diameter
          }`,
        )
        break
      case 'diameter_inches':
        if (this.element.diameter && parseFloat(this.element.diameter)) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.pipeline.diameter_inches')}:</strong> ${
              this.element.diameter
            }`,
          )
        }
        break
      case 'capacity':
        if (
          this.element.capacity &&
          this.element.unity &&
          parseFloat(this.element.capacity) !== 0
        ) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.pipeline.capacity')} (${
              this.element.unity
            }):</strong> ${this.element.capacity}`,
          )
        } else if (
          this.element.capacity &&
          (this.element.pipeline_type === 'Oil' ||
            this.element.pipeline_type === 'Refined Products') &&
          parseFloat(this.element.capacity) !== 0
        ) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.pipeline.capacity')} (kbd):</strong> ${
              this.element.capacity
            }`,
          )
        } else if (
          this.element.capacity &&
          this.element.pipeline_type === 'Gas' &&
          parseFloat(this.element.capacity) !== 0
        ) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.pipeline.capacity')} (MMcf/d):</strong> ${
              this.element.capacity
            }`,
          )
        }
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.pipeline.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BasePipelineInfoWindow
