class BaseBasinInfoWindow {
  html = ''

  constructor(element, country_name, props, is_restricted_area) {
    this.element = element
    this.country_name = country_name

    this.html = `<strong>Basin Name:</strong> ${element.name}<br>`
  }
}

export default BaseBasinInfoWindow
