import React from 'react'

export const SimpleInput = ({ title, name, value, onChange }) => {
  return (
    <label className="label col-sm-3 col-xs-12">
      <label className="item-text">{title}</label>
      <input type="text" value={value} name={`geology[${name}]`} onChange={onChange}></input>
    </label>
  )
}
