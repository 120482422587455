import buildCheckbox from './buildCheckbox'
import buildCheckboxChildForPipeline from './buildCheckboxChildForPipeline'

const buildCheckboxForArgentina = (subheaders, object) => {
  if (GoogleMap.country_name === 'Argentina') {
    if ($(`#${object}`).closest('li').length === 0) {
      $('.maps-filter-control > ul').append(
        `<li>${buildCheckbox('secondary_pipelines', 'warning')}`,
      )
      if (
        $(`#${object}`)
          .closest('li')
          .find('ul').length === 0
      ) {
        let checkboxs = subheaders.map(function(subheader) {
          let type = 'warning'
          if (subheader === 'secondary_gas') {
            type = 'danger'
          }
          if (subheader === 'secondary_oil') {
            type = 'success'
          }
          return buildCheckboxChildForPipeline(subheader, object, type)
        })

        checkboxs = checkboxs.join('')
        return $(`#${object}`)
          .closest('li')
          .append(`<ul>${checkboxs}</ul>`)
      }
    }
  } else {
    $(`#${object}`)
      .closest('li')
      .find('ul')
      .remove()
    return $(`#${object}`)
      .closest('li')
      .remove()
  }
}
export default buildCheckboxForArgentina
