class ShoreStatusFilter {
  constructor() {
    this.value = $('#search_shore_status')
      .text()
      .toLowerCase()
    this.isSelected = !!this.value
  }
  processData(filteredData) {
    if (this.isSelected) {
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filter.bind(this))
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, this.map.bind(this))
      filteredData.secondaryAssets = mapNestedCollections(
        filteredData.secondaryAssets,
        this.mapBlock.bind(this),
      )
    }

    return filteredData
  }
  filter(item) {
    return item.shore_status && item.shore_status.toLowerCase() === this.value
  }
  map(item) {
    if (item.selected) {
      item.selected = this.filter(item)
    }
    return item
  }
  filterBlock(block) {
    if (this.value === 'onshore' && block.shore_status === false) {
      return true
    }
    return block.shore_status && block.shore_status.toLowerCase() === this.value
  }
  mapBlock(item) {
    if (item.selected) {
      item.selected = this.filterBlock(item)
    }
    return item
  }
}

export default ShoreStatusFilter
