import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

// Instantsearch
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import {
  Highlight,
  Hits,
  InstantSearch,
  SearchBox,
  Stats,
} from 'react-instantsearch';

const isMac = navigator?.platform?.toLowerCase().includes('mac');

const { searchClient } = instantMeiliSearch(
  `${window.location.origin}/query`,
  undefined,
  {
    requestConfig: {
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
    },
  },
);

const Hit = ({ hit }) => {
  const textContent = (
    <Fragment>
      <strong className="mr-2">{hit.country_name}</strong>
      <span>{hit.asset_name}</span>
    </Fragment>
  );

  return (
    <Fragment key={hit.id}>
      <a
        href={`${window.location.origin}/admin/countries/${
          hit.country_id
        }/assets?${hit.asset_type.toLowerCase()}_id=${hit.asset_id}`}
      >
        {textContent}
      </a>
    </Fragment>
  );
};

const CommandMenu = () => {
  const [open, setOpen] = useState(false);

  const handleKeyDown = (e) => {
    if ((isMac ? e.metaKey : e.ctrlKey) && e.key === 'k') {
      e.preventDefault();
      e.stopPropagation();

      setOpen((currentValue) => {
        return !currentValue;
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger className="ml-3 search-assets save-button">
        SEARCH ASSETS
      </Dialog.Trigger>
      <div className="ml-3">
        {isMac ? (
          <p>
            Press <kbd>Cmd</kbd> + <kbd>K</kbd> search assets.
          </p>
        ) : (
          <p>
            Press <kbd>Ctrl</kbd> + <kbd>K</kbd> search assets.
          </p>
        )}
      </div>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Title>Command Menu</Dialog.Title>
        <Dialog.Content className="DialogContent">
          <div className="command-root">
            <div className="command-title">
              <h3>Search assets</h3>
              <Dialog.Close asChild>
                <button className="close-button" aria-label="Close">
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </div>
            <div className="command-body">
              <InstantSearch
                className="filters row"
                indexName="assets"
                searchClient={searchClient}
              >
                <SearchBox autoFocus />
                <div className="command-options">
                  <Hits hitComponent={Hit} />
                </div>
              </InstantSearch>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CommandMenu;
