import buildCheckboxChild from './buildCheckboxChild'

const buildSubCheckboxes = (subheaders, object, isNested) => {
  $(`#${object}`)
      .closest('li')
      .find('ul')
      .remove()

  if (isNested) {
    let checkboxes = subheaders.map(subheader => buildCheckboxChild(subheader, object))
    checkboxes = checkboxes.join('')

    $(`#${object}`)
      .closest('li')
      .append(`<ul>${checkboxes}</ul>`)
  }
}

export default buildSubCheckboxes
