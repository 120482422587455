class BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'design',
      'well_type',
      'total_depth',
      'water_blade',
      'operator',
      'shore_status',
      'line_break_for_producing',
      'ip',
      'eur',
      'gor',
      'recent_gas',
      'recent_oil',
      'line_break',
      'drilling_year',
      'drilling_start',
      'drilling_end',
      'drill_days',
      'line_break_for_cnh',
      'cnh',
      'formation',
    ];
  }

  lines = [];
  html = '';

  constructor(element, country_name, props) {
    this.element = element;
    this.country_name = country_name;

    props.forEach((prop) => {
      this.pushContent(prop);
    });

    this.html = this.lines.join('<br>');
  }

  removeDuplicate = (lines) => {
    let prevLine;

    return lines.filter((line, index) => {
      let compare = true;

      if (index !== 0) {
        compare = line !== prevLine;
      }

      prevLine = line;

      return compare;
    });
  };

  pushContent = (propName) => {
    let production, propTitle;
    switch (propName) {
      case 'line_break':
        return this.lines.push('');

      case 'line_break_for_producing':
        if ((production = this.element.most_recent_production)) {
          return this.lines.push('');
        }
        break;

      case 'line_break_for_cnh':
        if (this.element.cnh) {
          return this.lines.push('');
        }
        break;

      case 'recent_oil':
        if ((production = this.element.most_recent_production)) {
          if (isNaN(production.liquid)) {
            return this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_liquid_production',
              )}:</strong> ${production.liquid}`,
            );
          } else {
            if (production.liquid > 0 && production.liquid < 0.1) {
              production.liquid = 0.1;
            }
            return this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_liquid_production_period',
                { period: production.period },
              )}:</strong> ${Utils.formatProductionValue(production.liquid, 1)}`,
            );
          }
        }
        break;
      case 'recent_gas':
        if ((production = this.element.most_recent_production)) {
          if (isNaN(production.gas)) {
            return this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_gas_production',
              )}:</strong> ${production.gas}`,
            );
          } else {
            if (production.gas > 0 && production.gas < 0.1) {
              production.gas = 0.1;
            }
            return this.lines.push(
              `<strong>${I18n.t(
                'js.customer.maps.content.well.most_recent_gas_production_period',
                {
                  period: production.period,
                },
              )}:</strong> ${Utils.formatProductionValue(production.gas, 1)}`,
            );
          }
        }
        break;
      case 'drill_days':
        var propValue = this.element['drill_days'];
        if (propValue && parseInt(propValue) > 0) {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName);
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
        }
        break;
      case 'total_depth':
        propValue = this.element[propName] || this.element['depth_vertical'];
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (
            Array.from(JSCONSTANT.countryHasTotalVerticalDepth).includes(
              this.country_name,
            )
          ) {
            propName = 'total_vertical_depth';
          }
          propTitle = I18n.t('js.customer.maps.content.well.' + propName);
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
        }
        break;
      case 'depth_vertical':
        propValue = this.element[propName];
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName);
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
        }
        break;
      default:
        propValue = this.element[propName];
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.well.' + propName);

          propTitle = propName == 'shore_status' ? 'Shore Status' : propTitle;
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`);
        }
    }
  };
}

export default BaseWellInfoWindow;
