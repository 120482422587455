import React from 'react';
import SelectionInput from '../SelectionInput';
import { GeologyData, isPresent } from '../util';

export const PeriodSelection = ({ onChange, state }) => {
  let { era, period } = state;

  const getOption = () => {
    let data = GeologyData;

    if (isPresent(era)) {
      data = data.filter((t) => t['Era'] === era.value);
    }

    let options = _.uniq(data.map((t) => t['Period']));

    return options.map((t) => {
      return {
        value: t,
        label: t,
        name: 'period',
      };
    });
  };

  return (
    <SelectionInput
      title={'Geology'}
      name={'geology[period]'}
      onChange={onChange}
      options={getOption()}
      value={period}
      index={'period'}
      className={'col-sm-3 col-xs-12 label'}
    />
  );
};
