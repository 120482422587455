class CompanyFilter {
  constructor() {
    this.value = $("select[name='search[company][]'] option:selected")
      .map(function () {
        return this.innerText
      })
      .get()
    this.isSelected =
      !$('#search_is_company_operator').prop('checked') && this.value && this.value.length > 0
  }
  processData(filteredData, country) {
    if (this.isSelected) {
      if (country == 'Brazil') {
        filteredData.facilities = []
      } else if (country != 'Colombia') {
        filteredData.facilities = filterNestedCollections(
          filteredData.facilities,
          this.filterFacility.bind(this),
        )
      }
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filterWell.bind(this))
      filteredData.mainAssets = mapNestedCollections(filteredData.mainAssets, this.map.bind(this))
      filteredData.secondaryAssets = mapNestedCollections(
        filteredData.secondaryAssets,
        this.map.bind(this),
      )
    }
    return filteredData
  }
  filter(item) {
    let match
    let participation = Array.isArray(item.participation)
      ? item.participation.join(', ')
      : item.participation
    this.value.forEach((selectedCompany) => {
      if (
        participation && // operator present
        (participation.includes(selectedCompany + ' (Operator)') || // main operator
        participation.includes(selectedCompany + ' (100') || // main operator (mexico format)
        participation.includes(', ' + selectedCompany + ' (') ||
          participation.includes(selectedCompany + ' ('))
      ) {
        // secondary operator
        match = true
      }
    })
    return match
  }
  filterFacility(facility) {
    return this.value.indexOf(facility.operator) !== -1
  }
  filterWell(well) {
    let match
    this.value.forEach((selectedCompany) => {
      if (
        well.name_operator && // operator present
        (well.name_operator.indexOf(selectedCompany + ' (Operator)') !== -1 || // main operator
        well.name_operator.indexOf(selectedCompany + ' (100') !== -1 || // main operator (mexico format)
        well.name_operator.indexOf(', ' + selectedCompany + ' (') !== -1 ||
          well.name_operator.indexOf(selectedCompany + ' (') !== -1)
      ) {
        // secondary operator
        match = true
      }
    })
    return match
  }
  map(item) {
    if (item.selected) {
      item.selected = this.filter(item)
    }
    return item
  }
}

export default CompanyFilter
