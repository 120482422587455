import buildCheckbox from './buildCheckbox'

const buildCheckboxForMexico = () => {
  if (GoogleMap.country_name === 'Mexico') {
    if ($('#refineries').closest('li').length === 0) {
      return $('.maps-filter-control > ul').append(`<li>${buildCheckbox('refineries')}`)
    }
  } else {
    return $('#refineries')
      .closest('li')
      .remove()
  }
}

export default buildCheckboxForMexico
