const toggleActiveProductionWellFilter = () => {
  if (
    _.includes(['Brazil', 'Mexico'], GoogleMap.country_name) &&
    $('#active-production-well-filter-section')
  ) {
    return $('#active-production-well-filter-section')
      .parent()
      .removeClass('hidden')
  } else {
    return $('#active-production-well-filter-section')
      .parent()
      .addClass('hidden')
  }
}

export default toggleActiveProductionWellFilter
