import BaseBlockInfoWindow from './BaseBlockInfoWindow'

class DominicanRepublicBlockInfoWindow extends BaseBlockInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'area',
      'water_depth',
      'shore_status',
      'geology',
      'line_break',
      'status',
      'participation',
      'fiscal_regime',
      'round',
      'contract_signed',
      'expiration_date',
    ]
  }
}

export default DominicanRepublicBlockInfoWindow
