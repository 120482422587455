const selectObject = (markers, key, options) => {
  let block_group, block, field, marker, pipeline, type
  if (options == null) {
    options = {}
  }
  for (block_group of Array.from(GoogleMap.markers.block_groups)) {
    block_group.setOptions({
      strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
      strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
    })
  }
  for (field of Array.from(GoogleMap.markers.fields)) {
    field.setOptions({
      strokeColor: JSCONSTANT.markerOptions.strokeColor['fields'],
      strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
    })
  }

  for (field of Array.from(GoogleMap.markers.hydrocarbon_structures)) {
    field.setOptions({
      strokeColor: JSCONSTANT.markerOptions.strokeColor['fields'],
      strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
    })
  }

  if (Array.isArray(GoogleMap.markers.blocks)) {
    for (block of Array.from(GoogleMap.markers.blocks)) {
      block.setOptions({
        strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
        strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
      })
    }
  } else {
    for (type in GoogleMap.markers.blocks) {
      const blocks = GoogleMap.markers.blocks[type]
      for (block of Array.from(blocks)) {
        block.setOptions({
          strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
          strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
        })
      }
    }
  }

  for (let restricted_area of Array.from(GoogleMap.markers.restricted_areas)) {
    restricted_area.setOptions({
      strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
      strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
    })
  }

  for (let formation_shapefile of Array.from(GoogleMap.markers.formation_shapefiles)) {
    formation_shapefile.setOptions({
      strokeColor: JSCONSTANT.markerOptions.strokeColor['blocks'],
      strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
    })
  }

  for (type in GoogleMap.markers.pipelines) {
    const pipelines = GoogleMap.markers.pipelines[type]

    if (pipelines.length !== 0 && GoogleMap.country_name === 'Uruguay') {
      let pipeline_name = markers[0].customInfo.name
      let list_pipelines = pipelines.filter(
        (pipeline) => pipeline.customInfo.name === pipeline_name,
      )
      if (list_pipelines.length !== 0) {
        markers = list_pipelines
      }
    }

    for (pipeline of Array.from(pipelines)) {
      pipeline.setOptions({
        strokeColor: JSCONSTANT.mapColors.pipelines[type],
        strokeWeight: JSCONSTANT.markerOptions.pipelineStrokeWeight,
      })
    }
  }
  for (type in GoogleMap.markers.secondary_pipelines) {
    const secondary_pipelines = GoogleMap.markers.secondary_pipelines[type]
    for (pipeline of Array.from(secondary_pipelines)) {
      pipeline.setOptions({
        strokeColor: JSCONSTANT.mapColors.secondary_pipelines[type],
        strokeWeight: JSCONSTANT.markerOptions.pipelineStrokeWeight,
      })
    }
  }
  for (type in GoogleMap.markers.pipeline_throughputs) {
    const pipeline_throughputs = GoogleMap.markers.pipeline_throughputs[type]
    for (pipeline of Array.from(pipeline_throughputs)) {
      pipeline.setOptions({
        strokeColor: JSCONSTANT.mapColors.pipeline_throughputs[type],
        strokeWeight: JSCONSTANT.markerOptions.pipelineStrokeWeight,
      })
    }
  }
  if (!options.noZoom) {
    if (['wells', 'facilities'].includes(key)) {
      processPointSelector(markers, options)
    } else {
      let area = 0

      for (marker of Array.from(markers)) {
        area += google.maps.geometry.spherical.computeArea(marker.getPath())
      }

      if (key === 'fields') {
        if (area > 500000000) {
          GoogleMap.map.setZoom(9)
        } else if (area > 200000000) {
          GoogleMap.map.setZoom(10)
        } else if (area > 100000000) {
          GoogleMap.map.setZoom(11)
        } else if (area > 11000000) {
          GoogleMap.map.setZoom(12)
        } else {
          GoogleMap.map.setZoom(13)
        }
      } else {
        if (area > 2000000000) {
          GoogleMap.map.setZoom(8)
        } else if (area > 500000000) {
          GoogleMap.map.setZoom(9)
        } else if (area > 100000000) {
          GoogleMap.map.setZoom(10)
        } else {
          GoogleMap.map.setZoom(11)
        }
      }

      if (options.event) {
        GoogleMap.map.panTo(options.event.latLng)
      } else {
        GoogleMap.map.panTo(options.latLng)
      }
    }
  }
  return (() => {
    const result = []
    for (marker of Array.from(markers)) {
      result.push(
        marker.setOptions({
          strokeColor: JSCONSTANT.highlightColor,
          strokeWeight: JSCONSTANT.markerOptions.strokeWeight + 5,
        }),
      )
    }
    return result
  })()
}

const processPointSelector = (markers, options) => {
  let marker = markers[0]

  GoogleMap.map.setZoom(15)
  GoogleMap.map.panTo(options.latLng)
  new google.maps.event.trigger(marker, 'click')
}

export default selectObject
