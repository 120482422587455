/**
 * Converts a string to title case, capitalizing the first letter of each word.
 *
 * @param {string} str - The input string to be converted to title case.
 * @returns {string} The input string in title case.
 */
export const titleize = (str) => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

/**
 * Converts a string to a more human-readable format by converting it to lowercase and replacing underscores with spaces.
 *
 * @param {string} str - The input string to be humanized.
 * @returns {string} The humanized string.
 */
export const humanize = (str) => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().replace(/_/g, ' ');
};
