export default function loadCountryRelativeData(countryName, options) {
  if ($("select[name='search[basin]']").length == 1) {
    var basinSelectize = $("select[name='search[basin]']").data('selectize')
  } else {
    var basinSelectize = $("select[name='search[basin][]']").data('selectize')
  }
  var companySelectize = $("select[name='search[company][]']").data('selectize')

  var assetNameSelectize
  var blockNameSelectize
  var hydrocarbonStructureNameSelectize
  var wellNameSelectize
  var facilityNameSelectize

  if (options.on_map) {
    assetNameSelectize = $("select[name='search[asset_name]']").data('selectize')

    blockNameSelectize = $("select[name='search[block_name]']").data('selectize')

    hydrocarbonStructureNameSelectize = $("select[name='search[hydrocarbon_structure_name]']").data(
      'selectize',
    )

    wellNameSelectize = $("select[name='search[well_name]']").data('selectize')

    facilityNameSelectize = $("select[name='search[facility_name]']").data('selectize')

    hydrocarbonStructureNameSelectize.clearOptions()
    blockNameSelectize.clearOptions()
    wellNameSelectize.clearOptions()
    facilityNameSelectize.clearOptions()
  } else {
    assetNameSelectize = $("select[name='search[asset_name][]']").data('selectize')
  }

  companySelectize.clearOptions()
  basinSelectize.clearOptions()
  assetNameSelectize.clearOptions()

  let target = document.getElementById('search_target')

  $.ajax({
    url: Routes.customer_fetch_country_relative_data_path(),
    data: Object.assign(
      {},
      { country_name: countryName, target: target && target.value },
      getCurrentParams(),
    ),
    method: 'POST',
    success: function (response) {
      companySelectize.load(function (callback) {
        callback(response.companies)
      })
      $('#company').data('map', response.companies)

      basinSelectize.load(function (callback) {
        callback(response.basins)
      })
      $('#search_basin').data('map', response.basins)

      assetNameSelectize.clear()
      assetNameSelectize.clearOptions()
      assetNameSelectize.load(function (callback) {
        callback(response.asset_names)
      })

      blockNameSelectize.clear()
      blockNameSelectize.clearOptions()
      blockNameSelectize.load(function (callback) {
        callback(response.block_names)
      })

      handleHydrocarbonStructureDisplay(
        response.hydrocarbon_structure_names,
        hydrocarbonStructureNameSelectize,
      )

      wellNameSelectize.clear()
      wellNameSelectize.clearOptions()
      wellNameSelectize.load((callback) => callback(response.well_names))

      facilityNameSelectize.clear()
      facilityNameSelectize.clearOptions()
      facilityNameSelectize.load((callback) => callback(response.facility_names))
    },
  })
}

const getCurrentParams = () => {
  let params = {}
  location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (_s, k, v) {
    params[k] = v
  })

  return params
}

const handleHydrocarbonStructureDisplay = (names, selectizeObject) => {
  if (names && names.length !== 0) {
    $("select[name='search[hydrocarbon_structure_name]']").parents('div.filter').show()
  } else {
    $("select[name='search[hydrocarbon_structure_name]']").parents('div.filter').hide()
  }
  selectizeObject.load(function (callback) {
    callback(names)
  })
}
