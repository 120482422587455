export default (country_name) => {
  let countryList =
    JSCONSTANT.railsEnv == 'production'
      ? JSCONSTANT.countryHasCountryOverviewDownload
      : JSCONSTANT.countryLinkSubcriberOverview

  if (countryList.includes(country_name)) {
    return `<a target='_blank' href='${Routes.customer_proprietary_downloads_path({
      slug: `${country_name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '')}-core`,
    })}'>${I18n.t('js.customer.maps.content.field.link_subcriber', {
      country_name: country_name,
    })}</a>`
  }
}
