import CompanyFilter from './CompanyFilter'
class CompanyOperatorFilter extends CompanyFilter {
  constructor() {
    super()
    this.isSelected =
      $('#search_is_company_operator').prop('checked') && this.value && this.value.length > 0
  }
  filter(item) {
    let match
    let participation = Array.isArray(item.participation)
      ? item.participation.join(', ')
      : item.participation
    this.value.forEach((selectedCompany) => {
      if (
        participation &&
        (participation.includes(selectedCompany + ' (Operator)') || // main operator
          participation.includes(selectedCompany + ' (100'))
      ) {
        //  main operator (mexico format)
        match = true
      }
    })
    return match
  }
  filterWell(well) {
    let match
    this.value.forEach((selectedCompany) => {
      if (
        well.name_operator &&
        (well.name_operator.indexOf(selectedCompany + ' (Operator)') !== -1 || // main operator
          well.name_operator.indexOf(selectedCompany + ' (100') !== -1)
      ) {
        //  main operator (mexico format)
        match = true
      }
    })
    return match
  }
}

export default CompanyOperatorFilter
