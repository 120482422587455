import buildCheckbox from './buildCheckbox'

const buildRestrictedAreasCheckbox = () => {
  if (JSCONSTANT.mapRestrictedAreasLayer.indexOf(GoogleMap.country_name) > -1) {
    if ($('#restricted_areas').closest('li').length === 0) {
      return $('.maps-filter-control > ul').append(
        `<li>${buildCheckbox('restricted_areas', 'warning', false)}`,
      )
    }
  } else {
    return $('#restricted_areas')
      .closest('li')
      .remove()
  }
}

export default buildRestrictedAreasCheckbox
