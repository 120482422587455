import layerOnClick from './layerOnClick'
import buildCheckboxForMexico from './buildCheckboxForMexico'
import buildBlockSubCheckboxes from './buildBlockSubCheckboxes'
import buildFacilitySubCheckboxes from './buildFacilitySubCheckboxes'
import setCheckboxesForEcu from './setCheckboxesForEcu'
import buildRestrictedAreasCheckbox from './buildRestrictedAreasCheckbox'
import buildCheckboxForArgentina from './buildCheckboxForArgentina'
import buildShoreStatusSelect from './buildShoreStatusSelect'
import buildSubCheckboxPipeline from './buildSubCheckboxPipeline'
import toggleHydrocarbonLayerCheckbox from './toggleHydrocarbonLayerCheckbox'
import buildMarkerClusterer from './buildMarkerClusterer'
import buildFormationShapefileCheckbox from './buildFormationShapefileCheckbox'
import configureDefaultHiddenLayers from './configureDefaultHiddenLayers'
import setMarkersIcon from './setMarkersIcon'
import toggleActiveProductionWellFilter from './toggleActiveProductionWellFilter'
import changeLayerText from './changeLayerText'
import resetLayerText from './resetLayerText'
import getInvisibleLayers from './getInvisibleLayers'
import setMapOnAll from './setMapOnAll'
import addPopup from './addPopup'
import isNotDonutPolygon from './isNotDonutPolygon'
import drawFieldorBlock from './drawFieldorBlock'
import drawPipeline from './drawPipeline'
import buildBlockGroupCheckbox from './buildBlockGroupCheckbox'
import buildPipelineThroughputCheckbox from './buildPipelineThroughputCheckbox'

const initCircleMap = (data, clearMarkers) => {
  let clusterers, key, marker, markers, popup, type
  if (clearMarkers) {
    GoogleMap.country_name = data.country

    if (GoogleMap.markers !== undefined) {
      for (key in GoogleMap.markers) {
        markers = GoogleMap.markers[key]
        if (Array.isArray(markers)) {
          if (JSCONSTANT.layerHasSameDisplayOnMap.indexOf(key) > -1) {
            const popups = markers['Popup']
            if (popups) {
              for (popup of Array.from(popups)) {
                popup.anchor.style.visibility = 'hidden'
              }
            }
          }
          for (marker of Array.from(markers)) {
            marker.setMap(null)
            if (marker.text) {
              marker.text.anchor.style.visibility = 'hidden'
            }
          }
        } else {
          for (let subKey in markers) {
            const subMarkers = markers[subKey]
            for (marker of Array.from(subMarkers)) {
              marker.setMap(null)
              if (marker.text) {
                marker.text.anchor.style.visibility = 'hidden'
              }
            }
          }
        }
      }
      delete GoogleMap.markers

      for (key in GoogleMap.clusterers) {
        var cluster
        clusterers = GoogleMap.clusterers[key]
        if (Array.isArray(clusterers)) {
          for (cluster of Array.from(clusterers)) {
            cluster.clearMarkers()
          }
        } else {
          for (let newKey in clusterers) {
            const subClusterers = clusterers[newKey]
            for (cluster of Array.from(subClusterers)) {
              cluster.clearMarkers()
            }
          }
        }
      }
      delete GoogleMap.clusterers
    }

    GoogleMap.markers = {
      block_groups: [],
      fields: [],
      wells: {},
      blocks:
        Array.from(JSCONSTANT.country_display_block_over_field).includes(GoogleMap.country_name) ||
        GoogleMap.country_name === 'Mexico'
          ? {}
          : [],
      restricted_areas: [],
      facilities: [],
      basins: [],
      pipelines: {},
      secondary_pipelines: {},
      pipeline_throughputs: {},
      presalts: [],
      refineries: [],
      highlights: [],
      hydrocarbon_structures: [],
      formation_shapefiles: [],
    }

    GoogleMap.relationship = { field_blocks: [] }

    GoogleMap.infoWindow = new google.maps.InfoWindow()
    GoogleMap.clusterers = {
      wells: {},
      facilities: [],
    }
  }

  buildBlockGroupCheckbox()
  buildShoreStatusSelect()
  buildSubCheckboxPipeline()
  buildFormationShapefileCheckbox()
  buildCheckboxForMexico()
  setCheckboxesForEcu()
  buildCheckboxForArgentina(Object.keys(data.secondary_pipelines || {}), 'secondary_pipelines')
  buildRestrictedAreasCheckbox()
  toggleHydrocarbonLayerCheckbox()
  toggleActiveProductionWellFilter()
  buildFacilitySubCheckboxes(Object.keys(data.facilities), 'facilities')
  buildBlockSubCheckboxes(Object.keys(data.blocks), 'blocks')
  buildPipelineThroughputCheckbox()
  resetLayerText()
  configureDefaultHiddenLayers()
  const selectedObject =
    $('#search_asset_name').text() ||
    $('#search_block_name').text() ||
    $('#search_hydrocarbon_structure_name').text()
  let zoomed = false

  const invisibleLayers = getInvisibleLayers()

  for (key in data) {
    var area,
      center,
      coordinate,
      coordinateElement,
      coordinateObject,
      element,
      facilities,
      fillColor,
      latLng,
      name,
      pipeline,
      point,
      pointCenter,
      self
    const elements = data[key]
    var visible = invisibleLayers.indexOf(key) === -1
    switch (key) {
      case 'facilities':
        // change value google map if country is Mexico
        if (['Mexico', 'Colombia', 'GoM'].includes(GoogleMap.country_name)) {
          GoogleMap.markers.facilities = {}
          GoogleMap.clusterers.facilities = {}

          changeLayerText(key, elements.length, { notShow: true })

          for (type in elements) {
            facilities = elements[type]
            changeLayerText(type, facilities.length)
            GoogleMap.markers.facilities[type] = []

            for (let facility of Array.from(facilities)) {
              center = {
                lat: facility.lat,
                lng: facility.lng,
              }
              marker = new google.maps.Marker({
                position: center,
                visible,
                customInfo: facility,
                type: key,
              })

              layerOnClick(marker)
              GoogleMap.markers.facilities[type].push(marker)
            }
          }
        } else {
          GoogleMap.markers.facilities = []
          GoogleMap.clusterers.facilities = []

          changeLayerText(
            key,
            elements.length + (JSCONSTANT.numberStaticFacilities[GoogleMap.country_name] || 0),
          )

          for (element of Array.from(elements)) {
            center = {
              lat: element.lat,
              lng: element.lng,
            }
            marker = new google.maps.Marker({
              position: center,
              visible,
              customInfo: element,
              type: key,
              zIndex: JSCONSTANT.zIndex[key],
            })

            layerOnClick(marker)
            GoogleMap.markers[key].push(marker)
          }
        }
        break

      case 'wells':
        let wellCount = Object.values(elements)
          .map((wells) => wells.length)
          .reduce((total, currentValue) => total + currentValue, 0)

        changeLayerText('wells', wellCount)

        for (let classification in elements) {
          const wells = elements[classification]
          changeLayerText(classification, wells.length, { startWith: 'well' })
          GoogleMap.markers.wells[classification] = []

          wells.forEach((well) => {
            const center = {
              lat: well.lat,
              lng: well.lng,
            }

            let zidx = key
            if (GoogleMap.country_name === 'GoM' && well.is_recent_production === true) {
              zidx = 'prod_wells'
            }

            const marker = new google.maps.Marker({
              position: center,
              visible: true,
              customInfo: well,
              type: key,
              zIndex: JSCONSTANT.zIndex[zidx],
            })

            layerOnClick(marker)

            if (!zoomed) {
              if (well.name === selectedObject) {
                zoomed = true
                let latLng = marker.getPosition()
                if (GoogleMap.selectedObject) {
                  GoogleMap.selectedObject(marker, { latLng }, key)
                }
              }
            }

            GoogleMap.markers.wells[classification].push(marker)
          })
        }
        break
      case 'block_groups':
      case 'fields':
      case 'hydrocarbon_structures':
      case 'restricted_areas':
      case 'formation_shapefiles':
      case 'blocks':
        if (!elements) {
          return
        }
        if (
          key === 'blocks' &&
          (Array.from(JSCONSTANT.country_display_block_over_field).includes(
            GoogleMap.country_name,
          ) ||
            GoogleMap.country_name === 'Mexico')
        ) {
          changeLayerText(key, elements.length)
          for (let block_type in elements) {
            const blocks = elements[block_type]
            visible = invisibleLayers.indexOf('blocks_' + block_type) === -1
            if (!GoogleMap.markers.blocks[block_type]) {
              GoogleMap.markers.blocks[block_type] = []
              changeLayerText(block_type, blocks.length)
            }
            for (element of Array.from(blocks)) {
              self = this
              for (coordinateObject of Array.from(element.coordinates)) {
                var display_name
                if (Array.isArray(coordinateObject)) {
                  coordinate = coordinateObject
                  display_name = element.name
                } else {
                  coordinate = coordinateObject.value
                  display_name = coordinateObject.name || element.name
                }

                if (coordinate === null) {
                  continue
                }
                type = null
                fillColor = JSCONSTANT.mapColors[key]

                element.block_type = block_type

                let coordinates
                if (!(coordinate[0] instanceof Array && coordinate[0][0] instanceof Array)) {
                  coordinates = [coordinate]
                } else coordinates = coordinate

                for (const coordinate of coordinates) {
                  marker = drawFieldorBlock(
                    coordinate,
                    visible,
                    element,
                    fillColor,
                    element.highlighted,
                    key,
                    GoogleMap.map,
                  )

                  if (!zoomed) {
                    if (element.name === selectedObject) {
                      zoomed = true
                      latLng = new google.maps.LatLng(coordinate[0][0].lat, coordinate[0][0].lng)
                      if (GoogleMap.selectedObject) {
                        GoogleMap.selectedObject(marker, { latLng }, key)
                      }
                    }
                  }

                  point = polylabel(coordinate, JSCONSTANT.precision)
                  pointCenter = new google.maps.LatLng(point.lat, point.lng)
                  layerOnClick(marker)
                  area = google.maps.geometry.spherical.computeArea(marker.getPath())

                  if (google.maps.geometry.poly.containsLocation(pointCenter, marker)) {
                    addPopup(pointCenter, display_name, area, key, null, marker)
                  } else {
                    addPopup(marker.getApproximateCenter(), display_name, area, key, null, marker)
                  }
                  GoogleMap.markers.blocks[block_type].push(marker)
                }
              }
            }

            setMapOnAll({
              checkbox: block_type,
              visible,
              hasChild: false,
              parent: 'blocks',
            })
          }
        } else {
          changeLayerText(key, elements.length)
          for (element of Array.from(elements)) {
            self = this
            for (coordinateObject of Array.from(element.coordinates)) {
              if (Array.isArray(coordinateObject)) {
                coordinate = coordinateObject
                ;({ name } = element)
              } else {
                coordinate = coordinateObject.value
                if (key === 'fields' && GoogleMap.country_name === 'GoM') {
                  ;({ name } = element)
                } else {
                  name = coordinateObject.name || element.name
                }
              }

              if (coordinate === null) {
                continue
              }
              type = null
              fillColor = (() => {
                switch (key) {
                  case 'block_groups':
                    return JSCONSTANT.mapColors['blocks']
                  case 'fields':
                    type = element.field_type ? element.field_type : 'other'
                    if (GoogleMap.country_name === 'GoM' && type === 'other') {
                      type = 'exploration'
                    }
                    return JSCONSTANT.mapColors[key][type]
                  case 'hydrocarbon_structures':
                    type = element.hydrocarbon_type ? element.hydrocarbon_type : 'other'
                    return JSCONSTANT.mapColors[key][type]
                  case 'formation_shapefiles':
                    return JSCONSTANT.mapColors[key][
                      element.name.replace(/\//, '').replace(/\  */, '')
                    ]
                  default:
                    return JSCONSTANT.mapColors[key]
                }
              })()

              let coordinates
              if (!(coordinate[0] instanceof Array && coordinate[0][0] instanceof Array)) {
                coordinates = [coordinate]
              } else coordinates = coordinate

              for (const coordinate of coordinates) {
                marker = drawFieldorBlock(
                  coordinate,
                  visible,
                  element,
                  fillColor,
                  element.highlighted,
                  key,
                  GoogleMap.map,
                )

                if (!zoomed) {
                  if (element.name === selectedObject) {
                    zoomed = true
                    latLng = new google.maps.LatLng(coordinate[0][0].lat, coordinate[0][0].lng)
                    if (GoogleMap.selectedObject) {
                      GoogleMap.selectedObject(marker, { latLng }, key)
                    }
                  }
                }

                if (
                  key === 'fields' &&
                  element.point_center &&
                  typeof element.point_center !== 'undefined'
                ) {
                  pointCenter = new google.maps.LatLng(
                    element.point_center[0],
                    element.point_center[1],
                  )
                }
                if (
                  key === 'hydrocarbon_structures' &&
                  element.point_center &&
                  typeof element.point_center !== 'undefined'
                ) {
                  pointCenter = new google.maps.LatLng(
                    element.point_center[0],
                    element.point_center[1],
                  )
                } else {
                  point = polylabel(coordinate, JSCONSTANT.precision)
                  pointCenter = new google.maps.LatLng(point.lat, point.lng)
                }

                layerOnClick(marker)
                GoogleMap.markers[key].push(marker)
                area = google.maps.geometry.spherical.computeArea(marker.getPath())

                if (JSCONSTANT.layerHasSameDisplayOnMap.indexOf(key) > -1) {
                  if (Array.isArray(coordinate) && isNotDonutPolygon(coordinate)) {
                    for (coordinateElement of Array.from(coordinate)) {
                      marker = drawFieldorBlock(
                        coordinateElement,
                        visible,
                        element,
                        fillColor,
                        element.highlighted,
                        key,
                      )
                      area = google.maps.geometry.spherical.computeArea(marker.getPath())
                      addPopup(pointCenter, name, area, key)
                    }
                  } else {
                    if (google.maps.geometry.poly.containsLocation(pointCenter, marker)) {
                      addPopup(pointCenter, name, area, key)
                    } else {
                      addPopup(marker.getApproximateCenter(), name, area, key)
                    }
                  }
                }
              }
            }
          }

          setMapOnAll({ checkbox: key, visible, hasChild: false, parent: undefined })
        }
        break
      case 'basins':
        changeLayerText(key, elements.length)
        for (element of Array.from(elements)) {
          self = this
          $.each(element.geometries, function (name, value) {
            fillColor = value.position || 'other'
            marker = new google.maps.Polygon({
              paths: value.coordinates || [],
              visible,
              customInfo: element,
              strokeColor: JSCONSTANT.mapColors[key][fillColor],
              strokeOpacity: JSCONSTANT.markerOptions.strokeOpacity,
              strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
              fillColor: JSCONSTANT.mapColors[key][fillColor],
              fillOpacity: JSCONSTANT.markerOptions.fillOpacity[key],
              map: GoogleMap.map,
              type: key,
              zIndex: JSCONSTANT.zIndex[key],
            })

            layerOnClick(marker)
            return GoogleMap.markers[key].push(marker)
          })
        }
        break

      case 'pipelines':
        for (type in elements) {
          const pipelines = elements[type]
          changeLayerText(type, pipelines.length)
          GoogleMap.markers.pipelines[type] = []

          for (pipeline of Array.from(pipelines)) {
            for (coordinate of Array.from(pipeline.coordinates)) {
              marker = drawPipeline(coordinate, visible, key, type, pipeline)

              layerOnClick(marker)
              GoogleMap.markers.pipelines[type].push(marker)
            }
          }
        }
        break

      case 'secondary_pipelines':
        for (type in elements) {
          const secondary_pipelines = elements[type]
          changeLayerText(type, secondary_pipelines.length)
          GoogleMap.markers.secondary_pipelines[type] = []

          for (pipeline of Array.from(secondary_pipelines)) {
            for (coordinate of Array.from(pipeline.coordinates)) {
              marker = drawPipeline(coordinate, visible, key, type, pipeline)

              layerOnClick(marker)
              GoogleMap.markers.secondary_pipelines[type].push(marker)
            }
          }
        }
        break

      case 'pipeline_throughputs':
        for (type in elements) {
          const pipeline_throughputs = elements[type]
          changeLayerText(type, pipeline_throughputs.length)
          GoogleMap.markers.pipeline_throughputs[type] = []

          for (pipeline of Array.from(pipeline_throughputs)) {
            for (coordinate of Array.from(pipeline.coordinates)) {
              marker = drawPipeline(coordinate, visible, key, type, pipeline)

              layerOnClick(marker)
              GoogleMap.markers.pipeline_throughputs[type].push(marker)
            }
          }
        }
        break

      case 'presalts':
        for (element of Array.from(elements)) {
          marker = new google.maps.Polygon({
            paths: element.coordinates,
            visible,
            customInfo: element,
            strokeColor: JSCONSTANT.mapColors[key],
            strokeOpacity: JSCONSTANT.markerOptions.presalt,
            strokeWeight: JSCONSTANT.markerOptions.strokeWeight,
            fillColor: JSCONSTANT.mapColors[key],
            fillOpacity: JSCONSTANT.markerOptions.fillOpacity[key],
            map: GoogleMap.map,
            type: key,
            zIndex: JSCONSTANT.zIndex[key],
          })

          layerOnClick(marker)
          GoogleMap.markers[key].push(marker)
        }
        break
      case 'refineries':
        changeLayerText(key, elements.length)
        for (element of Array.from(elements)) {
          center = {
            lat: element.lat,
            lng: element.lng,
          }
          marker = new google.maps.Marker({
            position: center,
            visible,
            customInfo: element,
            type: key,
            zIndex: JSCONSTANT.zIndex[key],
            map: GoogleMap.map,
            icon: {
              url: JSCONSTANT.refineryIconPath,
              scaledSize: new google.maps.Size(30, 35),
            },
          })

          layerOnClick(marker)
          GoogleMap.markers[key].push(marker)
        }
        break
      case 'field_blocks':
        GoogleMap.relationship[key] = elements
        break
      default:
        null
    }
  }

  buildMarkerClusterer(invisibleLayers)

  setTimeout(() => setMarkersIcon(), 1000)
}
export default initCircleMap
