import BaseWellInfoWindow from './BaseWellInfoWindow';
import PeruWellInfoWindow from './PeruWellInfoWindow';
import ColombiaWellInfoWindow from './ColombiaWellInfoWindow';
import SurinameWellInfoWindow from './SurinameWellInfoWindow';
import FrenchGuianaWellInfoWindow from './FrenchGuianaWellInfoWindow';
import GuyanaWellInfoWindow from './GuyanaWellInfoWindow';
import MexicoWellInfoWindow from './MexicoWellInfoWindow';
import FalklandWellInfoWindow from './FalklandWellInfoWindow';
import BarbadosWellInfoWindow from './BarbadosWellInfoWindow';
import JamaicaWellInfoWindow from './JamaicaWellInfoWindow';
import GabonWellInfoWindow from './GabonWellInfoWindow';
import GomWellInfoWindow from './GomWellInfoWindow';
import UruguayWellInfoWindow from './UruguayWellInfoWindow';
import HondurasWellInfoWindow from './HondurasWellInfoWindow';
import NicaraguaWellInfoWindow from './NicaraguaWellInfoWindow';
import BrazilWellInfoWindow from './BrazilWellInfoWindow';
import ArgentinaWellInfoWindow from './ArgentinaWellInfoWindow';
import EcuadorWellInfoWindow from './EcuadorWellInfoWindow';
import BoliviaWellInfoWindow from './BoliviaWellInfoWindow';
import BahamasWellInfoWindow from './BahamasWellInfoWindow';
import DominicanRepublicWellInfoWindow from './DominicanRepublicWellInfoWindow';
import CubaWellInfoWindow from './CubaWellInfoWindow';
import AngolaWellInfoWindow from './AngolaWellInfoWindow';
import GhanaWellInfoWindow from './GhanaWellInfoWindow';
import GuineaWellInfoWindow from './GuineaWellInfoWindow';
import SenegalWellInfoWindow from './SenegalWellInfoWindow';
import NigeriaWellInfoWindow from './NigeriaWellInfoWindow';
import TrinidadWellInfoWindow from './TrinidadWellInfoWindow';
import CongoWellInfoWindow from './CongoWellInfoWindow';
import KenyaWellInfoWindow from './KenyaWellInfoWindow';
import DrcWellInfoWindow from './DrcWellInfoWindow';
import DenmarkWellInfoWindow from './DenmarkWellInfoWindow';
import AustraliaWellInfoWindow from './AustraliaWellInfoWindow';
import NamibiaWellInfoWindow from './NamibiaWellInfoWindow';

export default {
  default: BaseWellInfoWindow,
  Brazil: BrazilWellInfoWindow,
  Peru: PeruWellInfoWindow,
  Colombia: ColombiaWellInfoWindow,
  Suriname: SurinameWellInfoWindow,
  'French Guiana': FrenchGuianaWellInfoWindow,
  Guyana: GuyanaWellInfoWindow,
  Mexico: MexicoWellInfoWindow,
  'Falkland Islands/Islas Malvinas': FalklandWellInfoWindow,
  Barbados: BarbadosWellInfoWindow,
  Jamaica: JamaicaWellInfoWindow,
  Gabon: GabonWellInfoWindow,
  GoM: GomWellInfoWindow,
  Uruguay: UruguayWellInfoWindow,
  Honduras: HondurasWellInfoWindow,
  Nicaragua: NicaraguaWellInfoWindow,
  Argentina: ArgentinaWellInfoWindow,
  Ecuador: EcuadorWellInfoWindow,
  Bolivia: BoliviaWellInfoWindow,
  Bahamas: BahamasWellInfoWindow,
  'Dominican Republic': DominicanRepublicWellInfoWindow,
  Cuba: CubaWellInfoWindow,
  Angola: AngolaWellInfoWindow,
  Ghana: GhanaWellInfoWindow,
  Guinea: GuineaWellInfoWindow,
  Senegal: SenegalWellInfoWindow,
  Nigeria: NigeriaWellInfoWindow,
  'Trinidad and Tobago': TrinidadWellInfoWindow,
  'Congo Brazzaville': CongoWellInfoWindow,
  Kenya: KenyaWellInfoWindow,
  'Democratic Republic of Congo': DrcWellInfoWindow,
  Denmark: DenmarkWellInfoWindow,
  Australia: AustraliaWellInfoWindow,
  Namibia: NamibiaWellInfoWindow,
};
