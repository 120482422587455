import drawFieldorBlock from './drawFieldorBlock'

const isNotDonutPolygon = coordinates => {
  const listMarker = []
  for (let coordinate of Array.from(coordinates)) {
    const marker = drawFieldorBlock(coordinate)
    listMarker.push(marker)
  }
  for (let firstMarker of Array.from(listMarker)) {
    for (let secondMarker of Array.from(listMarker)) {
      if (firstMarker === secondMarker) {
        break
      }
      const firstLocation = firstMarker.getApproximateCenter()
      const secondLocation = secondMarker.getApproximateCenter()
      if (
        google.maps.geometry.poly.containsLocation(secondLocation, firstMarker) ||
        google.maps.geometry.poly.containsLocation(firstLocation, secondMarker)
      ) {
        return false
      }
    }
  }
  return true
}

export default isNotDonutPolygon
