import BaseWellInfoWindow from './BaseWellInfoWindow'

class DominicanRepublicWellInfoWindow extends BaseWellInfoWindow {
  static get props() {
    return [
      'name',
      'well_type',
      'depth_vertical',
      'operator',
      'drilling_start',
      'completion',
    ]
  }
}

export default DominicanRepublicWellInfoWindow
