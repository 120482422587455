class ActiveProductionWellsFilter {
  constructor() {
    this.min = $('#search_current_prod_min').val()
    this.max = $('#search_current_prod_max').val()
    this.value = [this.min, this.max]
    this.isSelected = !!(this.min || this.max)
  }
  processData(filteredData) {
    if (this.isSelected) {
      this.min = this.min ? parseInt(this.min.replace(/,/g, ''), 10) : 0
      this.max = this.max ? parseInt(this.max.replace(/,/g, ''), 10) : Number.POSITIVE_INFINITY
      filteredData.wells = filterNestedCollections(filteredData.wells, this.filterWell.bind(this))
      filteredData.mainAssets = mapNestedCollections(
        filteredData.mainAssets,
        this.mapField.bind(this),
      )
      filteredData.secondaryAssets = []
    }

    return filteredData
  }
  filterWell(well) {
    if (!well.field_production) return false
    let well_field_production = parseInt(well.field_production.toString().replace(/,/g, ''), 10)
    return this.min <= well_field_production && well_field_production <= this.max
  }
  filterField(field) {
    if (!field.most_recent_production) return false

    return this.min <= field.most_recent_production && field.most_recent_production <= this.max
  }
  mapField(item) {
    if (item.selected) {
      item.selected = this.filterField(item)
    }
    return item
  }
}

export default ActiveProductionWellsFilter
