import drawOilChart from './drawChart/drawOilChart'
import drawGasChart from './drawChart/drawGasChart'
import drawWaterChart from './drawChart/drawWaterChart'
import drawGasLineChart from './drawChart/drawGasLineChart'
import drawLiquidLineChart from './drawChart/drawLiquidLineChart'

const drawChart = (facility) => {
  return setTimeout(function () {
    let option = {
      count: 0,
    }
    drawOilChart(facility, option)
    drawGasChart(facility, option)
    drawWaterChart(facility, option)
    drawLiquidLineChart(facility)
    drawGasLineChart(facility)

    if (option.count === 0) {
      $(`#title-pie-chart-${facility.id}`).parent().addClass('hidden')
    }
  }, 100)
}

export default drawChart
