import linkSubcriberOverview from '../common'

class TrinidadBlockInfoWindow {
  static get props() {
    return [
      'name',
      'basin',
      'area',
      'water_depth',
      'average_depth',
      'shore_status',
      'period',
      'geology',
      'api',
      'line_break',
      'participation',
      'fiscal_regime',
      'round',
      'royalty_rate',
      'first_prod',
      'contract_signed',
      'expiration_date',
      'oil_reserves',
      'gas_reserves',
      'npv',
      'line_break',
      'recent_oil',
      'recent_gas',
      'active_wells',
      'total_wells_drilled',
      'enhanced_access_coverage',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props, { is_restricted_area }) {
    this.element = element
    this.country_name = country_name
    this.is_restricted_area = is_restricted_area || false

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = this.lines.join('<br>')
  }

  removeDuplicate = (lines) => {
    let prevLine

    return lines.filter((line, index) => {
      let compare = true

      if (index !== 0) {
        compare = line !== prevLine
      }

      prevLine = line

      return compare
    })
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle, asset_type, asset_id
    switch (propName) {
      case 'line_break':
        this.lines.push('')
        break
      case 'recent_gas':
        if (this.country_name != 'GoM' && this.element.block_type !== 'blocks_production') return
        let gasPeriod =
          this.element.most_recent_period ||
          (this.element.well_productions &&
            this.element.well_productions.gas &&
            this.element.well_productions.gas.period)
        let gasValue =
          this.element.recent_gas_prod ||
          (this.element.well_productions &&
            this.element.well_productions.gas &&
            this.element.well_productions.gas.value) ||
          0
        if (gasValue > 0 && gasValue < 0.1) {
          gasValue = 0.1
        }
        if (gasPeriod && parseFloat(gasValue) != 0) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.well.most_recent_gas_production_period', {
              period: gasPeriod,
            })}:</strong> ${Utils.formatProductionValue(gasValue, 1)}`,
          )
        }
        break
      case 'recent_oil':
        if (this.country_name != 'GoM' && this.element.block_type !== 'blocks_production') return
        let oilPeriod =
          this.element.most_recent_period ||
          (this.element.well_productions &&
            this.element.well_productions.oil &&
            this.element.well_productions.oil.period)
        let oilValue =
          this.element.recent_oil_prod ||
          (this.element.well_productions &&
            this.element.well_productions.oil &&
            this.element.well_productions.oil.value) ||
          0
        if (oilValue > 0 && oilValue < 0.1) {
          oilValue = 0.1
        }
        if (oilPeriod && oilValue != 0) {
          return this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.well.most_recent_liquid_production_period',
              { period: oilPeriod },
            )}:</strong> ${Utils.formatProductionValue(oilValue, 1)}`,
          )
        }
        break
      case 'access_coverage':
        if (this.element.access_coverage) {
          asset_type = 'Field'
          asset_id = this.element.id
        } else if (this.element.asset_model_id) {
          asset_type = 'Field'
          asset_id = this.element.asset_model_id
        }

        if (!asset_type || !asset_id) break

        this.lines.push(
          `<a href='${Routes.customer_download_path({
            country: this.country_name,
            asset_id,
            asset_type,
            type: 'report',
            timestamp: Date.now(),
          })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
        )
        this.lines.push(
          `<a target='_blank' href='${Routes.customer_download_path({
            country: this.country_name,
            asset_id,
            asset_type,
            type: 'pdf_report',
            timestamp: Date.now(),
          })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
        )

        break
      case 'enhanced_access_coverage':
        if (this.element.enhanced_access_coverage) {
          asset_type = 'Field'
          asset_id = this.element.id
        } else if (this.element.asset_model_id) {
          asset_type = 'Field'
          asset_id = this.element.asset_model_id
        } else if (this.element.asset_model) {
          asset_type = this.element.asset_model[0]
          asset_id = this.element.asset_model[1]
        }

        if (!asset_type || !asset_id) break

        this.lines.push(
          `<a href='${Routes.customer_download_path({
            country: this.country_name,
            asset_type,
            asset_id,
            type: 'report',
            timestamp: Date.now(),
          })}' target='_blank'>${I18n.t('js.customer.maps.content.field.report')}</a>`,
        )
        this.lines.push(
          `<a target='_blank' href='${Routes.customer_download_path({
            country: this.country_name,
            asset_type,
            asset_id,
            type: 'enhanced_pdf_report',
            timestamp: Date.now(),
          })}'>${I18n.t('js.customer.maps.content.field.pdf_report')}</a>`,
        )
        this.lines.push(linkSubcriberOverview(this.country_name))

        break
      case 'oil_reserves':
        propValue = this.element.oil_reserves
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue) && propName !== 'expiration_date') {
            propValue = Utils.formatProductionValue(propValue, 1)
          }
          propTitle = I18n.t('js.customer.maps.content.block.' + propName)
          propTitle =
            this.country_name === 'GoM'
              ? I18n.t('js.customer.maps.content.block.liquids_reserves')
              : propTitle
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break
      case 'first_prod':
        if (this.element.first_prod_date) {
          return this.lines.push(
            `<strong>${I18n.t('js.customer.maps.content.block.first_prod')}:</strong> ${
              this.element.first_prod_date
            }`,
          )
        }
        break
      case 'basin':
        propValue = this.element.basin
        if (propValue) {
          propName = this.country_name === 'GoM' ? 'protraction_area' : propName
          return this.lines.push(
            `<strong>${I18n.t(
              'js.customer.maps.content.block.' + propName,
            )}:</strong> ${propValue}`,
          )
        }
        break
      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          propTitle = I18n.t('js.customer.maps.content.block.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default TrinidadBlockInfoWindow
