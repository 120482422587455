import BaseFacilityInfoWindow from './BaseFacilityInfoWindow'

class BoliviaFacilityInfoWindow extends BaseFacilityInfoWindow {
  static get props() {
    return [
      'name',
      'type_name',
      'contract_type',
      'field_name',
      'province',
      'municipality',
      'operator',
      'contract',
      'water_depth',
      'hydrocarbon_type',
      'capacity',
      'oil_capacity',
    ]
  }

  lines = []
  html = ''

  constructor(element, country_name, props) {
    super(element, country_name, props)

    this.element = element
    this.country_name = country_name

    props.forEach((prop) => {
      this.pushContent(prop)
    })

    this.html = `<div style="position: relative">${this.lines.join('<br>')}</div>`

    this.bodyInformation = `<div style="position: relative">${this.lines.join('<br>')}</div>`
  }

  pushContent = (propName) => {
    let well_productions, propValue, propTitle
    switch (propName) {
      case 'line_break':
        return this.lines.push('')

      case 'field_name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)

      case 'capacity':
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            propValue = Utils.formatProductionValue(propValue, 1)
          }
          propTitle = `Processing Capacity ${this.element.unity}`
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
        break

      case 'name':
        propValue = this.element[propName]
        if (!(propValue && propValue !== 'N/A')) {
          break
        }

        propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
        return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)

      default:
        propValue = this.element[propName]
        if (propValue && parseFloat(propValue) !== 0 && propValue !== 'N/A') {
          if (parseFloat(propValue)) {
            propValue = Utils.formatProductionValue(propValue, 0)
          }
          propTitle = I18n.t('js.customer.maps.content.facility.' + propName)
          return this.lines.push(`<strong>${propTitle}:</strong> ${propValue}`)
        }
    }
  }
}

export default BoliviaFacilityInfoWindow
